import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MicrocourseGroup } from 'src/app/models/skillset.model';
import { environment } from 'src/environments/environment';
import { Subscription, catchError, map } from 'rxjs';
import { SkillsetService } from 'src/app/services/skillset/skillset.service';

@Component({
  selector: 'app-skillset-card',
  templateUrl: './skillset-card.component.html',
  styleUrls: ['./skillset-card.component.scss'],
})
export class SkillsetCardComponent implements OnInit, OnDestroy {
  @Input() skillset!: MicrocourseGroup;

  subscriptions: Subscription[] = [];

  numOfStudentsArchivedString: string = '';
  totalNumberOfMicrocourseString: string = '0 khóa học';

  constructor(
    private skillsetService: SkillsetService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.totalNumberOfMicrocourseString = `${this.skillset.microcourseIds.length} khóa học`;
    this.subscriptions.push(
      this.skillsetService
        .countCertBySkillsetId(this.skillset.id)
        .pipe(
          map((data) => {
            return `${data} học viên`;
          }),
          catchError((error) => {
            return '0 học viên';
          })
        )
        .subscribe((data) => {
          this.numOfStudentsArchivedString = data;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  displayBadgeImage(id: string): string {
    return environment.storageApi + `/akademy/courses/${id}/badge`;
  }

  displayDefaultImage($event: any) {
    $event.target.src = 'assets/images/default.png';
  }

  navigateToDetail() {
    this.router.navigate(['explore/skillset/detail', this.skillset.id]);
  }
}
