import {createReducer, on} from '@ngrx/store';

import * as EnrollmentActions from './enrollment.actions';
import {EnrollmentState} from './enrollment.state';
import {EnrollmentResponse} from 'src/app/models/enrollment.model';

const initialState: EnrollmentState = {
  enrollmentResponse: <EnrollmentResponse>{},
  isLoadingResponseByMicrocourseId: false,
  loadResponseByMicrocourseIdErrorMessage: '',
  isLoadingResponseByProfileId: false,
  loadResponseByProfileIdErrorMessage: '',

  isEnrolling: false,
  isEnrollSuccess: false,
  enrollErrorMessage: '',

  isDeleting: false,
  isDeleteSuccess: false,
  deleteErrorMessage: '',

  countByProfileId: 0,
  isCountingByProfileId: false,
  countByProfileIdErrorMessage: '',

  countByMicrocourseId: 0,
  isCountingByMicrocourseId: false,
  countByMicrocourseIdErrorMessage: '',
};

export const enrollmentReducer = createReducer(
  initialState,
  on(EnrollmentActions.clearStates, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      enrollmentResponse: <EnrollmentResponse>{},
      isLoadingResponseByMicrocourseId: false,
      loadResponseByMicrocourseIdErrorMessage: '',
      isLoadingResponseByProfileId: false,
      loadResponseByProfileIdErrorMessage: '',

      isEnrolling: false,
      isEnrollSuccess: false,
      enrollErrorMessage: '',

      isDeleting: false,
      isDeleteSuccess: false,
      deleteErrorMessage: '',

      countByProfileId: 0,
      isCountingByProfileId: false,
      countByProfileIdErrorMessage: '',

      countByMicrocourseId: 0,
      isCountingByMicrocourseId: false,
      countByMicrocourseIdErrorMessage: '',
    };
  }),
  on(EnrollmentActions.clearMessages, (state, action) => {
    // console.log(action.type);
    return <EnrollmentState>{
      ...state,
      loadResponseByMicrocourseIdErrorMessage: '',

      loadResponseByProfileIdErrorMessage: '',

      isEnrollSuccess: false,
      enrollErrorMessage: '',

      deleteErrorMessage: '',

      countByProfileIdErrorMessage: '',

      countByMicrocourseIdErrorMessage: '',
    };
  }),
  on(EnrollmentActions.enroll, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isEnrolling: true,
      isEnrollSuccess: false,
      enrollErrorMessage: '',
    };
  }),
  on(EnrollmentActions.enrollSuccess, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isEnrolling: false,
      isEnrollSuccess: true,
    };
  }),
  on(EnrollmentActions.enrollFailure, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isEnrolling: false,
      enrollErrorMessage: action.errorMessage,
    };
  }),

  on(EnrollmentActions.listByProfileId, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isLoadingResponseByProfileId: true,
      loadResponseByProfileIdErrorMessage: '',
      enrollmentResponse: <EnrollmentResponse>{},
    };
  }),
  on(EnrollmentActions.listByProfileIdSuccess, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isLoadingResponseByProfileId: false,
      enrollmentResponse: action.enrollments,
    };
  }),
  on(EnrollmentActions.listByProfileIdFailure, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isLoadingResponseByProfileId: false,
      loadResponseByProfileIdErrorMessage: action.errorMessage,
    };
  }),

  on(EnrollmentActions.listByMicrocourseId, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isLoadingResponseByMicrocourseId: true,
      loadResponseByMicrocourseIdErrorMessage: '',
      enrollmentResponse: <EnrollmentResponse>{},
    };
  }),
  on(EnrollmentActions.listByMicrocourseIdSuccess, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isLoadingResponseByMicrocourseId: false,
      enrollmentResponse: action.enrollments,
    };
  }),
  on(EnrollmentActions.listByMicrocourseIdFailure, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isLoadingResponseByMicrocourseId: false,
      loadResponseByMicrocourseIdErrorMessage: action.errorMessage,
    };
  }),

  on(EnrollmentActions.countByMicrocourseId, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isCountingByMicrocourseId: true,
      countByMicrocourseIdErrorMessage: '',
      countByMicrocourseId: 0,
    };
  }),
  on(EnrollmentActions.countByMicrocourseIdSuccess, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isCountingByMicrocourseId: false,
      countByMicrocourseId: action.count,
    };
  }),
  on(EnrollmentActions.countByMicrocourseIdFailure, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isCountingByMicrocourseId: false,
      countByMicrocourseIdErrorMessage: action.errorMessage,
    };
  }),

  on(EnrollmentActions.countByProfileId, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isCountingByProfileId: true,
      countByProfileId: 0,
      countByProfileIdErrorMessage: '',
    };
  }),
  on(EnrollmentActions.countByProfileIdSuccess, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isCountingByProfileId: false,
      countByProfileId: action.count,
    };
  }),
  on(EnrollmentActions.countByProfileIdFailure, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isCountingByProfileId: false,
      countByProfileIdErrorMessage: action.errorMessage,
    };
  }),

  on(EnrollmentActions.deleteById, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isDeleting: true,
      isDeleteSuccess: false,
      deleteErrorMessage: '',
    };
  }),
  on(EnrollmentActions.deleteByIdSuccess, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isDeleting: false,
      isDeleteSuccess: true,
    };
  }),
  on(EnrollmentActions.deleteByIdFailure, (state, action) => {
    console.log(action.type);
    return <EnrollmentState>{
      ...state,
      isDeleting: false,
      deleteErrorMessage: action.errorMessage,
    };
  })
);
