import { Component, OnInit, OnDestroy, Inject, Injector } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { Store } from '@ngrx/store';
import { AuthState } from './ngrx/auth/auth.state';

import * as AuthActions from './ngrx/auth/auth.actions';
import * as ProfileActions from './ngrx/profile/profile.actions';
import * as ACIActions from './ngrx/aci/aci.actions';
import { Subscription } from 'rxjs';
import { FirebaseUser, StaticUser } from './models/user.model';
import { CreditState } from './ngrx/credit/credit.state';
import { TuiAlertService } from '@taiga-ui/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileState } from './ngrx/profile/profile.state';
import * as UsageTimeActions from './ngrx/usage-time/usage-time.actions';
import { TimeSlot } from './models/usage_time.model';
import { Microcourse } from './models/microcourse.model';
import { DrawerState } from './ngrx/drawer/drawer.state';
import * as DrawerActions from './ngrx/drawer/drawer.actions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  defaultCourse = <Microcourse>{};

  jwt = '';
  idToken = '';
  subscriptions: Subscription[] = [];

  loginData: StaticUser = {
    username: '',
    password: '',
  };

  userCredential$ = this.store.select('auth', 'userCredential');
  signInWithSystemGgErrorMessage$ = this.store.select(
    'auth',
    'signInWithSystemGgErrorMessage',
  );
  isSignUpWithSystemGgSuccess$ = this.store.select(
    'auth',
    'isSignUpWithSystemGgSuccess',
  );

  staticUserResponse$ = this.store.select('auth', 'staticUserCredential');
  signInWithStaticUserErrorMessage$ = this.store.select(
    'auth',
    'signInWithStaticUserErrorMessage',
  );

  jwt$ = this.store.select('auth', 'jwt');
  timer: any;

  drawerMicrocourseContent$ = this.store.select(
    'drawer',
    'drawerMicrocourseContent',
  );
  drawerStyles = ['DrawerMenu'];
  overlayStyles = ['overlay'];

  closeDrawer() {
    this.store.dispatch(DrawerActions.close());
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    // @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private auth: Auth,
    private store: Store<{
      auth: AuthState;
      credit: CreditState;
      profile: ProfileState;
      drawer: DrawerState;
    }>,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
  ) {
    onAuthStateChanged(this.auth, async (user) => {
      if (user) {
        let idToken = await user.getIdToken(true);
        this.idToken = idToken;
        console.log(idToken);
        let firebaseUser: FirebaseUser = {
          uid: user.uid,
          email: user?.email || '',
          displayName: user?.displayName || '',
          photoURL: user?.photoURL || '',
        };
        this.store.dispatch(AuthActions.storeIdToken(idToken));
        this.store.dispatch(AuthActions.storeFirebaseUser({ firebaseUser }));
        // console.log(idToken)
        if (user.uid && idToken) {
          this.store.dispatch(AuthActions.signInWithSystemGG({ idToken }));
          this.store.dispatch(
            AuthActions.verifyIsSignInYet({ isAlreadySignIn: true }),
          );
        }
      } else {
        this.jwt = sessionStorage.getItem('jwt') || '';
        if (this.jwt != '') {
          this.loginData = {
            username: sessionStorage.getItem('username') || '',
            password: sessionStorage.getItem('password') || '',
          };
          this.store.dispatch(
            AuthActions.signInWithStaticUser({ staticUser: this.loginData }),
          );
          this.store.dispatch(
            AuthActions.verifyIsSignInYet({ isAlreadySignIn: true }),
          );
        } else {
          this.store.dispatch(
            AuthActions.verifyIsSignInYet({ isAlreadySignIn: false }),
          );
        }
      }
    });
  }

  monitorOnlineTime() {
    this.timer = setInterval(() => {
      let newTimeSlot: TimeSlot = {
        id: Date.now().toString(),
        userId: '',
        startTime: Math.ceil((new Date().getTime() - 120000) / 1000),
        endTime: Math.ceil(new Date().getTime() / 1000),
      };
      this.store.dispatch(UsageTimeActions.create({ timeSlot: newTimeSlot }));
    }, 120000);
  }

  ngOnInit(): void {
    // if (
    //   navigator.userAgent.match(/Android/i) ||
    //   navigator.userAgent.match(/webOS/i) ||
    //   navigator.userAgent.match(/iPhone/i) ||
    //   navigator.userAgent.match(/iPod/i) ||
    //   navigator.userAgent.match(/BlackBerry/i) ||
    //   navigator.userAgent.match(/Windows Phone/i)
    // ) {
    //   this.showMobileDeviceWarningDialog();
    // }

    this.subscriptions.push(
      this.jwt$.subscribe((jwt) => {
        if (jwt != '') {
          this.monitorOnlineTime();
          document.addEventListener('visibilitychange', () => {
            // use the document's hidden property to check if the current tab is active!
            if (document.hidden) {
              // console.log('Browser tab is inactive!');
              clearInterval(this.timer);
            } else {
              // console.log('Browser tab is again active!');
              clearInterval(this.timer);
              this.monitorOnlineTime();
            }
          });
        } else {
          console.log('clear interval');
          clearInterval(this.timer);
        }
      }),
      this.signInWithStaticUserErrorMessage$.subscribe((error) => {
        if (error) {
          this.dangerNotify('Kiểm tra lại tài khoản hoặc mật khẩu!');
        }
      }),
      this.staticUserResponse$.subscribe((staticUserResponse) => {
        if (staticUserResponse.jwt) {
          // this.cookieService.set('jwt', staticUserResponse.jwt);
          sessionStorage.setItem('jwt', staticUserResponse.jwt);
          this.jwt = sessionStorage.getItem('jwt') || '';
          this.store.dispatch(
            AuthActions.storeJWT({
              jwt: staticUserResponse.jwt,
            }),
          );
          this.store.dispatch(ProfileActions.getMine());
        }
      }),
      this.signInWithSystemGgErrorMessage$.subscribe((mess) => {
        if (mess != '') {
          this.store.dispatch(
            AuthActions.signUpWithSystemGG({ idToken: this.idToken }),
          );
        }
      }),
      this.isSignUpWithSystemGgSuccess$.subscribe((isSignedUpSuccess) => {
        if (isSignedUpSuccess) {
          this.store.dispatch(
            AuthActions.signInWithSystemGG({ idToken: this.idToken }),
          );
        }
      }),
      this.userCredential$.subscribe((userCredential) => {
        if (userCredential.jwt != '' && userCredential.jwt != undefined) {
          // console.log(userCredential.jwt);
          this.store.dispatch(
            AuthActions.storeJWT({ jwt: userCredential.jwt }),
          );
          this.store.dispatch(ProfileActions.getMine());
          this.store.dispatch(
            ACIActions.getByUserId({ userId: userCredential.userId }),
          );
          this.store.dispatch(
            ACIActions.getByRoleId({ roleId: userCredential.roleId }),
          );
        }
      }),
      this.store.select('drawer', 'isOpen').subscribe((val) => {
        if (val) {
          this.drawerStyles = ['DrawerMenu', 'open'];
          this.overlayStyles = ['overlay', 'open'];
        } else {
          //remove query params
          //check if the url has query params
          if (this.router.url.includes('?c_id')) {
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {
                c_id: null,
              },
              queryParamsHandling: 'merge',
            });
          }
          this.drawerStyles = ['DrawerMenu'];
          this.overlayStyles = ['overlay'];
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  dangerNotify(errorMessage: any) {
    this.alertService
      .open(errorMessage, {
        status: 'error',
        label: (errorMessage as HttpErrorResponse).statusText,
        autoClose: 5000,
      })
      .subscribe();
    this.store.dispatch(AuthActions.clearMessages());
  }

  // showMobileDeviceWarningDialog(): void {
  //   this.dialogs
  //     .open(
  //       new PolymorpheusComponent(MobileDeviceWarningComponent, this.injector),
  //       {
  //         size: 'page',
  //         closeable: true,
  //         dismissible: true,
  //       }
  //     )
  //     .subscribe();
  // }
}
