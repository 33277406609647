<div class="skillset-card tui-space_bottom-2" (click)="navigateToDetail()">
  <div
    *ngIf="
      skillset.certificatePhotoUrl | sourceToBlob | async as blobUrl;
      else defaultImg
    "
    class="cert-img tui-space_right-2"
  >
    <img [src]="blobUrl" alt="cert-img" />
  </div>
  <ng-template #defaultImg>
    <div class="cert-img tui-space_right-2">
      <span class="tui-text_h1"> Img </span>
    </div>
  </ng-template>
  <div class="content tui-space_right-2">
    <div class="name-box">
      <div class="name">{{ skillset.name }}</div>
    </div>
    <div class="number-details">
      <tui-tag
        size="l"
        [value]="numOfStudentsArchivedString"
        [status]="'primary'"
      ></tui-tag>
      <tui-tag
        size="l"
        class="tui-space_left-1"
        [value]="this.totalNumberOfMicrocourseString"
        [status]="'primary'"
      ></tui-tag>
    </div>
    <div class="description-box">
      <div class="description">{{ skillset.description }}</div>
    </div>
  </div>

  <tui-scrollbar class="microcourse-badge-scroller">
    <div class="microcourse-badge-list">
      <ng-container
        *ngFor="let course of skillset.microcourseIds; let i = index"
      >
        <div *ngIf="i < 10">
          <tui-avatar
            *ngIf="
              displayBadgeImage(course) | sourceToBlob | async as blobUrl;
              else defaultImg
            "
            size="xxl"
            text="course img"
            [rounded]="false"
            [avatarUrl]="blobUrl"
            [autoColor]="true"
          ></tui-avatar>
          <ng-template #defaultImg>
            <tui-avatar
              class="tui-space_right-2"
              [avatarUrl]="''"
              [size]="'xxl'"
              [autoColor]="true"
            ></tui-avatar>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </tui-scrollbar>
</div>
