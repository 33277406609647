import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientWithAuth} from "../../utils/HttpClientWithAuth";

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private http: HttpClientWithAuth) {
  }

  uploadFile(file: File, fileName: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('file_name', fileName);

    return this.http.post('/storage', formData);
  }
}
