import { Pipe, PipeTransform } from '@angular/core';
import { Commitment } from 'src/app/models/commitment_schedule.model';

@Pipe({
  name: 'schedule',
})
export class SchedulePipe implements PipeTransform {
  transform(value: Commitment): string {
    return `${value.fromHour}h${
      value.fromMinute == 0 ? '00' : value.fromMinute
    } - ${value.toHour}h${value.toMinute == 0 ? '00' : value.toMinute}`;
  }
}
