import { createAction, props } from '@ngrx/store';
import { LearningProgress } from 'src/app/models/learning-progress.model';

export const getByUserIdAndMicrocourseId = createAction(
  '[Learning Progress] Get By User Id And Microcourse Id',
  props<{ userId: string; microcourseId: string }>()
);
export const getByUserIdAndMicrocourseIdSuccess = createAction(
  '[Learning Progress] Get By User Id And Microcourse Id Success',
  props<{ learningProgresses: LearningProgress[] }>()
);
export const getByUserIdAndMicrocourseIdFail = createAction(
  '[Learning Progress] Get By User Id And Microcourse Id Failure',
  props<{ errorMessage: string }>()
);

export const count = createAction(
  '[Learning Progress] Count',
  props<{ userId: string }>()
);
export const countSuccess = createAction(
  '[Learning Progress] Count Success',
  props<{ count: number }>()
);
export const countFail = createAction(
  '[Learning Progress] Count Failure',
  props<{ errorMessage: string }>()
);

export const getByUserId = createAction(
  '[Learning Progress] Get By User Id',
  props<{ userId: string }>()
);
export const getByUserIdSuccess = createAction(
  '[Learning Progress] Get By User Id Success',
  props<{ learningProgresses: LearningProgress[] }>()
);
export const getByUserIdFail = createAction(
  '[Learning Progress] Get By User Id Failure',
  props<{ errorMessage: string }>()
);

export const clearGetLearningProgressesByUserIdState = createAction(
  '[Learning Progress] Clear Get Learning Progresses By User Id State'
);

export const clearStates = createAction('[Learning Progress] Clear States');
