import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BulkService } from '../../services/bulk/bulk.service';

import * as BulkActions from './bulk.actions';
import { catchError, map, of, switchMap } from 'rxjs';

@Injectable()
export class BulkEffects {
  constructor(
    private actions$: Actions,
    private bulkService: BulkService,
  ) {}

  exportBulk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BulkActions.exportBulk),
      switchMap(({ courseId }) => {
        return this.bulkService.export(courseId).pipe(
          map((data) => {
            return BulkActions.exportBulkSuccess({ objectFile: data });
          }),
          catchError((error) => {
            return of(BulkActions.exportBulkFailure({ error }));
          }),
        );
      }),
    );
  });

  importBulk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BulkActions.importBulk),
      switchMap(({ courseId, data }) => {
        return this.bulkService.import(courseId, data).pipe(
          map(() => {
            return BulkActions.importBulkSuccess();
          }),
          catchError((error) => {
            return of(BulkActions.importBulkFailure({ error }));
          }),
        );
      }),
    );
  });
}
