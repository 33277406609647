import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import EditorJS, { LogLevels, OutputData } from '@editorjs/editorjs';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';

const Header = require('@editorjs/header');
const LinkTool = require('@editorjs/link');
const Checklist = require('@editorjs/checklist');
const Quote = require('@editorjs/quote');
const ImageTool = require('@editorjs/image');
const Embed = require('@editorjs/embed');
const Table = require('@editorjs/table');
const NestedList = require('@editorjs/nested-list');
const CodeTool = require('@editorjs/code');
const InlineCode = require('@editorjs/inline-code');
const AnyButton = require('editorjs-button');
const RawTool = require('@editorjs/raw');
const Warning = require('@editorjs/warning');
const Delimiter = require('@editorjs/delimiter');
const Marker = require('@editorjs/marker');
const Underline = require('@editorjs/underline');
const Paragraph = require('@editorjs/paragraph');
const List = require('@editorjs/list');
const Undo = require('editorjs-undo');

@Component({
  selector: 'app-basic-editor',
  templateUrl: './basic-editor.component.html',
  styleUrls: ['./basic-editor.component.scss'],
})
export class BasicEditorComponent implements OnDestroy, OnInit {
  @Input() data: OutputData | undefined;
  @Output('dataChange') dataChange = new EventEmitter<OutputData | undefined>();
  @ViewChild('editor', { read: ElementRef, static: true })
  editorElement?: ElementRef;

  @Input('token') token: string = '';
  @Input('courseId') courseId: string = '';
  @Input('skillsetId') skillsetId: string = '';

  @Output('isContentChanged') isContentChanged = new EventEmitter<boolean>();

  private editor?: EditorJS;
  private unsubscribe$ = new Subject<void>();
  private isEditorInitialized = false;

  constructor() {}

  ngOnInit(): void {
    // console.log(this.data);
    this.initializeEditor(this.token, this.courseId, this.skillsetId);

    setTimeout(() => {
      if (this.data?.blocks === undefined) {
        this.editor?.blocks.insert('header', {
          level: 2,
          text: 'Content goes here...',
        });
      }
    }, 600);
  }

  ngOnDestroy(): void {
    this.editor?.destroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeEditor(
    token: string,
    courseId: string = '',
    skillsetId: string = '',
  ): void {
    if (courseId === '' && skillsetId === '') {
      console.error('CourseId or SkillsetId is required');
      return;
    } else if (courseId !== '' && skillsetId !== '') {
      console.error('CourseId and SkillsetId cannot be used at the same time');
      return;
    } else if (courseId !== '' && skillsetId === '') {
      this.initializeCourseEditor(token, courseId);
    } else if (courseId === '' && skillsetId !== '') {
      this.initializeSkillsetEditor(token, skillsetId);
    } else {
      console.error('CourseId or SkillsetId is required');
      return;
    }
  }

  initializeSkillsetEditor(token: string, skillsetId: string) {
    try {
      this.editor = new EditorJS({
        readOnly: false,
        placeholder: 'Content goes here...',
        holder: this.editorElement?.nativeElement,
        logLevel: 'ERROR' as LogLevels,
        inlineToolbar: true,
        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+H',
          },
          image: {
            class: ImageTool,
            config: {
              uploader: {
                /**
                 * Upload file to the server and return an uploaded image data
                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                 * @return {Promise.<{success, file: {url}}>}
                 */
                uploadByFile(file: File) {
                  // fetch api
                  const formData = new FormData();
                  formData.append('file', file);
                  formData.append(
                    'file_name',
                    'skillsets/' + skillsetId + '/content/' + file.name,
                  );
                  console.log(formData);

                  // add Authorization header

                  return fetch(environment.baseUrl + '/v2/storage', {
                    method: 'POST',
                    body: formData,
                    headers: {
                      Authorization: 'Bearer ' + token,
                    },
                  }).then((response) => {
                    return response.json().then(() => {
                      return {
                        success: 1,
                        file: {
                          url:
                            environment.storageApi +
                            '/akademy/skillsets/' +
                            skillsetId +
                            '/content/' +
                            file.name,
                        },
                      };
                    });
                  });
                },
              },
            },
          },
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered',
            },
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+O',
            config: {
              quotePlaceholder: 'Enter a quote',
              captionPlaceholder: "Quote's author",
            },
          },
          code: {
            class: CodeTool,
            shortcut: 'CMD+SHIFT+C',
          },
          warning: Warning,
          marker: {
            class: Marker,
            shortcut: 'CMD+SHIFT+M',
            config: {
              sanitize: true,
            },
          },
          delimiter: Delimiter,
          inlineCode: {
            class: InlineCode,
          },
          link: LinkTool,
          embed: Embed,
          table: {
            class: Table,
            inlineToolbar: true,
          },
          AnyButton: {
            class: AnyButton,
            inlineToolbar: true,
          },
          raw: RawTool,
          underline: Underline,
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
        },

        data: this.data,

        onReady: () => {
          const undo = new Undo({ editor: this.editor });
          undo.initialize(this.data || {});

          console.log('Editor.js is ready to work!');

          if (
            this.data?.blocks !== undefined &&
            this.data?.blocks.length !== 0
          ) {
            this.editor?.render(this.data);
          }
          this.editor?.on('change', (event) => this.onDataChange(event));
        },
        onChange: (api, event) => this.onDataChange(event),
      });
      this.isEditorInitialized = true;
    } catch (e) {
      console.log('Editor initialization failed', e);
    }
  }

  initializeCourseEditor(token: string, courseId: string) {
    try {
      this.editor = new EditorJS({
        readOnly: false,
        placeholder: 'Content goes here...',
        holder: this.editorElement?.nativeElement,
        logLevel: 'ERROR' as LogLevels,
        inlineToolbar: true,
        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+H',
          },
          image: {
            class: ImageTool,
            config: {
              uploader: {
                /**
                 * Upload file to the server and return an uploaded image data
                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                 * @return {Promise.<{success, file: {url}}>}
                 */
                uploadByFile(file: File) {
                  // fetch api
                  const formData = new FormData();
                  formData.append('file', file);
                  formData.append(
                    'file_name',
                    'courses/' + courseId + '/content/' + file.name,
                  );
                  console.log(formData);

                  // add Authorization header

                  return fetch(environment.baseUrl + '/v2/storage', {
                    method: 'POST',
                    body: formData,
                    headers: {
                      Authorization: 'Bearer ' + token,
                    },
                  }).then((response) => {
                    return response.json().then((json) => {
                      return {
                        success: 1,
                        file: {
                          url:
                            environment.storageApi +
                            '/akademy/courses/' +
                            courseId +
                            '/content/' +
                            file.name,
                        },
                      };
                    });
                  });
                },
              },
            },
          },
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered',
            },
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+O',
            config: {
              quotePlaceholder: 'Enter a quote',
              captionPlaceholder: "Quote's author",
            },
          },
          code: {
            class: CodeTool,
            shortcut: 'CMD+SHIFT+C',
          },
          warning: Warning,
          marker: {
            class: Marker,
            shortcut: 'CMD+SHIFT+M',
            config: {
              sanitize: true,
            },
          },
          delimiter: Delimiter,
          inlineCode: {
            class: InlineCode,
          },
          link: LinkTool,
          embed: Embed,
          table: {
            class: Table,
            inlineToolbar: true,
          },
          AnyButton: {
            class: AnyButton,
            inlineToolbar: true,
          },
          raw: RawTool,
          underline: Underline,
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
        },

        data: this.data,

        onReady: () => {
          const undo = new Undo({ editor: this.editor });
          undo.initialize(this.data || {});

          console.log('Editor.js is ready to work!');

          if (
            this.data?.blocks !== undefined &&
            this.data?.blocks.length !== 0
          ) {
            this.editor?.render(this.data);
          }
          this.editor?.on('change', (event) => this.onDataChange(event));
        },
        onChange: (api, event) => this.onDataChange(event),
      });
      this.isEditorInitialized = true;
    } catch (e) {
      console.log('Editor initialization failed', e);
    }
  }

  private onDataChange(data: any): void {
    if (this.isEditorInitialized && this.editor) {
      this.editor
        .save()
        .then((savedData) => {
          this.dataChange.emit(savedData);
          this.isContentChanged.emit(true);
          console.log('Saving successful: ', savedData);
        })
        .catch((error) => {
          console.log('Saving failed: ', error);
        });
    } else {
      console.log('Editor is not initialized.');
    }
  }
}
