import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { objectToCamel } from 'src/app/utils/objectTransform';
import { EnrollmentService } from 'src/app/services/enrollment/enrollment.service';
import * as EnrollmentActions from './enrollment.actions';
import { EnrollmentResponse } from 'src/app/models/enrollment.model';

@Injectable()
export class EnrollmentEffects {
  constructor(
    private actions$: Actions,
    private enrollmentService: EnrollmentService
  ) {}

  enroll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.enroll),
      switchMap((action) => {
        return this.enrollmentService.enroll(action.microcourseId).pipe(
          map(() => {
            return EnrollmentActions.enrollSuccess();
          }),
          catchError((error) => {
            // console.log(error);
            return of(EnrollmentActions.enrollFailure({ errorMessage: error }));
          })
        );
      })
    );
  });

  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.deleteById),
      switchMap((action) => {
        return this.enrollmentService.deleteById(action.id).pipe(
          map(() => {
            return EnrollmentActions.deleteByIdSuccess();
          }),
          catchError((error) => {
            console.log(error);
            return of(
              EnrollmentActions.deleteByIdFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });

  listByProfileId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.listByProfileId),
      switchMap((action) => {
        return this.enrollmentService
          .listByProfileId(action.id, action.page, action.size)
          .pipe(
            map((data) => {
              return EnrollmentActions.listByProfileIdSuccess({
                enrollments: <EnrollmentResponse>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                EnrollmentActions.listByProfileIdFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });

  listByMicrocourseId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.listByMicrocourseId),
      switchMap((action) => {
        return this.enrollmentService
          .listByMicrocourseId(action.id, action.page, action.size)
          .pipe(
            map((data) => {
              return EnrollmentActions.listByMicrocourseIdSuccess({
                enrollments: <EnrollmentResponse>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                EnrollmentActions.listByMicrocourseIdFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });

  countByMicrocourseId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.countByMicrocourseId),
      switchMap((action) => {
        return this.enrollmentService.countByMicrocourseId(action.id).pipe(
          map((data) => {
            return EnrollmentActions.countByMicrocourseIdSuccess({
              count: data.count,
            });
          }),
          catchError((error) => {
            return of(
              EnrollmentActions.countByMicrocourseIdFailure({
                errorMessage: error,
              })
            );
          })
        );
      })
    );
  });

  countByProfileId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.countByProfileId),
      switchMap((action) => {
        return this.enrollmentService.countByProfileId(action.id).pipe(
          map((data) => {
            return EnrollmentActions.countByProfileIdSuccess({
              count: data.count,
            });
          }),
          catchError((error) => {
            return of(
              EnrollmentActions.countByProfileIdFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });
}
