import { Injectable } from '@angular/core';
import { HttpClientWithAuth } from '../../utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class BulkService {
  constructor(private http: HttpClientWithAuth) {}

  export(courseId: string) {
    return this.http.get(`/bulk/microcourse/export?course_id=${courseId}`, {
      responseType: 'blob',
    });
  }

  import(courseId: string, data: any) {
    return this.http.post(
      `/bulk/microcourse/restore?course_id=${courseId}`,
      data,
    );
  }
}
