import {IndexedDbState} from "./indexed-db.state";
import {AttemptData} from "../../models/indexed_db.model";
import {createReducer, on} from "@ngrx/store";
import * as IndexedDbActions from "./indexed-db.actions";

export const initialState: IndexedDbState = {
  attemptData: <AttemptData>{},
  isCreating: false,
  isCreateSuccess: false,
  createErrorMessages: '',
  isUpdating: false,
  isUpdateSuccess: false,
  updateErrorMessages: '',
  isGetting: false,
  getErrorMessages: '',
  isInit: false
};

export const indexedDbReducer = createReducer(
  initialState,
  on(IndexedDbActions.create, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isCreating: true,
      isCreateSuccess: false,
      createErrorMessages: '',
    };
  }),
  on(IndexedDbActions.createSuccess, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isCreating: false,
      isCreateSuccess: true,
    };
  }),
  on(IndexedDbActions.createFailure, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isCreating: false,
      createErrorMessages: action.errorMessage,
    };
  }),
  on(IndexedDbActions.update, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isUpdating: true,
      isUpdateSuccess: false,
      updateErrorMessages: '',
    };
  }),
  on(IndexedDbActions.updateSuccess, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isUpdating: false,
      isUpdateSuccess: true,
    };
  }),
  on(IndexedDbActions.updateFailure, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isUpdating: false,
      updateErrorMessages: action.errorMessage,
    };
  }),
  on(IndexedDbActions.get, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isGetting: true,
      attemptData: <AttemptData>{},
      getErrorMessages: '',
    };
  }),
  on(IndexedDbActions.getSuccess, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isGetting: false,
      attemptData: action.attemptData,
    };
  }),
  on(IndexedDbActions.getFailure, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isGetting: false,
      getErrorMessages: action.errorMessage,
    };
  }),
  on(IndexedDbActions.init, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isInit: true,
    };
  }),
  on(IndexedDbActions.resetInit, (state, action) => {
    console.log(action.type)
    return <IndexedDbState>{
      ...state,
      isInit: false,
    };
  })
)

