import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { camelToSnake, objectToCamel } from 'src/app/utils/objectTransform';

import { VoucherService } from 'src/app/services/voucher/voucher.service';
import * as VoucherActions from './voucher.actions';
import { Batch, Voucher, VoucherResponse } from 'src/app/models/voucher.model';

@Injectable()
export class VoucherEffects {
  constructor(
    private actions$: Actions,
    private voucherService: VoucherService,
  ) {}

  check$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VoucherActions.check),
      switchMap((action) => {
        return this.voucherService.check(action.voucher).pipe(
          map((data) => {
            return VoucherActions.checkSuccess({
              voucher: <Voucher>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(VoucherActions.checkFailure({ errorMessage: error }));
          }),
        );
      }),
    );
  });

  redeem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VoucherActions.redeem),
      switchMap((action) => {
        return this.voucherService.redeem(action.voucher).pipe(
          map(() => {
            return VoucherActions.redeemSuccess();
          }),
          catchError((error) => {
            return of(VoucherActions.redeemFailure({ errorMessage: error }));
          }),
        );
      }),
    );
  });

  getByBatchId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VoucherActions.getByBatchId),
      switchMap((action) => {
        return this.voucherService
          .getListByBatchId(action.batchId, action.page, action.size)
          .pipe(
            map((data) => {
              return VoucherActions.getByBatchIdSuccess({
                voucherResponse: <VoucherResponse>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                VoucherActions.getByBatchIdFailure({ errorMessage: error }),
              );
            }),
          );
      }),
    );
  });

  generate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VoucherActions.generate),
      switchMap((action) => {
        return this.voucherService.generate(camelToSnake(action.batch)).pipe(
          map(() => {
            return VoucherActions.generateSuccess();
          }),
          catchError((error) => {
            return of(VoucherActions.generateFailure({ errorMessage: error }));
          }),
        );
      }),
    );
  });

  getByTimeRange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VoucherActions.getBatchByTimeRange),
      switchMap((action) => {
        return this.voucherService
          .getBatchByTimeRange(action.from, action.to)
          .pipe(
            map((data) => {
              return VoucherActions.getBatchByTimeRangeSuccess({
                batchList: <Batch[]>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                VoucherActions.getBatchByTimeRangeFailure({
                  errorMessage: error,
                }),
              );
            }),
          );
      }),
    );
  });
}
