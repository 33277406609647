<div class="search-content">
  <span class="search-title">
    Hiện tại giao diện chưa hỗ trợ trên thiết bị di động.
  </span>
  <p>
    <button
      (click)="close()"
      size="m"
      tuiButton
      type="button"
    >
      Đã hiểu
    </button>
  </p>

</div>
