import { createAction, props } from '@ngrx/store';
import {
  MentorProfile,
  MentorProfileData,
  MentorProfileResponse,
} from 'src/app/models/profile.model';

export const getMineProfile = createAction('[Mentor Profile] Get Mine Profile');

export const getMineProfileSuccess = createAction(
  '[Mentor Profile] Get Mine Profile Success',
  props<{ mine: MentorProfile }>(),
);

export const getMineProfileFailure = createAction(
  '[Mentor Profile] Get Mine Profile Failure',
  props<{ errorMessage: string }>(),
);

export const createMineProfile = createAction(
  '[Mentor Profile] Create Mine Profile',
  props<{ profile: MentorProfileData }>(),
);

export const createMineProfileSuccess = createAction(
  '[Mentor Profile] Create Mine Profile Success',
);

export const createMineProfileFailure = createAction(
  '[Mentor Profile] Create Mine Profile Failure',
  props<{ errorMessage: string }>(),
);

export const updateMineProfile = createAction(
  '[Mentor Profile] Update Mine Profile',
  props<{ profile: MentorProfileData }>(),
);

export const updateMineProfileSuccess = createAction(
  '[Mentor Profile] Update Mine Profile Success',
);

export const updateMineProfileFailure = createAction(
  '[Mentor Profile] Update Mine Profile Failure',
  props<{ errorMessage: string }>(),
);

export const listByStatus = createAction(
  '[Mentor Profile] List by Status',
  props<{ status: number; size: number; page: number }>(),
);

export const listByStatusSuccess = createAction(
  '[Mentor Profile] List by Status Success',
  props<{ mentorResponse: MentorProfileResponse }>(),
);

export const listByStatusFailure = createAction(
  '[Mentor Profile] List by Status Failure',
  props<{ errorMessage: string }>(),
);

export const getMentorProfileById = createAction(
  '[Mentor Profile] Get Mentor Profile by Id',
  props<{ id: string }>(),
);

export const getMentorProfileByIdSuccess = createAction(
  '[Mentor Profile] Get Mentor Profile by Id Success',
  props<{ mentor: MentorProfile }>(),
);

export const getMentorProfileByIdFailure = createAction(
  '[Mentor Profile] Get Mentor Profile by Id Failure',
  props<{ errorMessage: string }>(),
);

export const changeApproveStatus = createAction(
  '[Mentor Profile] Change Approve Status',
  props<{ id: string; status: number }>(),
);

export const changeApproveStatusSuccess = createAction(
  '[Mentor Profile] Change Approve Status Success',
);

export const changeApproveStatusFailure = createAction(
  '[Mentor Profile] Change Approve Status Failure',
  props<{ errorMessage: string }>(),
);

export const approveProfile = createAction(
  '[Mentor Profile] Approve Profile',
  props<{ id: string }>(),
);

export const approveProfileSuccess = createAction(
  '[Mentor Profile] Approve Profile Success',
);

export const approveProfileFailure = createAction(
  '[Mentor Profile] Approve Profile Failure',
  props<{ errorMessage: string }>(),
);

export const changeCommissionRate = createAction(
  '[Mentor Profile] Change Commission Rate',
  props<{ id: string; commissionRate: number }>(),
);

export const changeCommissionRateSuccess = createAction(
  '[Mentor Profile] Change Commission Rate Success',
);

export const changeCommissionRateFailure = createAction(
  '[Mentor Profile] Change Commission Rate Failure',
  props<{ errorMessage: string }>(),
);

export const clearCreateMentorProfileStates = createAction(
  '[Mentor Profile] Clear Create Mentor Profile States',
);

export const clearUpdateMentorProfileStates = createAction(
  '[Mentor Profile] Clear Update Mentor Profile States',
);

export const clearGetListMentorProfileByStatusStates = createAction(
  '[Mentor Profile] Clear Get List Mentor Profile By Status States',
);

export const clearApproveMentorProfileStates = createAction(
  '[Mentor Profile] Clear Approve Mentor Profile States',
);

export const clearChangeApproveStatusStates = createAction(
  '[Mentor Profile] Clear Change Approve Status States',
);

export const clearChangeCommissionStates = createAction(
  '[Mentor Profile] Clear Change Commission States',
);
