import { BulkState } from './bulk.state';
import { createReducer, on } from '@ngrx/store';

import * as BulkActions from './bulk.actions';

export const initialState: BulkState = {
  courseId: '',
  objectFile: null,
  isExporting: false,
  exportError: '',

  isImporting: false,
  isImportSuccess: false,
  importError: '',
};

export const bulkReducer = createReducer(
  initialState,
  on(BulkActions.clearStates, (state) => {
    return <BulkState>{
      ...state,
      courseId: '',
      objectFile: null,
      isExporting: false,
      exportError: '',
    
      isImporting: false,
      isImportSuccess: false,
      importError: '',
    };
  }),
  on(BulkActions.exportBulk, (state, { courseId, type }) => {
    console.log(type);
    return <BulkState>{
      ...state,
      isExporting: true,
    };
  }),
  on(BulkActions.exportBulkSuccess, (state, { objectFile, type }) => {
    console.log(type);
    return <BulkState>{
      ...state,
      isExporting: false,
      objectFile: objectFile,
    };
  }),
  on(BulkActions.exportBulkFailure, (state, { error, type }) => {
    console.log(type);
    return <BulkState>{
      ...state,
      isExporting: false,
      exportError: error,
    };
  }),

  on(BulkActions.importBulk, (state, { courseId, data, type }) => {
    console.log(type);
    return <BulkState>{
      ...state,
      isImporting: true,
      isImportSuccess: false,
    };
  }),
  on(BulkActions.importBulkSuccess, (state, { type }) => {
    console.log(type);
    return <BulkState>{
      ...state,
      isImporting: false,
      isImportSuccess: true,
    };
  }),
  on(BulkActions.importBulkFailure, (state, { error, type }) => {
    console.log(type);
    return <BulkState>{
      ...state,
      isImporting: false,
      isImportSuccess: false,
      importError: error,
    };
  }),
);
