import { PipeState } from './pipe.state';
import { createReducer, on } from '@ngrx/store';
import * as PipeActions from './pipe.actions';

const initialState: PipeState = {
  embedContentByContentJson: {},
  isTransformingContent: false,
  isTransformContentSuccess: false,
  transformContentErrorMessage: '',
};

export const pipeReducer = createReducer(
  initialState,
  // transformContent
  on(PipeActions.transformContent, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isTransformingContent: true,
      isTransformContentSuccess: false,
      transformContentErrorMessage: '',
    };
  }),
  // transformContentSuccess
  on(
    PipeActions.transformContentSuccess,
    (state, { type, embedContentByContentJson }) => {
      console.log(type);
      return {
        ...state,
        embedContentByContentJson: embedContentByContentJson,
        isTransformingContent: false,
        isTransformContentSuccess: true,
        transformContentErrorMessage: '',
      };
    },
  ),
  // transformContentErrorMessage
  on(
    PipeActions.transformContentErrorMessage,
    (state, { type, errorMessage }) => {
      console.log(type);
      return {
        ...state,
        embedContentByContentJson: {},
        isTransformingContent: false,
        isTransformContentSuccess: false,
        transformContentErrorMessage: errorMessage,
      };
    },
  ),

  // clearEmbedContent
  on(PipeActions.clearEmbedContent, (state) => ({
    ...state,
    embedContentByContentJson: {},
    isTransformingContent: false,
    isTransformContentSuccess: false,
    transformContentErrorMessage: '',
  })),

  // clearMessage
  on(PipeActions.clearMessage, (state) => ({
    ...state,
    isTransformingContent: false,
    isTransformContentSuccess: false,
    transformContentErrorMessage: '',
  })),
);
