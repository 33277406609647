import { createAction, props } from '@ngrx/store';
import { Assignment } from 'src/app/models/assignment.model';

export const getById = createAction(
  '[Assignment] Get By Id',
  props<{ id: string }>()
);

export const getByIdSuccess = createAction(
  '[Assignment] Get By Id Success',
  props<{ assignment: Assignment }>()
);

export const getByIdFailure = createAction(
  '[Assignment] Get By Id Failure',
  props<{ errorMessage: string }>()
);

export const create = createAction(
  '[Assignment] Create',
  props<{ assignment: Assignment }>()
);

export const createSuccess = createAction('[Assignment] Create Success');

export const createFailure = createAction(
  '[Assignment] Create Failure',
  props<{ errorMessage: string }>()
);

export const update = createAction(
  '[Assignment] Update',
  props<{ assignment: Assignment }>()
);

export const updateSuccess = createAction('[Assignment] Update Success');

export const updateFailure = createAction(
  '[Assignment] Update Failure',
  props<{ errorMessage: string }>()
);

export const resetStates = createAction('[Assignment] Reset States');
