import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import parser, {Parser} from 'src/app/utils/editor-parse';

@Component({
  selector: 'app-preview-editor',
  templateUrl: './preview-editor.component.html',
  styleUrls: ['./preview-editor.component.scss'],
})
export class PreviewEditorComponent implements OnInit, AfterViewInit {
  @Input('data') data: any;
  @Output('editor') editorEvent = new EventEmitter();
  @ViewChild('editor', {read: ElementRef, static: true})
  editorElement?: ElementRef;

  @ViewChild('previewEditor', {static: true}) previewEditor!: ElementRef;

  a: Parser = parser();
  html: any[] = [];

  code = `const language = 'typescript';`;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
    // console.log(this.data);
    if (this.data) {
      this.html = this.a.parse(this.data);
    }
  }

  ngAfterViewInit() {
    for (let i = 0; i < this.html.length; i++) {
      this.renderer.appendChild(this.previewEditor.nativeElement, this.html[i]);
    }
  }
}
