import { CreditState } from './credit.state';
import { createReducer, on } from '@ngrx/store';

import * as CreditActions from './credit.actions';

const initialState: CreditState = {
  credit: 0,
  isLoading: false,
  loadErrorMessage: '',
};

export const creditReducer = createReducer(
  initialState,
  on(CreditActions.getMine, (state, { type }) => {
    console.log(type);
    return <CreditState>{
      ...state,
      credit: 0,
      isLoading: true,
      loadErrorMessage: '',
    };
  }),
  on(CreditActions.getMineSuccess, (state, { type, credit }) => {
    console.log(type);
    return <CreditState>{
      ...state,
      isLoading: false,
      credit: credit,
    };
  }),
  on(CreditActions.getMineFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <CreditState>{
      ...state,
      isLoading: false,
      loadErrorMessage: errorMessage,
    };
  })
);
