<tui-root>
  <div id="root-container">
    <router-outlet></router-outlet>
  </div>
  <div [ngClass]="overlayStyles"></div>
  <aside [ngClass]="drawerStyles">
    <div class="MenuOverlay" (click)="closeDrawer()"></div>
    <div class="MenuContainer">
      <app-course-detail-drawer
        [course]="(drawerMicrocourseContent$ | async) ?? defaultCourse"
      ></app-course-detail-drawer>
    </div>
  </aside>
</tui-root>
