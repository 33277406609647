import {createAction, props} from '@ngrx/store';
import {Role} from 'src/app/models/role.model';

export const RoleActions = {
  createRole: createAction('[Role] Create Role', props<{ newRole: Role }>()),
  createRoleSuccess: createAction('[Role] Create Role Success'),
  createRoleFailure: createAction(
    '[Role] Create Role Failure',
    props<{ errorMessage: string }>()
  ),

  updateRole: createAction('[Role] Update Role', props<{ role: Role }>()),
  updateRoleSuccess: createAction('[Role] Update Role Success'),
  updateRoleFailure: createAction(
    '[Role] Update Role Failure',
    props<{ errorMessage: string }>()
  ),

  deleteRole: createAction('[Role] Delete Role', props<{ role: Role }>()),
  deleteRoleSuccess: createAction('[Role] Delete Role Success'),
  deleteRoleFailure: createAction(
    '[Role] Delete Role Failure',
    props<{ errorMessage: string }>()
  ),

  getAllRoles: createAction('[Role] Get All Roles'),
  getAllRolesSuccess: createAction(
    '[Role] Get All Roles Success',
    props<{ roles: Role[] }>()
  ),
  getAllRolesFailure: createAction(
    '[Role] Get All Roles Failure',
    props<{ errorMessage: string }>()
  ),

  clearStates: createAction('[Role] Clear States'),
};
