import { createReducer, on } from '@ngrx/store';
import { DrawerState } from './drawer.state';
import { Microcourse } from 'src/app/models/microcourse.model';
import * as DrawerActions from './drawer.actions';

const initialState: DrawerState = {
  drawerMicrocourseContent: <Microcourse>{},
  isOpen: false,
};

export const drawerReducer = createReducer(
  initialState,
  on(DrawerActions.open, (state, { course, type }) => {
    console.log(type);
    return <DrawerState>{
      ...state,
      drawerMicrocourseContent: course,
      isOpen: true,
    };
  }),
  on(DrawerActions.close, (state, action) => {
    console.log(action.type);
    return <DrawerState>{
      ...state,
      // drawerMicrocourseContent: <Microcourse>{},
      isOpen: false,
    };
  })
);
