import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {IndexedDBService} from "../../services/indexed-db/indexed-db.service";
import * as IndexedDBActions from './indexed-db.actions';
import {catchError, map, of, switchMap} from "rxjs";
import {AttemptData} from "../../models/indexed_db.model";

@Injectable()
export class IndexedDbEffects {

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IndexedDBActions.get),
      switchMap((action) => {
        return this.indexedDbService.getAttemptDataInIndexedDB(action.courseId, action.profileId).pipe(
          map((data) => {
            return IndexedDBActions.getSuccess({attemptData: data as AttemptData});
          }),
          catchError((err) => {
            return of(
              IndexedDBActions.getFailure({errorMessage: err})
            )
          })
        )
      })
    )
  })

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IndexedDBActions.create),
      switchMap((action) => {
        return this.indexedDbService.createAttemptDataInIndexedDB(action.attemptData).pipe(
          map(() => {
            return IndexedDBActions.createSuccess();
          }),
          catchError((err) => {
            return of(
              IndexedDBActions.createFailure({errorMessage: err})
            )
          })
        )
      })
    )
  })

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IndexedDBActions.update),
      switchMap((action) => {
        return this.indexedDbService.updateAttemptDataInIndexedDB(action.attemptData).pipe(
          map(() => {
            return IndexedDBActions.updateSuccess();
          }),
          catchError((err) => {
            return of(
              IndexedDBActions.updateFailure({errorMessage: err})
            )
          })
        )
      })
    )
  })

  constructor(
    private actions$: Actions,
    private indexedDbService: IndexedDBService
  ) {
  }
}
