import {Assignment} from 'src/app/models/assignment.model';
import {AssignmentState} from './assignment.state';
import {createReducer, on} from '@ngrx/store';

import * as AssignmentActions from './assignment.actions';

const initialState: AssignmentState = {
  assignment: <Assignment>{},

  isLoading: false,
  isSuccess: false,

  isCreating: false,
  isCreateSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,

  isFailure: false,

  errorMessage: '',
};

export const assignmentReducer = createReducer(
  initialState,

  on(AssignmentActions.create, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isCreating: true,
      isCreateSuccess: false,
      errorMessage: '',
    };
  }),
  on(AssignmentActions.createSuccess, (state, {type}) => {
    console.log(type);
    return {
      ...state,
      isCreating: false,
      isCreateSuccess: true,
    };
  }),
  on(AssignmentActions.createFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return {
      ...state,
      isCreating: false,
      isCreateSuccess: false,
      errorMessage: errorMessage,
    };
  }),

  on(AssignmentActions.update, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUpdating: true,
      isUpdateSuccess: false,
      errorMessage: '',
    };
  }),
  on(AssignmentActions.updateSuccess, (state, {type}) => {
    console.log(type);
    return {
      ...state,
      isUpdating: false,
      isUpdateSuccess: true,
    };
  }),
  on(AssignmentActions.updateFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return {
      ...state,
      isUpdating: false,
      isUpdateSuccess: false,
      errorMessage: errorMessage,
    };
  }),

  on(AssignmentActions.getById, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isLoading: true,
      isSuccess: false,
      assignment: <Assignment>{},
      errorMessage: '',
    };
  }),
  on(AssignmentActions.getByIdSuccess, (state, {type, assignment}) => {
    console.log(type);
    return {
      ...state,
      isLoading: false,
      isSuccess: true,
      assignment: assignment,
    };
  }),
  on(AssignmentActions.getByIdFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return {
      ...state,
      isLoading: false,
      isSuccess: false,
      isFailure: true,
      errorMessage: errorMessage,
    };
  }),

  on(AssignmentActions.resetStates, (state) => {
    return {
      ...state,
      isCreating: false,
      isCreateSuccess: false,
      isUpdating: false,
      isUpdateSuccess: false,
      isFailure: false,
      errorMessage: '',
    };
  })
);
