import { createAction, props } from '@ngrx/store';

export const transformContent = createAction(
  '[Pipe] Transform Content',
  props<{ contentJson: any }>(),
);
export const transformContentSuccess = createAction(
  '[Pipe] Transform Content Success',
  props<{ embedContentByContentJson: any }>(),
);
export const transformContentErrorMessage = createAction(
  '[Pipe] Transform Content Error Message',
  props<{ errorMessage: string }>(),
);

export const clearEmbedContent = createAction('[Pipe] Clear Embed Content');

export const clearMessage = createAction('[Pipe] Clear Message');
