import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpClientWithAuth} from "../../utils/HttpClientWithAuth";

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  constructor(private http: HttpClientWithAuth) {
  }

  getMine() {
    return this.http.get('/credit/balance/mine');
  }
}
