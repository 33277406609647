import { FeedbackState } from './feedback.state';
import { FeedbackRepsonse } from '../../models/feedback.model';
import { createReducer, on } from '@ngrx/store';
import * as FeedbackActions from './feedback.actions';

const initialState: FeedbackState = {
  myFeedback: null,
  isCreating: false,
  isCreateSuccess: false,
  createErrorMessage: '',
  feedbackResponse: <FeedbackRepsonse>{},
  isGetting: false,
  getErrorMessage: '',
};

export const feedbackReducer = createReducer(
  initialState,
  on(FeedbackActions.feedbackCourse, (state, { type }) => {
    console.log(type);
    return <FeedbackState>{
      ...state,
      isCreating: true,
      isCreateSuccess: false,
      createErrorMessage: '',
    };
  }),
  on(FeedbackActions.feedbackCourseSuccess, (state, { type }) => {
    console.log(type);
    return <FeedbackState>{
      ...state,
      isCreating: false,
      isCreateSuccess: true,
      createErrorMessage: '',
    };
  }),
  on(FeedbackActions.feedbackCourseFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <FeedbackState>{
      ...state,
      isCreating: false,
      createErrorMessage: errorMessage,
    };
  }),
  on(FeedbackActions.listByCourseId, (state, { type }) => {
    console.log(type);
    return <FeedbackState>{
      ...state,
      feedbackResponse: <FeedbackRepsonse>{},
      isGetting: true,
    };
  }),
  on(
    FeedbackActions.listByCourseIdSuccess,
    (state, { type, feedbackResponse }) => {
      console.log(type);
      return <FeedbackState>{
        ...state,
        feedbackResponse: feedbackResponse,
        isGetting: false,
      };
    }
  ),
  on(FeedbackActions.listByCourseIdFailure, (state, { type }) => {
    console.log(type);
    return <FeedbackState>{
      ...state,
      isGetting: false,
    };
  }),
  on(FeedbackActions.getByUserIdAndCourseId, (state, { type }) => {
    console.log(type);
    return <FeedbackState>{
      ...state,
      myFeedback: null,
    };
  }),
  on(
    FeedbackActions.getByUserIdAndCourseIdSuccess,
    (state, { type, feedback }) => {
      console.log(type);
      return <FeedbackState>{
        ...state,
        myFeedback: feedback,
      };
    }
  ),
  on(FeedbackActions.getByUserIdAndCourseIdFailure, (state, { type }) => {
    console.log(type);
    return <FeedbackState>{
      ...state,
    };
  }),
  on(FeedbackActions.clearStates, (state, { type }) => {
    console.log(type);
    return <FeedbackState>{
      ...state,
      myFeedback: null,
      isCreating: false,
      isCreateSuccess: false,
      createErrorMessage: '',
      feedbackResponse: <FeedbackRepsonse>{},
      isGetting: false,
      getErrorMessage: '',
    };
  })
);
