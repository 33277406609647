import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthState } from '../ngrx/auth/auth.state';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpClientWithAuth {
  // override post() method
  token: string | null = null;
  jwt: string | null = null;

  constructor(
    private http: HttpClient,
    private store: Store<{ auth: AuthState }>,
  ) {
    this.store.select('auth', 'jwt').subscribe((jwt) => {
      this.jwt = jwt;
    });
    this.store.select('auth', 'idToken').subscribe((idToken) => {
      this.token = idToken;
    });
  }

  get(url: string, options?: any): Observable<any> {
    const { url: newUrl, options: newOptions } = this.transformRequest(
      url,
      options,
    );
    // console.log("Opt:", newOptions)
    return this.http.get(newUrl, newOptions);
  }

  post(url: string, body: any, options?: any): Observable<any> {
    const { url: newUrl, options: newOptions } = this.transformRequest(
      url,
      options,
    );
    // console.log("Opt:", newOptions)
    return this.http.post(newUrl, body, newOptions);
  }

  put(url: string, body: any, options?: any): Observable<any> {
    const { url: newUrl, options: newOptions } = this.transformRequest(
      url,
      options,
    );
    // console.log("Opt:", newOptions)
    return this.http.put(newUrl, body, newOptions);
  }

  delete(url: string, options?: any): Observable<any> {
    const { url: newUrl, options: newOptions } = this.transformRequest(
      url,
      options,
    );
    // console.log("Opt:", newOptions)
    return this.http.delete(newUrl, newOptions);
  }

  private transformRequest(url: string, options: any) {
    let token = '';
    let newUrl = '';
    if (url.includes('auth')) {
      if (url.includes('google')) {
        token = this.token as string;
      } else {
        token = this.jwt as string;
      }
    } else {
      token = this.jwt as string;
    }

    newUrl = `${environment.baseUrl}/${environment.baseVersion}${url}`;

    if (url.includes('storage')) {
      newUrl = `${environment.baseUrl}/${environment.nextVersion}${url}`;
    } else if (url.includes('editorpipe')) {
      newUrl = `${url}`;
    }

    return {
      url: newUrl,
      options: {
        headers: new HttpHeaders({
          // 'Content-Type': 'application/json',
          Authorization: `${token}`,
        }),
        ...options,
      },
    };
  }
}
