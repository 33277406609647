import { createAction, props } from '@ngrx/store';
import { UploadedFile } from 'src/app/models/storage.model';

export const uploadUserAvatar = createAction(
  '[Storage] Upload User Avatar',
  props<{ file: File; fileName: string }>()
);
export const uploadUserAvatarSuccess = createAction(
  '[Storage] Upload User Avatar Success',
  props<{ uploadedUserAvatar: UploadedFile }>()
);
export const uploadUserAvatarFailure = createAction(
  '[Storage] Upload User Avatar Failure',
  props<{ errorMessage: string }>()
);

export const uploadMicrocoursePhotoCover = createAction(
  '[Storage] Upload Microcourse Photo Cover',
  props<{ file: File; fileName: string }>()
);
export const uploadMicrocoursePhotoCoverSuccess = createAction(
  '[Storage] Upload Microcourse Photo Cover Success',
  props<{ uploadedMicrocoursePhotoCover: UploadedFile }>()
);
export const uploadMicrocoursePhotoCoverFailure = createAction(
  '[Storage] Upload Microcourse Photo Cover Failure',
  props<{ errorMessage: string }>()
);

export const uploadMicrocourseBadgeImageFile = createAction(
  '[Storage] Upload Badge Image File',
  props<{ file: File; fileName: string }>()
);
export const uploadMicrocourseBadgeImageFileSuccess = createAction(
  '[Storage] Upload Badge Image File Success',
  props<{ uploadedMicrocourseBadgeImageFile: UploadedFile }>()
);
export const uploadMicrocourseBadgeImageFileFailure = createAction(
  '[Storage] Upload Badge Image File Failure',
  props<{ errorMessage: string }>()
);

export const uploadSkillsetPhotoCover = createAction(
  '[Storage] Upload Skillset Photo Cover',
  props<{ file: File; fileName: string }>()
);
export const uploadSkillsetPhotoCoverSuccess = createAction(
  '[Storage] Upload Skillset Photo Cover Success',
  props<{ uploadedSkillsetPhotoCover: UploadedFile }>()
);
export const uploadSkillsetPhotoCoverFailure = createAction(
  '[Storage] Upload Skillset Photo Cover Failure',
  props<{ errorMessage: string }>()
);

export const uploadSkillsetCertificateImageFile = createAction(
  '[Storage] Upload Skillset Certificate Image File',
  props<{ file: File; fileName: string }>()
);
export const uploadSkillsetCertificateImageFileSuccess = createAction(
  '[Storage] Upload Skillset Certificate Image File Success',
  props<{ uploadedSkillsetCertImageFile: UploadedFile }>()
);
export const uploadSkillsetCertificateImageFileFailure = createAction(
  '[Storage] Upload Skillset Certificate Image File Failure',
  props<{ errorMessage: string }>()
);

export const uploadSkillsetIconImageFile = createAction(
  '[Storage] Upload Skillset Icon Image File',
  props<{ file: File; fileName: string }>()
);
export const uploadSkillsetIconImageFileSuccess = createAction(
  '[Storage] Upload Skillset Icon Image File Success',
  props<{ uploadedSkillsetIconImageFile: UploadedFile }>()
);
export const uploadSkillsetIconImageFileFailure = createAction(
  '[Storage] Upload Skillset Icon Image File Failure',
  props<{ errorMessage: string }>()
);

export const clearUserAvatarStates = createAction(
  '[Storage] Clear User Avatar States'
);

export const clearStates = createAction('[Storage] Clear States');

export const clearMicrocoursePhotoCoverStates = createAction(
  '[Storage] Clear Microcourse Photo Cover States'
);
export const clearMicrocourseBadgeImageFileStates = createAction(
  '[Storage] Clear Badge Image File States'
);
export const clearSkillsetPhotoCoverStates = createAction(
  '[Storage] Clear Skillset Photo Cover States'
);
export const clearSkillsetCertificateImageFileStates = createAction(
  '[Storage] Clear Skillset Certificate Image File States'
);
export const clearSkillsetIconImageFileStates = createAction(
  '[Storage] Clear Skillset Icon Image File States'
);
