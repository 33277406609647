import { createReducer, on } from '@ngrx/store';
import { ACIState } from './aci.state';
// import {ACIActions} from './aci.actions';
import { ACI } from '../../models/aci.model';
import * as ACIActions from './aci.actions';

const initialState: ACIState = {
  aci: <ACI>{},
  isCreating: false,
  isCreateSuccess: false,
  createErrorMessage: '',

  isUpdating: false,
  isUpdateSuccess: false,
  updateErrorMessage: '',

  isDeleting: false,
  isDeleteSuccess: false,
  deleteErrorMessage: '',

  payloads: [],
  isGettingPayloads: false,
  isGetPayloadsSuccess: false,
  getPayloadsErrorMessage: '',

  personalAcl: [],
  isGettingByUserId: false,
  isGetByUserIdSuccess: false,
  getByUserIdErrorMessage: '',

  acl: [],
  isGettingByRoleId: false,
  isGetByRoleIdSuccess: false,
  getByRoleIdErrorMessage: '',

  countingTasks: 0,
  totalTasks: 0,
};

export const ACIReducer = createReducer(
  initialState,
  // clear messages
  on(ACIActions.clearAllMessage, (state, { type }) => {
    console.log(type);
    return {
      ...state,
      createErrorMessage: '',
      updateErrorMessage: '',
      deleteErrorMessage: '',
      getPayloadsErrorMessage: '',
      getByUserIdErrorMessage: '',
      getByRoleIdErrorMessage: '',
    };
  }),
  //clear acl states
  on(ACIActions.clearACLStates, (state, { type }) => {
    console.log(type);
    return {
      ...state,
      acl: [],
      isGettingByRoleId: false,
      isGetByRoleIdSuccess: false,
      getByRoleIdErrorMessage: '',
    };
  }),
  //clear personal acl states
  on(ACIActions.clearPersonalACLStates, (state, { type }) => {
    console.log(type);
    return {
      ...state,
      personalAcl: [],
      isGettingByUserId: false,
      isGetByUserIdSuccess: false,
      getByUserIdErrorMessage: '',
    };
  }),
  //clear payload states
  on(ACIActions.clearPayloadStates, (state, { type }) => {
    console.log(type);
    return {
      ...state,
      payloads: [],
      isGettingPayloads: false,
      isGetPayloadsSuccess: false,
      getPayloadsErrorMessage: '',
    };
  }),
  //clear aci states
  on(ACIActions.clearACIStates, (state, { type }) => {
    console.log(type);
    return {
      ...state,
      aci: <ACI>{},
      isCreating: false,
      isCreateSuccess: false,
      createErrorMessage: '',

      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: '',

      isDeleting: false,
      isDeleteSuccess: false,
      deleteErrorMessage: '',

      countingTasks: 0,
    };
  }),

  // create aci
  on(ACIActions.create, (state, { type }) => {
    console.log(type);
    return {
      ...state,
      isCreating: true,
      isCreateSuccess: false,
    };
  }),
  on(ACIActions.createSuccess, (state, { type }) => {
    console.log(type);
    return {
      ...state,
      isCreating: false,
      isCreateSuccess: true,
      countingTasks: state.countingTasks + 1,
    };
  }),
  on(ACIActions.createFailure, (state, { type, createErrorMessage }) => {
    console.log(type);
    return {
      ...state,
      isCreating: false,
      isCreateSuccess: false,
      createErrorMessage: createErrorMessage,
    };
  }),

  // update aci
  on(ACIActions.update, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUpdating: true,
      isUpdateSuccess: false,
    };
  }),
  on(ACIActions.updateSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUpdating: false,
      isUpdateSuccess: true,
      countingTasks: state.countingTasks + 1,
    };
  }),
  on(ACIActions.updateFailure, (state, { type, updateErrorMessage }) => {
    console.log(type);
    return {
      ...state,
      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: updateErrorMessage,
    };
  }),

  // delete aci
  on(ACIActions.deleteACI, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isDeleting: true,
      isDeleteSuccess: false,
    };
  }),
  on(ACIActions.deleteSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isDeleting: false,
      isDeleteSuccess: true,
      countingTasks: state.countingTasks + 1,
    };
  }),
  on(ACIActions.deleteFailure, (state, { type, deleteErrorMessage }) => {
    console.log(type);
    return {
      ...state,
      isDeleting: false,
      isDeleteSuccess: false,
      deleteErrorMessage: deleteErrorMessage,
    };
  }),

  // get aci by role id
  on(ACIActions.getByRoleId, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      acl: [],
      isGettingByRoleId: true,
      isGetByRoleIdSuccess: false,
    };
  }),
  on(ACIActions.getByRoleIdSuccess, (state, { type, acl }) => {
    console.log(type);
    return {
      ...state,
      acl: acl,
      isGettingByRoleId: false,
      isGetByRoleIdSuccess: true,
    };
  }),
  on(
    ACIActions.getByRoleIdFailure,
    (state, { type, getByRoleIdErrorMessage }) => {
      console.log(type);
      return {
        ...state,
        isGettingByRoleId: false,
        isGetByRoleIdSuccess: false,
        getByRoleIdErrorMessage: getByRoleIdErrorMessage,
      };
    },
  ),

  // get aci by user id
  on(ACIActions.getByUserId, (state, { type, userId }) => {
    console.log(type);
    return {
      ...state,
      personalAcl: [],
      isGettingByUserId: true,
      isGetByUserIdSuccess: false,
    };
  }),
  on(ACIActions.getByUserIdSuccess, (state, { type, personalAcl }) => {
    console.log(type);
    return {
      ...state,
      personalAcl: personalAcl,
      isGettingByUserId: false,
      isGetByUserIdSuccess: true,
    };
  }),
  on(
    ACIActions.getByUserIdFailure,
    (state, { type, getByUserIdErrorMessage }) => {
      console.log(type);
      return {
        ...state,
        isGettingByUserId: false,
        isGetByUserIdSuccess: false,
        getByUserIdErrorMessage: getByUserIdErrorMessage,
      };
    },
  ),

  on(ACIActions.getPayloadByResource, (state, { type }) => {
    console.log(type);
    return {
      ...state,
      isGettingPayloads: true,
      isGetPayloadsSuccess: false,
    };
  }),
  on(ACIActions.getPayloadByResourceSuccess, (state, { type, payloads }) => {
    console.log(type);
    return {
      ...state,
      isGettingPayloads: false,
      isGetPayloadsSuccess: true,
      payloads: payloads,
    };
  }),
  on(
    ACIActions.getPayloadByResourceFailure,
    (state, { type, getPayloadsErrorMessage }) => {
      console.log(type);
      return {
        ...state,
        isGettingPayloads: false,
        isGetPayloadsSuccess: false,
        getPayloadsErrorMessage: getPayloadsErrorMessage,
      };
    },
  ),

  on(ACIActions.countCompletedTasks, (state, { type }) => {
    console.log(type);
    return {
      ...state,
      countingTasks: state.countingTasks + 1,
    };
  }),
);
