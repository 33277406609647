import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Pipe({
  name: 'sourceToBlob',
})
export class SourceToBlobPipe implements PipeTransform {
  constructor(private httpClient: HttpClient) {}

  async transform(src: string): Promise<string> {
    try {
      const blob: Blob | undefined = await lastValueFrom(
        this.httpClient.get(src, { responseType: 'blob' }),
        { defaultValue: <Blob>{} }
      );
      if (blob && blob.type.startsWith('application')) {
        return URL.createObjectURL(blob);
      }
      return '';
    } catch (error) {
      // console.error(error);
      return '';
    }
  }
}
