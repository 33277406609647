import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, of, switchMap} from 'rxjs';
import * as UsageTimeActions from './usage-time.actions';
import {objectToCamel} from 'src/app/utils/objectTransform';
import {UsageTimeService} from "../../services/usage-time/usage-time.service";
import {UsageTime} from "../../models/usage_time.model";

@Injectable()
export class UsageTimeEffects {
  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsageTimeActions.create),
      switchMap((action) => {
        return this.usageTimeService
          .create(action.timeSlot)
          .pipe(
            map(() => {
              return UsageTimeActions.createSuccess();
            }),
            catchError((error) => {
              return of(
                UsageTimeActions.createFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });

  getByUserId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsageTimeActions.getByUserId),
      switchMap((action) => {
        return this.usageTimeService
          .getByUserId(action.userId, action.currentTime)
          .pipe(
            map((data) => {
              return UsageTimeActions.getByUserIdSuccess({
                usageTime: <UsageTime>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                UsageTimeActions.getByUserIdFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });


  constructor(
    private actions$: Actions,
    private usageTimeService: UsageTimeService
  ) {
  }
}
