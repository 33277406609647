import { Batch, Voucher, VoucherResponse } from 'src/app/models/voucher.model';
import { VoucherState } from './voucher.state';
import { createReducer, on } from '@ngrx/store';

import * as VoucherActions from './voucher.actions';

const initialState: VoucherState = {
  voucher: <Voucher>{},
  isChecking: false,
  checkErrorMessage: '',

  isRedeeming: false,
  isRedeemSuccess: false,
  redeemErrorMessage: '',

  voucherResponse: <VoucherResponse>{},
  isGettingByBatchId: false,
  getByBatchIdErrorMessage: '',

  batch: <Batch>{},
  isGenerating: false,
  isGenerateSuccess: false,
  generateErrorMessage: '',

  batchList: [],
  isGettingByTimeRange: false,
  getByTimeRangeErrorMessage: '',
};

export const voucherReducer = createReducer(
  initialState,
  on(VoucherActions.check, (state, { type }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isChecking: true,
      voucher: <Voucher>{},
      checkErrorMessage: '',
    };
  }),
  on(VoucherActions.checkSuccess, (state, { type, voucher }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isChecking: false,
      voucher: voucher,
    };
  }),
  on(VoucherActions.checkFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isChecking: false,
      checkErrorMessage: errorMessage,
    };
  }),

  on(VoucherActions.redeem, (state, { type }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isRedeeming: true,
      isRedeemSuccess: false,
      redeemErrorMessage: '',
    };
  }),
  on(VoucherActions.redeemSuccess, (state, { type }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isRedeeming: false,
      isRedeemSuccess: true,
    };
  }),
  on(VoucherActions.redeemFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isRedeeming: false,
      redeemErrorMessage: errorMessage,
    };
  }),

  on(VoucherActions.getByBatchId, (state, { type }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isGettingByBatchId: true,
      voucherResponse: <VoucherResponse>{},
    };
  }),
  on(VoucherActions.getByBatchIdSuccess, (state, { type, voucherResponse }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isGettingByBatchId: false,
      voucherResponse: voucherResponse,
    };
  }),
  on(VoucherActions.getByBatchIdFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isGettingByBatchId: false,
      getByBatchIdErrorMessage: errorMessage,
    };
  }),

  on(VoucherActions.generate, (state, { type }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isGenerating: true,
      isGenerateSuccess: false,
    };
  }),
  on(VoucherActions.generateSuccess, (state, { type }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isGenerating: false,
      isGenerateSuccess: true,
    };
  }),
  on(VoucherActions.generateFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isGenerating: false,
      isGenerateSuccess: false,
      generateErrorMessage: errorMessage,
    };
  }),

  on(VoucherActions.getBatchByTimeRange, (state, { type }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isGettingByTimeRange: true,
      batchList: [],
    };
  }),
  on(
    VoucherActions.getBatchByTimeRangeSuccess,
    (state, { type, batchList }) => {
      console.log(type);
      return <VoucherState>{
        ...state,
        isGettingByTimeRange: false,
        batchList: batchList,
      };
    },
  ),
  on(
    VoucherActions.getBatchByTimeRangeFailure,
    (state, { type, errorMessage }) => {
      console.log(type);
      return <VoucherState>{
        ...state,
        isGettingByTimeRange: false,
        getByTimeRangeErrorMessage: errorMessage,
      };
    },
  ),

  on(VoucherActions.clearStates, (state) => {
    return <VoucherState>{
      ...state,
      voucher: <Voucher>{},
      isChecking: false,
      checkErrorMessage: '',

      isRedeeming: false,
      isRedeemSuccess: false,
      redeemErrorMessage: '',

      voucherList: [],
      isGettingByBatchId: false,
      getByBatchIdErrorMessage: '',

      batch: <Batch>{},
      isGenerating: false,
      isGenerateSuccess: false,
      generateErrorMessage: '',

      batchList: [],
      isGettingByTimeRange: false,
      getByTimeRangeErrorMessage: '',
    };
  }),
);
