import { createAction, props } from '@ngrx/store';

export const clearStates = createAction('[Bulk] Clear States');

export const exportBulk = createAction(
  '[Bulk] Export',
  props<{ courseId: string }>(),
);
export const exportBulkSuccess = createAction(
  '[Bulk] Export Success',
  props<{ objectFile: any }>(),
);
export const exportBulkFailure = createAction(
  '[Bulk] Export Failure',
  props<{ error: string }>(),
);

export const importBulk = createAction(
  '[Bulk] Import',
  props<{ courseId: string; data: any }>(),
);

export const importBulkSuccess = createAction('[Bulk] Import Success');

export const importBulkFailure = createAction(
  '[Bulk] Import Failure',
  props<{ error: string }>(),
);
