import { createAction, props } from '@ngrx/store';
import { Feedback, FeedbackRepsonse } from '../../models/feedback.model';

export const feedbackCourse = createAction(
  '[Feedback] Feedback Course',
  props<{ feedback: Feedback }>()
);

export const feedbackCourseSuccess = createAction(
  '[Feedback] Feedback Course Success'
);

export const feedbackCourseFailure = createAction(
  '[Feedback] Feedback Course Failure',
  props<{ errorMessage: string }>()
);

export const listByCourseId = createAction(
  '[Feedback] List By Course Id',
  props<{ courseId: string; page: number; size: number }>()
);

export const listByCourseIdSuccess = createAction(
  '[Feedback] List By Course Id Success',
  props<{ feedbackResponse: FeedbackRepsonse }>()
);

export const listByCourseIdFailure = createAction(
  '[Feedback] List By Course Id Failure',
  props<{ errorMessage: string }>()
);

export const getByUserIdAndCourseId = createAction(
  '[Feedback] Get By User Id And Course Id',
  props<{ userId: string; courseId: string }>()
);

export const getByUserIdAndCourseIdSuccess = createAction(
  '[Feedback] Get By User Id And Course Id Success',
  props<{ feedback: Feedback }>()
);

export const getByUserIdAndCourseIdFailure = createAction(
  '[Feedback] Get By User Id And Course Id Failure',
  props<{ errorMessage: string }>()
);

export const del = createAction(
  '[Feedback] Delete',
  props<{ courseId: string; userId: string }>()
);

export const clearStates = createAction('[Feedback] Clear States');
