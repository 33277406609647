import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ACI} from 'src/app/models/aci.model';
import {HttpClientWithAuth} from "../../utils/HttpClientWithAuth";

@Injectable({
  providedIn: 'root',
})
export class ACIService {
  constructor(private http: HttpClientWithAuth) {
  }

  createNewACI(newACI: ACI) {
    return this.http.post('/aci', newACI);
  }

  updateACI(ACI: ACI) {
    return this.http.put('/aci', ACI);
  }

  deleteACI(id: string) {
    return this.http.delete(`/aci?id=${id}`);
  }

  getACIByRoleId(roleId: string) {
    return this.http.get(`/aci/role?role_id=${roleId}`);
  }

  getACIsWithPagination() {
    return this.http.get('/aci');
  }

  getACIByUserId(userId: string) {
    return this.http.get(`/aci/user?user_id=${userId}`);
  }

  checkACIPermission() {
    return this.http.get(`/aci/perm`);
  }

  getPayloadByResource(resource: string) {
    return this.http.get(`/aci/payload?resource=${resource}`);
  }
}
