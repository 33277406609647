import { Pipe, PipeTransform } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { Skill } from 'src/app/models/skill.model';
import { SkillService } from 'src/app/services/skill/skill.service';

@Pipe({
  name: 'idToName',
})
export class IdToNamePipe implements PipeTransform {
  constructor(private skillService: SkillService) {}

  transform(id: string): Observable<string> {
    return this.skillService.getSkillById(id).pipe(
      map((data: Skill) => {
        return data.name;
      }),
      catchError(() => {
        return 'default';
      })
    );
  }
}
