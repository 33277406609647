import { createReducer, on } from '@ngrx/store';
import * as SkillActions from './skill.actions';
import { SkillState } from './skill.state';
import {
  SkillResponse,
  Skill,
  SkillCategory,
  SkillCategoryResponse,
} from 'src/app/models/skill.model';

const initialState: SkillState = {
  skillResponse: <SkillResponse>{},
  isListingSkillReponseById: false,
  listSkillReponseByIdErrorMessage: '',

  skillsOfMentor: [],
  isGettingSkillById: false,
  getSkillByIdErrorMessage: '',

  isCreatingSkill: false,
  isCreateSkillSuccess: false,
  createSkillErrorMessage: '',

  isUpdatingSkill: false,
  isUpdateSkillSuccess: false,
  updateSkillErrorMessage: '',

  isDeletingSkill: false,
  isDeleteSkillSuccess: false,
  deleteSkillErrorMessage: '',

  //category
  isListingSkillResponseByCategoryId: false,
  skillByCategoryIdResponse: <SkillResponse>{},
  listSkillResponseByCategoryIdErrorMessage: '',

  skillCategoryResponse: <SkillCategoryResponse>{},
  isListingSkillCategoryResponse: false,
  listSkillCategoryReponseErrorMessage: '',

  skillCategroy: <SkillCategory>{},
  isGettingSkillCategoryById: false,
  getSkillCategoryByIdErrorMessage: '',

  isCreatingSkillCategory: false,
  isCreateSkillCategorySuccess: false,
  createSkillCategoryErrorMessage: '',

  isUpdatingSkillCategory: false,
  isUpdateSkillCategorySuccess: false,
  updateSkillCategoryErrorMessage: '',

  isDeletingSkillCategory: false,
  isDeleteSkillCategorySuccess: false,
  deleteSkillCategoryErrorMessage: '',
};

export const skillReducer = createReducer(
  initialState,

  //category
  on(SkillActions.getSkillCategoryById, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillCategoryById: true,
      getSkillCategoryByIdErrorMessage: '',
      skillCategroy: <SkillCategory>{},
    };
  }),
  on(SkillActions.getSkillCategoryByIdSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillCategoryById: false,
      skillCategroy: action.skillCategory,
    };
  }),
  on(SkillActions.getSkillCategoryByIdFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillCategoryById: false,
      getSkillCategoryByIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.listSkillCategory, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillCategoryResponse: true,
      listSkillCategoryReponseErrorMessage: '',
      skillCategoryResponse: <SkillCategoryResponse>{},
    };
  }),
  on(SkillActions.listSkillCategorySuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillCategoryResponse: false,
      skillCategoryResponse: action.skillCategoryResponse,
    };
  }),
  on(SkillActions.listSkillCategoryFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillCategoryResponse: false,
      listSkillCategoryReponseErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.createSkillCategory, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isCreatingSkillCategory: true,
      isCreateSkillCategorySuccess: false,
      createSkillCategoryErrorMessage: '',
    };
  }),
  on(SkillActions.createSkillCategorySuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isCreatingSkillCategory: false,
      isCreateSkillCategorySuccess: true,
    };
  }),
  on(SkillActions.createSkillCategoryFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isCreatingSkillCategory: false,
      createSkillCategoryErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.updateCategory, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isUpdatingSkillCategory: true,
      isUpdateSkillCategorySuccess: false,
      updateSkillCategoryErrorMessage: '',
    };
  }),
  on(SkillActions.updateCategorySuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isUpdatingSkillCategory: false,
      isUpdateSkillCategorySuccess: true,
    };
  }),
  on(SkillActions.updateCategoryFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isUpdatingSkillCategory: false,
      updateSkillCategoryErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.removeCategory, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isDeletingSkillCategory: true,
      isDeleteSkillCategorySuccess: false,
      deleteSkillCategoryErrorMessage: '',
    };
  }),
  on(SkillActions.removeCategorySuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isDeletingSkillCategory: false,
      isDeleteSkillCategorySuccess: true,
    };
  }),
  on(SkillActions.removeCategoryFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isDeletingSkillCategory: false,
      deleteSkillCategoryErrorMessage: action.errorMessage,
    };
  }),

  //skill
  on(SkillActions.getSkillById, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillById: true,
      getSkillByIdErrorMessage: '',
    };
  }),
  on(SkillActions.getSkillByIdSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillById: false,
      skillsOfMentor: [...state.skillsOfMentor, action.skill],
    };
  }),
  on(SkillActions.getSkillByIdFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillById: false,
      getSkillByIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.listSkillByCategoryId, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillResponseByCategoryId: true,
      listSkillResponseByCategoryIdErrorMessage: '',
      skillByCategoryIdResponse: <SkillResponse>{},
    };
  }),
  on(SkillActions.listSkillByCategoryIdSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillResponseByCategoryId: false,
      skillByCategoryIdResponse: action.skillResponse,
    };
  }),
  on(SkillActions.listSkillByCategoryIdFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillResponseByCategoryId: false,
      listSkillResponseByCategoryIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.listSkill, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillReponseById: true,
      listSkillReponseByIdErrorMessage: '',
      skillResponse: <SkillResponse>{},
    };
  }),
  on(SkillActions.listSkillSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillReponseById: false,
      skillResponse: action.skillResponse,
    };
  }),
  on(SkillActions.listSkillFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillReponseById: false,
      listSkillReponseByIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.createSkill, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isCreatingSkill: true,
      isCreateSkillSuccess: false,
      createSkillErrorMessage: '',
    };
  }),
  on(SkillActions.createSkillSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isCreatingSkill: false,
      isCreateSkillSuccess: true,
    };
  }),
  on(SkillActions.createSkillFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isCreatingSkill: false,
      createSkillErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.updateSkill, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isUpdatingSkill: true,
      isUpdateSkillSuccess: false,
      updateSkillErrorMessage: '',
    };
  }),
  on(SkillActions.updateSkillSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isUpdatingSkill: false,
      isUpdateSkillSuccess: true,
    };
  }),
  on(SkillActions.updateSkillFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isUpdatingSkill: false,
      updateSkillErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.removeSkill, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isDeletingSkill: true,
      isDeleteSkillSuccess: false,
      deleteSkillErrorMessage: '',
    };
  }),
  on(SkillActions.removeSkillSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isDeletingSkill: false,
      isDeleteSkillSuccess: true,
    };
  }),
  on(SkillActions.removeSkillFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isDeletingSkill: false,
      deleteSkillErrorMessage: action.errorMessage,
    };
  }),
  on(SkillActions.clearCreateCategoryActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isCreateSkillCategorySuccess: false,
      createSkillCategoryErrorMessage: '',
      isCreatingSkillCategory: false,
    };
  }),
  on(SkillActions.clearUpdateCategoryActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isUpdateSkillCategorySuccess: false,
      updateSkillCategoryErrorMessage: '',
      isUpdatingSkillCategory: false,
    };
  }),
  on(SkillActions.clearRemoveCategoryActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isDeleteSkillCategorySuccess: false,
      deleteSkillCategoryErrorMessage: '',
      isDeletingSkillCategory: false,
    };
  }),
  on(SkillActions.clearListSkillByCategoryIdActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillResponseByCategoryId: false,
      listSkillResponseByCategoryIdErrorMessage: '',
      skillByCategoryIdResponse: <SkillResponse>{},
    };
  }),

  //skill
  on(SkillActions.clearCreateSkillActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isCreateSkillSuccess: false,
      createSkillErrorMessage: '',
      isCreatingSkill: false,
    };
  }),
  on(SkillActions.clearUpdateSkillActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isUpdateSkillSuccess: false,
      updateSkillErrorMessage: '',
      isUpdatingSkill: false,
    };
  }),
  on(SkillActions.clearRemoveSkillActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isDeleteSkillSuccess: false,
      deleteSkillErrorMessage: '',
      isDeletingSkill: false,
    };
  }),
  on(SkillActions.clearListSkillByCategoryIdActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillResponseByCategoryId: false,
      listSkillResponseByCategoryIdErrorMessage: '',
      skillByCategoryIdResponse: <SkillResponse>{},
    };
  }),
  on(SkillActions.clearListSkillActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillReponseById: false,
      listSkillReponseByIdErrorMessage: '',
      skillResponse: <SkillResponse>{},
    };
  })
);
