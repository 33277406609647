import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CreditService} from 'src/app/services/credit/credit.service';

import * as CreditActions from './credit.actions';
import {catchError, map, of, switchMap} from 'rxjs';
import {objectToCamel} from 'src/app/utils/objectTransform';

@Injectable()
export class CreditEffects {
  constructor(
    private actions$: Actions,
    private creditService: CreditService
  ) {
  }

  getMine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreditActions.getMine),
      switchMap(() => {
        return this.creditService.getMine().pipe(
          map((data) => {
            return CreditActions.getMineSuccess({
              credit: <number>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(CreditActions.getMineFailure({errorMessage: error}));
          })
        );
      })
    );
  });
}
