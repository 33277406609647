import { createAction, props } from '@ngrx/store';
import { ACI } from 'src/app/models/aci.model';

export const create = createAction(
  '[ACI] Create ACI',
  props<{ newACI: ACI }>(),
);
export const createSuccess = createAction('[ACI] Create ACI Success');
export const createFailure = createAction(
  '[ACI] Create ACI Failure',
  props<{ createErrorMessage: string }>(),
);

export const update = createAction('[ACI] Update ACI', props<{ ACI: ACI }>());
export const updateSuccess = createAction('[ACI] Update ACI Success');
export const updateFailure = createAction(
  '[ACI] Update ACI Failure',
  props<{ updateErrorMessage: string }>(),
);

export const deleteACI = createAction(
  '[ACI] Delete ACI',
  props<{ id: string }>(),
);
export const deleteSuccess = createAction('[ACI] Delete ACI Success');
export const deleteFailure = createAction(
  '[ACI] Delete ACI Failure',
  props<{ deleteErrorMessage: string }>(),
);

export const getByRoleId = createAction(
  '[ACI] Get ACI By Role Id',
  props<{ roleId: string }>(),
);
export const getByRoleIdSuccess = createAction(
  '[ACI] Get ACI By Role Id Success',
  props<{ acl: ACI[] }>(),
);
export const getByRoleIdFailure = createAction(
  '[ACI] Get ACI By Role Id Failure',
  props<{ getByRoleIdErrorMessage: string }>(),
);

export const getByUserId = createAction(
  '[ACI] Get ACI By User Id',
  props<{ userId: string }>(),
);
export const getByUserIdSuccess = createAction(
  '[ACI] Get ACI By User Id Success',
  props<{ personalAcl: ACI[] }>(),
);
export const getByUserIdFailure = createAction(
  '[ACI] Get ACI By User Id Failure',
  props<{ getByUserIdErrorMessage: string }>(),
);

export const getPayloadByResource = createAction(
  '[ACI] Get Payload By Resource',
  props<{ resource: string }>(),
);
export const getPayloadByResourceSuccess = createAction(
  '[ACI] Get Payload By Resource Success',
  props<{ payloads: string[] }>(),
);
export const getPayloadByResourceFailure = createAction(
  '[ACI] Get Payload By Resource Failure',
  props<{ getPayloadsErrorMessage: string }>(),
);

export const clearACLStates = createAction('[ACI] Clear ACL States');
export const clearPersonalACLStates = createAction(
  '[ACI] Clear Personal ACL States',
);
export const clearPayloadStates = createAction('[ACI] Clear Payload States');
export const clearACIStates = createAction('[ACI] Clear ACI States');

export const clearAllMessage = createAction('[ACI] Clear All Message');

export const countCompletedTasks = createAction('[ACI] Count Completed Tasks');
