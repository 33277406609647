import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AttemptData } from '../../models/indexed_db.model';
import { Store } from '@ngrx/store';

import * as IndexedDbActions from '../../ngrx/indexed-db/indexed-db.actions';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService {
  //indexedDB
  //open connect
  DBOpenRequest = window.indexedDB.open('attempts', 1);
  db: IDBDatabase = {} as IDBDatabase;
  objectStore: IDBObjectStore = {} as IDBObjectStore;

  constructor(private store: Store) {
    // these two event handlers act on the database being opened successfully, or not
    this.DBOpenRequest.onerror = () => {
      console.error('Error loading database.');
    };
    this.DBOpenRequest.onsuccess = () => {
      // console.info("Database initialized.");
      // store the result of opening the database in the db variable. This is used a lot later on, for opening transactions and suchlike.
      this.db = this.DBOpenRequest.result;
      this.store.dispatch(IndexedDbActions.init());
    };
    this.DBOpenRequest.onupgradeneeded = (event) => {
      // Save the IDBDatabase interface
      this.db = (event.target as any).result;
      this.objectStore = this.db.createObjectStore('attempts', {
        keyPath: ['courseId', 'profileId'],
      });
      this.objectStore.createIndex('courseId', 'courseId', { unique: false });
      this.objectStore.createIndex('profileId', 'profileId', { unique: false });
      this.objectStore.createIndex('id', 'id', { unique: true });
      this.objectStore.createIndex('value', 'value', { unique: false });
      this.objectStore.createIndex('onQuestNum', 'onQuestNum', {
        unique: false,
      });
      this.objectStore.createIndex('answering', 'answering', { unique: false });
    };
  }

  getAttemptDataInIndexedDB(courseId: string, profileId: string) {
    return new Observable((observer) => {
      let objectStore = this.db.transaction('attempts').objectStore('attempts');
      let request = objectStore.get([courseId, profileId]);
      request.onerror = (event) => {
        // Handle errors!
        observer.error(event.toString());
      };
      request.onsuccess = () => {
        observer.next(request.result);
      };
    });
  }

  createAttemptDataInIndexedDB(data: AttemptData) {
    return new Observable((observer) => {
      let objectStore = this.db
        .transaction(['attempts'], 'readwrite')
        .objectStore('attempts');
      let request = objectStore.add(data);
      request.onerror = (event) => {
        // Handle errors!
        observer.error(event.toString());
      };
      request.onsuccess = () => {
        observer.next(request.result);
      };
    });
  }

  updateAttemptDataInIndexedDB(newData: AttemptData) {
    return new Observable((observer) => {
      let objectStore = this.db
        .transaction(['attempts'], 'readwrite')
        .objectStore('attempts');
      let request = objectStore.put(newData);
      request.onerror = (event) => {
        // Handle errors!
        console.error(event);
        observer.error(event.toString());
      };
      request.onsuccess = () => {
        observer.next(request.result);
      };
    });
  }
}
