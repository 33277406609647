import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as FeedbackActions from './feedback.actions';
import {catchError, map, of, switchMap} from 'rxjs';
import {camelToSnake, objectToCamel} from 'src/app/utils/objectTransform';
import {FeedbackService} from "../../services/feedback/feedback.service";

@Injectable()
export class FeedbackEffects {
  feedback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FeedbackActions.feedbackCourse),
      switchMap((action) => {
        return this.feedbackService.feedback(camelToSnake(action.feedback)).pipe(
          map(() => {
            return FeedbackActions.feedbackCourseSuccess();
          }),
          catchError((error) => {
            return of(
              FeedbackActions.feedbackCourseFailure({errorMessage: error})
            );
          })
        );
      })
    );
  });
  listByCourseId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FeedbackActions.listByCourseId),
      switchMap((action) => {
        return this.feedbackService.listByCourseId(action.courseId, action.page, action.size).pipe(
          map((data) => {
            return FeedbackActions.listByCourseIdSuccess({feedbackResponse: objectToCamel(data)});
          }),
          catchError((error) => {
            return of(
              FeedbackActions.listByCourseIdFailure({errorMessage: error})
            );
          })
        );
      })
    );
  });

  getByUserIdAndCourseId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FeedbackActions.getByUserIdAndCourseId),
      switchMap((action) => {
        return this.feedbackService.getByUserIdAndCourseId(action.userId, action.courseId).pipe(
          map((data) => {
            return FeedbackActions.getByUserIdAndCourseIdSuccess({feedback: objectToCamel(data)});
          }),
          catchError((error) => {
            return of(
              FeedbackActions.getByUserIdAndCourseIdFailure({errorMessage: error})
            );
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private feedbackService: FeedbackService
  ) {
  }
}
