import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Role} from 'src/app/models/role.model';
import {HttpClientWithAuth} from "../../utils/HttpClientWithAuth";

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private http: HttpClientWithAuth) {
  }

  createNewRole(newRole: Role) {
    return this.http.post('/role', newRole);
  }

  updateRole(role: Role) {
    return this.http.put('/role', role);
  }

  deleteRole(id: string) {
    return this.http.delete(`/role?id=${id}`);
  }

  getAllRoles() {
    return this.http.get('/role');
  }
}
