import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of, switchMap } from 'rxjs';
import { PipesService } from '../../services/pipes/pipes.service';

import * as PipeActions from './pipe.actions';

@Injectable()
export class PipeEffects {
  constructor(
    private actions$: Actions,
    private pipesService: PipesService,
  ) {}

  transformContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PipeActions.transformContent),
      concatMap((action) => {
        return this.pipesService.transform(action.contentJson).pipe(
          map((data) => {
            return PipeActions.transformContentSuccess({
              embedContentByContentJson: data,
            });
          }),
          catchError((error) => {
            console.log('transformContentErrorMessage', error);
            return of(
              PipeActions.transformContentErrorMessage({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });
}
