import { Injectable } from '@angular/core';
import { Batch, Voucher } from 'src/app/models/voucher.model';
import { HttpClientWithAuth } from '../../utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  constructor(private http: HttpClientWithAuth) {}

  check(voucher: Voucher) {
    return this.http.post('/voucher/check', voucher);
  }

  redeem(voucher: Voucher) {
    return this.http.post('/voucher/redeem', voucher);
  }

  generate(batch: Batch) {
    return this.http.post('/voucher/generate', batch);
  }

  getBatchByTimeRange(from: number, to: number) {
    return this.http.get(`/voucher/batch/time?from=${from}&to=${to}`);
  }

  getListByBatchId(batchId: string, page: number, size: number) {
    return this.http.get(
      `/voucher?batch_id=${batchId}&page=${page}&size=${size}`,
    );
  }
}
