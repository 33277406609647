<div #sidebar class="side-container">
  <div class="up">
    <div *ngIf="isExpanded" class="close-btn">
      <span class="material-symbols-rounded" (click)="expand()">
        keyboard_double_arrow_left
      </span>
    </div>
    <div *ngIf="!isExpanded" class="close-btn-expanded">
      <span class="material-symbols-rounded" (click)="expand()">
        keyboard_double_arrow_right
      </span>
    </div>
    <div *ngIf="isExpanded" class="logo">
      <img
        class="logo-neg"
        src="../../../../assets/images/Mikademy-Logo.svg"
        alt=""
        (click)="handleLogoOnClick()"
      />
    </div>
    <div *ngIf="!isExpanded" class="logo-icon">
      <img
        class="logo-icon-neg"
        src="../../../../assets/images/Mikademy-Logo-Icon-Neg.svg"
        alt=""
        (click)="handleLogoOnClick()"
      />
    </div>

    <div class="item-up">
      <tui-scrollbar>
        <div class="list-items">
          <div
            (click)="selected(0, i)"
            [class]="
              itemSelected == i && sectionSelected == 0
                ? 'item item-active'
                : isExpanded
                  ? 'item'
                  : 'item-expanded'
            "
            class="item"
            *ngFor="let item of userPages; let i = index"
          >
            <span [class]="!isExpanded ? 'cover-expanded' : 'cover'">
              <span class="material-symbols-rounded">
                {{ item.icon }}
              </span>
              <span [class]="isExpanded ? 'tui-text_body-l title' : 'hide'">{{
                item.title
              }}</span>
            </span>
          </div>
        </div>

        <div class="list-items" *ngIf="isCreator || isStaticUser">
          <div
            (click)="selected(1, i)"
            [class]="
              itemSelected == i && sectionSelected == 1
                ? 'item item-active'
                : isExpanded
                  ? 'item'
                  : 'item-expanded'
            "
            class="item"
            *ngFor="let item of studioPages; let i = index"
          >
            <span [class]="!isExpanded ? 'cover-expanded' : 'cover'">
              <span class="material-symbols-rounded"> {{ item.icon }} </span>
              <span [class]="isExpanded ? 'tui-text_body-l title' : 'hide'">{{
                item.title
              }}</span>
            </span>
          </div>
        </div>

        <div class="list-items" *ngIf="isMentor || isStaticUser">
          <div
            (click)="selected(2, i)"
            [class]="
              itemSelected == i && sectionSelected == 2
                ? 'item item-active'
                : isExpanded
                  ? 'item'
                  : 'item-expanded'
            "
            class="item"
            *ngFor="let item of mentorPages; let i = index"
          >
            <span [class]="!isExpanded ? 'cover-expanded' : 'cover'">
              <span class="material-symbols-rounded"> {{ item.icon }} </span>
              <span [class]="isExpanded ? 'tui-text_body-l title' : 'hide'">{{
                item.title
              }}</span>
            </span>
          </div>
        </div>

        <div class="list-items" *ngIf="isAdmin || isStaticUser">
          <div
            (click)="selected(3, i)"
            [class]="
              itemSelected == i && sectionSelected == 3
                ? 'item item-active'
                : isExpanded
                  ? 'item'
                  : 'item-expanded'
            "
            class="item"
            *ngFor="let item of adminPages; let i = index"
          >
            <span [class]="!isExpanded ? 'cover-expanded' : 'cover'">
              <span class="material-symbols-rounded"> {{ item.icon }} </span>
              <span [class]="isExpanded ? 'tui-text_body-l title' : 'hide'">{{
                item.title
              }}</span>
            </span>
          </div>
        </div>
      </tui-scrollbar>
    </div>
  </div>
  <div class="down">
    <div class="item setting">
      <span
        [class]="!isExpanded ? 'cover-expanded' : 'cover'"
        (click)="feedback()"
      >
        <span class="material-symbols-outlined"> help </span>
        <span [class]="isExpanded ? 'tui-text_body-l title' : 'hide'"
          >Feedback</span
        >
      </span>
    </div>
    <div
      class="item setting"
      (click)="signOut()"
      *ngIf="!hidden && !isStaticUser"
    >
      <span [class]="!isExpanded ? 'cover-expanded' : 'cover'">
        <span class="material-symbols-rounded"> logout </span>
        <span [class]="isExpanded ? 'tui-text_body-l title' : 'hide'"
          >Logout</span
        >
      </span>
    </div>
    <div
      class="item setting"
      (click)="signOutStatic()"
      *ngIf="!hidden && isStaticUser"
    >
      <span [class]="!isExpanded ? 'cover-expanded' : 'cover'">
        <span class="material-symbols-rounded"> logout </span>
        <span [class]="isExpanded ? 'tui-text_body-l title' : 'hide'"
          >Logout</span
        >
      </span>
    </div>
  </div>
</div>
