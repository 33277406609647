import { createAction, props } from '@ngrx/store';
import {
  Skill,
  SkillCategory,
  SkillResponse,
} from 'src/app/models/skill.model';

//category
export const getSkillCategoryById = createAction(
  '[Skill Category] Get by Id',
  props<{ id: string }>()
);

export const getSkillCategoryByIdSuccess = createAction(
  '[Skill Category] Get by Id Success',
  props<{ skillCategory: SkillCategory }>()
);

export const getSkillCategoryByIdFailure = createAction(
  '[Skill Category] Get by Id Failure',
  props<{ errorMessage: string }>()
);

export const listSkillCategory = createAction(
  '[Skill Category] List',
  props<{ size: number; page: number }>()
);

export const listSkillCategorySuccess = createAction(
  '[Skill Category] List Success',
  props<{ skillCategoryResponse: SkillResponse }>()
);

export const listSkillCategoryFailure = createAction(
  '[Skill Category] List Failure',
  props<{ errorMessage: string }>()
);

export const createSkillCategory = createAction(
  '[Skill Category] Create',
  props<{ skillCategory: SkillCategory }>()
);

export const createSkillCategorySuccess = createAction(
  '[Skill Category] Create Success'
);

export const createSkillCategoryFailure = createAction(
  '[Skill Category] Create Failure',
  props<{ errorMessage: string }>()
);

export const updateCategory = createAction(
  '[Skill Category] Update',
  props<{ skillCategory: SkillCategory }>()
);

export const updateCategorySuccess = createAction(
  '[Skill Category] Update Success'
);

export const updateCategoryFailure = createAction(
  '[Skill Category] Update Failure',
  props<{ errorMessage: string }>()
);

export const removeCategory = createAction(
  '[Skill Category] Remove',
  props<{ id: string }>()
);

export const removeCategorySuccess = createAction(
  '[Skill Category] Remove Success',
  props<{ id: string }>()
);

export const removeCategoryFailure = createAction(
  '[Skill Category] Remove Failure',
  props<{ errorMessage: string }>()
);

//skill
export const getSkillById = createAction(
  '[Skill] Get by Id',
  props<{ id: string }>()
);

export const getSkillByIdSuccess = createAction(
  '[Skill] Get by Id Success',
  props<{ skill: Skill }>()
);

export const getSkillByIdFailure = createAction(
  '[Skill] Get by Id Failure',
  props<{ errorMessage: string }>()
);

export const listSkill = createAction(
  '[Skill] List',
  props<{ size: number; page: number }>()
);

export const listSkillSuccess = createAction(
  '[Skill] List Success',
  props<{ skillResponse: SkillResponse }>()
);

export const listSkillFailure = createAction(
  '[Skill] List Failure',
  props<{ errorMessage: string }>()
);

export const createSkill = createAction(
  '[Skill] Create',
  props<{ skill: Skill }>()
);

export const createSkillSuccess = createAction('[Skill] Create Success');

export const createSkillFailure = createAction(
  '[Skill] Create Failure',
  props<{ errorMessage: string }>()
);

export const updateSkill = createAction(
  '[Skill] Update',
  props<{ skill: Skill }>()
);

export const updateSkillSuccess = createAction('[Skill] Update Success');

export const updateSkillFailure = createAction(
  '[Skill] Update Failure',
  props<{ errorMessage: string }>()
);

export const removeSkill = createAction(
  '[Skill] Remove',
  props<{ id: string }>()
);

export const removeSkillSuccess = createAction(
  '[Skill] Remove Success',
  props<{ id: string }>()
);

export const removeSkillFailure = createAction(
  '[Skill] Remove Failure',
  props<{ errorMessage: string }>()
);

export const listSkillByCategoryId = createAction(
  '[Skill] List by Category Id',
  props<{ categoryId: string; page: number; size: number }>()
);

export const listSkillByCategoryIdSuccess = createAction(
  '[Skill] List by Category Id Success',
  props<{ skillResponse: SkillResponse }>()
);

export const listSkillByCategoryIdFailure = createAction(
  '[Skill] List by Category Id Failure',
  props<{ errorMessage: string }>()
);

export const clearCreateSkillActionState = createAction(
  '[Skill] Clear Create Action State'
);

export const clearUpdateSkillActionState = createAction(
  '[Skill] Clear Update Action State'
);

export const clearRemoveSkillActionState = createAction(
  '[Skill] Clear Remove Action State'
);

export const clearListSkillByCategoryIdActionState = createAction(
  '[Skill] Clear List by Category Id Action State'
);

export const clearListSkillActionState = createAction(
  '[Skill] Clear List Action State'
);

export const clearCreateCategoryActionState = createAction(
  '[Skill Category] Clear Create Action State'
);

export const clearUpdateCategoryActionState = createAction(
  '[Skill Category] Clear Update Action State'
);

export const clearRemoveCategoryActionState = createAction(
  '[Skill Category] Clear Remove Action State'
);

export const clearListSkillCategoryActionState = createAction(
  '[Skill Category] Clear List Action State'
);
