import {Pipe, PipeTransform} from '@angular/core';
import {ProfileService} from "../../services/profile/profile.service";
import {map, Observable} from "rxjs";
import {Profile} from "../../models/profile.model";

@Pipe({
  name: 'idToEmail'
})
export class IdToEmailPipe implements PipeTransform {

  constructor(private profileService: ProfileService) {
  }

  transform(id: string): Observable<string> {
    return this.profileService.getById(id).pipe(map((data: Profile) => {
      return data.email;
    }));
  }

}
