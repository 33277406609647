import { createReducer, on } from '@ngrx/store';
import { LearningProgressState } from './learning-progress.state';
import * as LearningProgressActions from './learning-progress.actions';

const initialState: LearningProgressState = {
  courseLearningProgress: [],
  isGettingCourseLearningProgress: false,
  getCourseLearningProgressErrorMessage: '',

  count: 0,
  isCounting: false,
  countErrorMessage: '',

  isGettingByUserId: false,
  getByUserIdErrorMessage: '',
  learningProgresses: [],
};

export const learningProgressReducer = createReducer(
  initialState,
  on(LearningProgressActions.clearStates, (state, action) => {
    console.log(action.type);
    return <LearningProgressState>{
      ...state,
      courseLearningProgress: [],
      isGettingCourseLearningProgress: false,
      getCourseLearningProgressErrorMessage: '',

      count: 0,
      isCounting: false,
      countErrorMessage: '',

      isGettingByUserId: false,
      getByUserIdErrorMessage: '',
      learningProgresses: [],
    };
  }),
  on(LearningProgressActions.getByUserIdAndMicrocourseId, (state, action) => {
    console.log(action.type);
    return <LearningProgressState>{
      ...state,
      isGettingCourseLearningProgress: true,
      getCourseLearningProgressErrorMessage: '',
      courseLearningProgress: [],
    };
  }),
  on(
    LearningProgressActions.getByUserIdAndMicrocourseIdSuccess,
    (state, action) => {
      console.log(action.type);
      return <LearningProgressState>{
        ...state,
        courseLearningProgress: action.learningProgresses,
        isGettingCourseLearningProgress: false,
        getCourseLearningProgressErrorMessage: '',
      };
    }
  ),
  on(
    LearningProgressActions.getByUserIdAndMicrocourseIdFail,
    (state, action) => {
      console.log(action.type);
      return <LearningProgressState>{
        ...state,
        isGettingCourseLearningProgress: false,
        getCourseLearningProgressErrorMessage: action.errorMessage,
      };
    }
  ),
  on(LearningProgressActions.count, (state, action) => {
    console.log(action.type);
    return <LearningProgressState>{
      ...state,
      isCounting: true,
      countErrorMessage: '',
    };
  }),
  on(LearningProgressActions.countSuccess, (state, action) => {
    console.log(action.type);
    return <LearningProgressState>{
      ...state,
      count: action.count,
      isCounting: false,
      countErrorMessage: '',
    };
  }),
  on(LearningProgressActions.countFail, (state, action) => {
    console.log(action.type);
    return <LearningProgressState>{
      ...state,
      isCounting: false,
      countErrorMessage: action.errorMessage,
    };
  }),
  on(LearningProgressActions.getByUserId, (state, action) => {
    console.log(action.type);
    return <LearningProgressState>{
      ...state,
      learningProgresses: [],
      isGettingByUserId: true,
      getByUserIdErrorMessage: '',
    };
  }),
  on(LearningProgressActions.getByUserIdSuccess, (state, action) => {
    console.log(action.type);
    return <LearningProgressState>{
      ...state,
      learningProgresses: action.learningProgresses,
      isGettingByUserId: false,
      getByUserIdErrorMessage: '',
    };
  }),
  on(LearningProgressActions.getByUserIdFail, (state, action) => {
    console.log(action.type);
    return <LearningProgressState>{
      ...state,
      isGettingByUserId: false,
      getByUserIdErrorMessage: action.errorMessage,
    };
  }),
  on(
    LearningProgressActions.clearGetLearningProgressesByUserIdState,
    (state, action) => {
      console.log(action.type);
      return <LearningProgressState>{
        ...state,
        learningProgresses: [],
        isGettingByUserId: false,
        getByUserIdErrorMessage: '',
      };
    }
  )
);
