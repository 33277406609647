<div class="aci-form">
  <div class="tui-form__row tui-form__row_multi-fields">
    <div class="tui-form__multi-field">
      <tui-input
        (focusedChange)="focusedChange($event, 'resource')"
        [(ngModel)]="aciInfo.resource"
        [readOnly]="isReadonly"
        [disabled]="isRemove"
      >
        Resource
        <input
          [ngStyle]="{
            'text-decoration-line': isRemove ? 'line-through' : '',
            filter: isRemove ? 'blur(0.5px)' : '',
            'background-color': isRemove ? '#f2f2f2' : ''
          }"
          [readOnly]="isRemove"
          tuiTextfield
          type="text"
        />
      </tui-input>
      <tui-error
        [(ngModel)]="aciInfo.resource"
        [error]="[] | tuiFieldError | async"
      ></tui-error>
    </div>

    <div class="tui-form__multi-field">
      <tui-input
        [(ngModel)]="aciInfo.payload"
        (focusedChange)="focusedChange($event, 'payload')"
        [readOnly]="isReadonly"
        [disabled]="isRemove"
      >
        Payload
        <input
          [ngStyle]="{
            'text-decoration-line': isRemove ? 'line-through' : '',
            filter: isRemove ? 'blur(0.8px)' : '',
            'background-color': isRemove ? '#f2f2f2' : ''
          }"
          [readOnly]="isRemove"
          tuiTextfield
          type="text"
        />
      </tui-input>
      <tui-error
        [(ngModel)]="aciInfo.payload"
        [error]="[] | tuiFieldError | async"
      ></tui-error>
    </div>
    <div id="btn-box">
      <!--      <button-->
      <!--        (click)="remove()"-->
      <!--        *ngIf="isPersonal == false && isRole == true"-->
      <!--        [icon]="icon"-->
      <!--        [ngStyle]="{-->
      <!--          display: isRemove ? 'none' : ''-->
      <!--        }"-->
      <!--        appearance="none"-->
      <!--        class="remove-but"-->
      <!--        size="s"-->
      <!--        title="remove"-->
      <!--        tuiIconButton-->
      <!--        type="button"-->
      <!--      ></button>-->
      <button
        (click)="remove()"
        [icon]="icon"
        *ngIf="!isReadonly"
        appearance="flat"
        class="remove-but"
        size="s"
        title="remove"
        tuiIconButton
        type="button"
      ></button>
    </div>
  </div>

  <!-- <tui-notification *ngIf="isRemove" status="neutral">
    This aci was removed
  </tui-notification> -->
</div>

<!-- icon -->
<ng-template #icon>
  <tui-svg src="tuiIconMinus"></tui-svg>
</ng-template>

<ng-template #iconReload>
  <tui-svg src="tuiIconRotateCcw"></tui-svg>
</ng-template>
