import { createAction, props } from '@ngrx/store';
import { EnrollmentResponse } from 'src/app/models/enrollment.model';

export const clearStates = createAction('[Enrollment] Clear States');

export const clearMessages = createAction('[Enrollment] Clear Messages');

export const enroll = createAction(
  '[Enrollment] Enroll',
  props<{ microcourseId: string }>()
);

export const enrollSuccess = createAction('[Enrollment] Enroll Success');

export const enrollFailure = createAction(
  '[Enrollment] Enroll Failure',
  props<{ errorMessage: string }>()
);

export const listByProfileId = createAction(
  '[Enrollment] List By Profile Id',
  props<{
    id: string;
    page: number;
    size: number;
  }>()
);

export const listByProfileIdSuccess = createAction(
  '[Enrollment] List By Profile Id Success',
  props<{
    enrollments: EnrollmentResponse;
  }>()
);

export const listByProfileIdFailure = createAction(
  '[Enrollment] List By Profile Id Failure',
  props<{
    errorMessage: string;
  }>()
);

export const listByMicrocourseId = createAction(
  '[Enrollment] List By Microcourse Id',
  props<{
    id: string;
    page: number;
    size: number;
  }>()
);

export const listByMicrocourseIdSuccess = createAction(
  '[Enrollment] List By Microcourse Id Success',
  props<{
    enrollments: EnrollmentResponse;
  }>()
);

export const listByMicrocourseIdFailure = createAction(
  '[Enrollment] List By Microcourse Id Failure',
  props<{
    errorMessage: string;
  }>()
);

export const countByMicrocourseId = createAction(
  '[Enrollment] Count By Microcourse Id',
  props<{ id: string }>()
);

export const countByMicrocourseIdSuccess = createAction(
  '[Enrollment] Count By Microcourse Id Success',
  props<{
    count: number;
  }>()
);

export const countByMicrocourseIdFailure = createAction(
  '[Enrollment] Count By Microcourse Id Failure',
  props<{
    errorMessage: string;
  }>()
);

export const countByProfileId = createAction(
  '[Enrollment] Count By Profile Id',
  props<{ id: string }>()
);

export const countByProfileIdSuccess = createAction(
  '[Enrollment] Count By Profile Id Success',
  props<{
    count: number;
  }>()
);

export const countByProfileIdFailure = createAction(
  '[Enrollment] Count By Profile Id Failure',
  props<{
    errorMessage: string;
  }>()
);

export const deleteById = createAction(
  '[Enrollment] Delete By Id',
  props<{ id: string }>()
);

export const deleteByIdSuccess = createAction(
  '[Enrollment] Delete By Id Success'
);

export const deleteByIdFailure = createAction(
  '[Enrollment] Delete By Id Failure',
  props<{ errorMessage: string }>()
);
