import {createAction, props} from '@ngrx/store';
import {TimeSlot, UsageTime} from "../../models/usage_time.model";

export const create = createAction(
  '[Usage Time] Create',
  props<{ timeSlot: TimeSlot }>()
);

export const createSuccess = createAction(
  '[Usage Time] Create Success',
);

export const createFailure = createAction(
  '[Usage Time] Create Failure',
  props<{ errorMessage: string }>()
);


export const getByUserId = createAction(
  '[Usage Time] Get By User Id',
  props<{ userId: string, currentTime: number }>()
);

export const getByUserIdSuccess = createAction(
  '[Usage Time] Get By User Id Success',
  props<{ usageTime: UsageTime }>()
);

export const getByUserIdFailure = createAction(
  '[Usage Time] Get By User Id Failure',
  props<{ errorMessage: string }>()
);

export const resetStates = createAction(
  '[Usage Time] Reset States',
);
