import { createReducer, on } from '@ngrx/store';
import * as PaymentActions from './payment.actions';
import { PaymentOSResponse } from 'src/app/models/payment.model';
import { PaymentState } from './payment.state';

const initialState: PaymentState = {
  paymentOSResponse: <PaymentOSResponse>{},
  isCreating: false,
  createErrorMessage: '',
};

export const paymentReducer = createReducer(
  initialState,
  on(PaymentActions.createWithPayOS, (state) => ({
    ...state,
    isCreating: true,
    createErrorMessage: '',
  })),
  on(PaymentActions.createWithPayOSSuccess, (state, { paymentOSResponse }) => ({
    ...state,
    paymentOSResponse,
    isCreating: false,
  })),
  on(PaymentActions.createWithPayOSFailure, (state, { errorMessage }) => ({
    ...state,
    isCreating: false,
    createErrorMessage: errorMessage,
  })),

  on(PaymentActions.clearPaymentState, (state) => ({
    ...state,
    paymentOSResponse: <PaymentOSResponse>{},
    isCreating: false,
    createErrorMessage: '',
  })),
);
