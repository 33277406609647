import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import {
  TUI_SANITIZER,
  TuiAlertModule,
  TuiDialogModule,
  TuiRootModule,
} from '@taiga-ui/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
  isDevMode,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';

import { authReducer } from './ngrx/auth/auth.reducer';
import { AuthEffects } from './ngrx/auth/auth.effects';
import { profileReducer } from './ngrx/profile/profile.reducer';
import { ProfileEffects } from './ngrx/profile/profile.effects';
import { microcourseReducer } from './ngrx/microcourse/microcourse.reducer';
import { MicrocourseEffects } from './ngrx/microcourse/microcourse.effects';
import { roleReducer } from './ngrx/role/role.reducer';
import { ACIReducer } from './ngrx/aci/aci.reducer';
import { ACIEffects } from './ngrx/aci/aci.effects';
import { RoleEffects } from './ngrx/role/role.effects';
import { quizReducer } from './ngrx/quiz/quiz.reducer';
import { QuizEffects } from './ngrx/quiz/quiz.effects';
import { assignmentReducer } from './ngrx/assignment/assignment.reducer';
import { AssignmentEffects } from './ngrx/assignment/assignment.effects';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { mentorProfileReducer } from './ngrx/mentor-profile/mentor-profile.reducer';
import { MentorProfileEffects } from './ngrx/mentor-profile/mentor-profile.effects';
import { skillReducer } from './ngrx/skill/skill.reducer';
import { SkillEffects } from './ngrx/skill/skill.effects';
import { storageReducer } from './ngrx/storage/storage.reducer';
import { StorageEffects } from './ngrx/storage/storage.effects';
import { enrollmentReducer } from './ngrx/enrollment/enrollment.reducer';
import { EnrollmentEffects } from './ngrx/enrollment/enrollment.effects';
import { voucherReducer } from './ngrx/voucher/voucher.reducer';
import { VoucherEffects } from './ngrx/voucher/voucher.effects';
import { creditReducer } from './ngrx/credit/credit.reducer';
import { CreditEffects } from './ngrx/credit/credit.effects';
import { LearningProgressEffects } from './ngrx/learning-progress/learning-progress.effects';
import { learningProgressReducer } from './ngrx/learning-progress/learning-progress.reducer';
import { trackingItemReducer } from './ngrx/tracking-item/tracking-item.reducer';
import { TrackingItemEffects } from './ngrx/tracking-item/tracking-item.effects';
import { feedbackReducer } from './ngrx/feedback/feedback.reducer';
import { FeedbackEffects } from './ngrx/feedback/feedback.effects';
import { HttpClientWithAuth } from './utils/HttpClientWithAuth';
import { usageTimeReducer } from './ngrx/usage-time/usage-time.reducer';
import { UsageTimeEffects } from './ngrx/usage-time/usage-time.effects';
import { indexedDbReducer } from './ngrx/indexed-db/indexed-db.reducer';
import { IndexedDbEffects } from './ngrx/indexed-db/indexed-db.effects';
import { bulkReducer } from './ngrx/bulk/bulk.reducer';
import { BulkEffects } from './ngrx/bulk/bulk.effects';
import { drawerReducer } from './ngrx/drawer/drawer.reducer';
import { skillsetReducer } from './ngrx/skillset/skillset.reducer';
import { SkillsetEffects } from './ngrx/skillset/skillset.effects';
import { paymentReducer } from './ngrx/payment/payment.reducer';
import { PaymentEffects } from './ngrx/payment/payment.effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { pipeReducer } from './ngrx/pipe/pipe.reducer';
import { PipeEffects } from './ngrx/pipe/pipe.effects';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TuiRootModule,
    TuiDialogModule,
    TuiAlertModule,
    SharedModule,
    StoreModule.forRoot(
      {
        auth: authReducer,
        profile: profileReducer,
        microcourse: microcourseReducer,
        role: roleReducer,
        aci: ACIReducer,
        quiz: quizReducer,
        assignment: assignmentReducer,
        mentorProfile: mentorProfileReducer,
        skill: skillReducer,
        storage: storageReducer,
        enrollment: enrollmentReducer,
        voucher: voucherReducer,
        credit: creditReducer,
        learningProgress: learningProgressReducer,
        trackingItem: trackingItemReducer,
        feedback: feedbackReducer,
        usageTime: usageTimeReducer,
        indexedDb: indexedDbReducer,
        bulk: bulkReducer,
        drawer: drawerReducer,
        skillset: skillsetReducer,
        payment: paymentReducer,
        pipe: pipeReducer,
      },
      {},
    ),
    EffectsModule.forRoot([
      AuthEffects,
      ProfileEffects,
      MicrocourseEffects,
      RoleEffects,
      ACIEffects,
      QuizEffects,
      AssignmentEffects,
      MentorProfileEffects,
      SkillEffects,
      StorageEffects,
      EnrollmentEffects,
      VoucherEffects,
      CreditEffects,
      LearningProgressEffects,
      TrackingItemEffects,
      FeedbackEffects,
      UsageTimeEffects,
      IndexedDbEffects,
      BulkEffects,
      SkillsetEffects,
      PaymentEffects,
      PipeEffects,
    ]),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth(getApp())),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
    HttpClientWithAuth,
    CookieService,
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  exports: [],
})
export class AppModule {
  //   test
}
