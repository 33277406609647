import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Microcourse } from 'src/app/models/microcourse.model';
import * as DrawerActions from '../../../../../ngrx/drawer/drawer.actions';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {
  @Input('course') course!: Microcourse;
  @Input('viewMode') viewMode = 'prod';

  disable = false;

  @Input('disable')
  set _disable(disable: boolean) {
    this.disable = disable;
    if (this.disable) {
      this.cardStyle.push('disable');
    } else {
      this.cardStyle = this.cardStyle.filter((style) => style !== 'disable');
    }
  }

  cardStyle = ['course-card'];

  currentPage: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes('page')) {
      if (this.router.url.includes('recently-list')) {
        const url = this.router.url.split('recently-list')[1];
        const params = new URLSearchParams(url);
        this.currentPage = Number(params.get('page'));
      } else if (this.router.url.includes('hot-list')) {
        const url = this.router.url.split('hot-list')[1];
        const params = new URLSearchParams(url);
        this.currentPage = Number(params.get('page'));
      }
    }
    if (this.viewMode == 'preview') {
      this.cardStyle.push('preview-mode');
    } else {
      this.cardStyle = this.cardStyle.filter(
        (style) => style !== 'preview-mode',
      );
    }
  }

  openDrawer(): void {
    if (this.viewMode == 'dev') {
      return;
    }
    if (this.viewMode == 'preview') {
      if (!this.router.url.includes('preview')) {
        this.router
          .navigate(['/explore/microcourses/list'], {
            queryParams: { c_id: this.course.id },
          })
          .then(() => {
            this.store.dispatch(DrawerActions.open({ course: this.course }));
          });
      }
    }
    if (this.viewMode == 'prod') {
      if (this.course) {
        if (this.currentPage > 0) {
          console.log('hehe');
          this.router
            .navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {
                page: this.currentPage,
                c_id: this.course.id,
              },
            })
            .then(() => {
              this.store.dispatch(DrawerActions.open({ course: this.course }));
            });
        } else {
          this.router
            .navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {
                c_id: this.course.id,
              },
            })
            .then(() => {
              this.store.dispatch(DrawerActions.open({ course: this.course }));
            });
        }
      }
    }
  }
}
