import {createReducer, on} from '@ngrx/store';
import {RoleState} from './role.state';
import {RoleActions} from './role.actions';

const initialState: RoleState = {
  roleList: [],
  isLoadingAll: false,
  loadAllErrorMessage: '',

  isCreating: false,
  isCreateSuccess: false,
  createErrorMessage: '',

  isUpdating: false,
  isUpdateSuccess: false,
  updateErrorMessage: '',

  isDeleting: false,
  isDeleteSuccess: false,
  deleteErrorMessage: '',
};

export const roleReducer = createReducer(
  initialState,
  on(RoleActions.clearStates, (state, action) => {
    console.log(action.type);
    return <RoleState>{
      ...state,
      roleList: [],
      isLoadingAll: false,
      loadAllErrorMessage: '',

      isCreating: false,
      isCreateSuccess: false,
      createErrorMessage: '',

      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: '',

      isDeleting: false,
      isDeleteSuccess: false,
      deleteErrorMessage: '',
    }
  }),

  on(RoleActions.createRole, (state, action) => {
    console.log(action.type);
    return <RoleState>{
      ...state,
      isCreating: true,
      isCreateSuccess: false,
      createErrorMessage: '',
    };
  }),
  on(RoleActions.createRoleSuccess, (state, action) => {
    console.log(action.type);
    return <RoleState>{
      ...state,
      isCreating: false,
      isCreateSuccess: true,
    };
  }),
  on(RoleActions.createRoleFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <RoleState>{
      ...state,
      isCreating: false,
      createErrorMessage: errorMessage,
    };
  }),

  on(RoleActions.updateRole, (state, action) => {
    console.log(action.type);
    return <RoleState>{
      ...state,
      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: '',
    };
  }),
  on(RoleActions.updateRoleSuccess, (state, action) => {
    console.log(action.type);
    return <RoleState>{
      ...state,
      isUpdating: false,
      isUpdateSuccess: true,
    };
  }),
  on(RoleActions.updateRoleFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <RoleState>{
      ...state,
      isUpdating: false,
      updateErrorMessage: errorMessage,
    };
  }),

  on(RoleActions.deleteRole, (state, action) => {
    console.log(action.type);
    return <RoleState>{
      ...state,
      isDeleting: true,
      isDeleteSuccess: false,
      deleteErrorMessage: '',
    };
  }),
  on(RoleActions.deleteRoleSuccess, (state, action) => {
    console.log(action.type);
    return <RoleState>{
      ...state,
      isDeleting: false,
      isDeleteSuccess: true,
    };
  }),
  on(RoleActions.deleteRoleFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <RoleState>{
      ...state,
      isDeleting: false,
      deleteErrorMessage: errorMessage,
    };
  }),

  on(RoleActions.getAllRoles, (state, action) => {
    console.log(action.type);
    return <RoleState>{
      ...state,
      isLoadingAll: true,
      loadAllErrorMessage: '',
      roleList: [],
    };
  }),
  on(RoleActions.getAllRolesSuccess, (state, action) => {
    console.log(action.type);
    return <RoleState>{
      ...state,
      isLoadingAll: false,
      roleList: action.roles,
    };
  }),
  on(RoleActions.getAllRolesFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <RoleState>{
      ...state,
      isLoadingAll: false,
      loadAllErrorMessage: errorMessage,
    };
  })
);
