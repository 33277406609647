import * as SkillActions from './skill.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of, mergeMap } from 'rxjs';
import { SkillService } from 'src/app/services/skill/skill.service';
import { camelToSnake, objectToCamel } from 'src/app/utils/objectTransform';

@Injectable()
export class SkillEffects {
  //category
  getSkillCategoryById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.getSkillCategoryById),
      switchMap((action) => {
        return this.skill.getSkillCategoryById(action.id).pipe(
          map((response: any) => {
            const skillCategory = objectToCamel(response);
            return SkillActions.getSkillCategoryByIdSuccess({ skillCategory });
          }),
          catchError((error) => {
            return of(
              SkillActions.getSkillCategoryByIdFailure({
                errorMessage: error.error.message,
              })
            );
          })
        );
      })
    )
  );

  listSkillCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.listSkillCategory),
      switchMap((action) => {
        return this.skill.listSkillCategory(action.size, action.page).pipe(
          map((response: any) => {
            const skillCategoryResponse = objectToCamel(response);
            return SkillActions.listSkillCategorySuccess({
              skillCategoryResponse,
            });
          }),
          catchError((error) => {
            return of(
              SkillActions.listSkillCategoryFailure({
                errorMessage: error.error.message,
              })
            );
          })
        );
      })
    )
  );

  createSkillCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.createSkillCategory),
      switchMap((action) => {
        return this.skill
          .createSkillCategory(camelToSnake(action.skillCategory))
          .pipe(
            map(() => {
              return SkillActions.createSkillCategorySuccess();
            }),
            catchError((error) => {
              return of(
                SkillActions.createSkillCategoryFailure({
                  errorMessage: error.error.message,
                })
              );
            })
          );
      })
    )
  );

  updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.updateCategory),
      switchMap((action) => {
        return this.skill
          .updateSkillCategory(camelToSnake(action.skillCategory))
          .pipe(
            map(() => {
              return SkillActions.updateCategorySuccess();
            }),
            catchError((error) => {
              return of(
                SkillActions.updateCategoryFailure({
                  errorMessage: error.error.message,
                })
              );
            })
          );
      })
    )
  );

  removeCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.removeCategory),
      switchMap((action) => {
        return this.skill.removeSkillCategory(action.id).pipe(
          map(() => {
            return SkillActions.removeCategorySuccess({ id: action.id });
          }),
          catchError((error) => {
            return of(
              SkillActions.removeCategoryFailure({
                errorMessage: error.error.message,
              })
            );
          })
        );
      })
    )
  );

  //skill
  getSkillById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.getSkillById),
      switchMap((action) => {
        return this.skill.getSkillById(action.id).pipe(
          map((response: any) => {
            const skill = objectToCamel(response);
            return SkillActions.getSkillByIdSuccess({ skill });
          }),
          catchError((error) => {
            return of(
              SkillActions.getSkillByIdFailure({
                errorMessage: error.error.message,
              })
            );
          })
        );
      })
    )
  );

  listSkill$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.listSkill),
      switchMap((action) => {
        return this.skill.listSkill(action.size, action.page).pipe(
          map((response: any) => {
            const skillResponse = objectToCamel(response);
            return SkillActions.listSkillSuccess({ skillResponse });
          }),
          catchError((error) => {
            return of(
              SkillActions.listSkillFailure({
                errorMessage: error.error.message,
              })
            );
          })
        );
      })
    )
  );

  listSkillByCategoryId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.listSkillByCategoryId),
      switchMap((action) => {
        return this.skill
          .listSkillByCategoryId(action.size, action.page, action.categoryId)
          .pipe(
            map((response: any) => {
              let skillResponse = objectToCamel(response);
              return SkillActions.listSkillByCategoryIdSuccess({
                skillResponse,
              });
            }),
            catchError((error) => {
              return of(
                SkillActions.listSkillByCategoryIdFailure({
                  errorMessage: error.error.message,
                })
              );
            })
          );
      })
    )
  );

  createSkill$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.createSkill),
      switchMap((action) => {
        return this.skill.createSkill(camelToSnake(action.skill)).pipe(
          map(() => {
            return SkillActions.createSkillSuccess();
          }),
          catchError((error) => {
            return of(
              SkillActions.createSkillFailure({
                errorMessage: error.error.message,
              })
            );
          })
        );
      })
    )
  );

  updateSkill$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.updateSkill),
      switchMap((action) => {
        return this.skill.updateSkill(camelToSnake(action.skill)).pipe(
          map(() => {
            return SkillActions.updateSkillSuccess();
          }),
          catchError((error) => {
            return of(
              SkillActions.updateSkillFailure({
                errorMessage: error.error.message,
              })
            );
          })
        );
      })
    )
  );

  removeSkill$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.removeSkill),
      switchMap((action) => {
        return this.skill.removeSkill(action.id).pipe(
          map(() => {
            return SkillActions.removeSkillSuccess({ id: action.id });
          }),
          catchError((error) => {
            return of(
              SkillActions.removeSkillFailure({
                errorMessage: error.error.message,
              })
            );
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private skill: SkillService) {}
}
