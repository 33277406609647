import {Injectable} from '@angular/core';
import {HttpClientWithAuth} from "../../utils/HttpClientWithAuth";
import {TimeSlot} from "../../models/usage_time.model";
import * as http from "http";

@Injectable({
  providedIn: 'root'
})
export class UsageTimeService {

  constructor(private http: HttpClientWithAuth) {
  }

  create(timeSlot: TimeSlot) {
    return this.http.post('/usage_time', timeSlot);
  }

  getByUserId(userId: string, currentTime: number) {
    return this.http.get('/usage_time?user_id=' + userId + '&current_time=' + currentTime);
  }
}
