import {createReducer, on} from '@ngrx/store';
import * as TrackingItemActions from './tracking-item.actions';
import {TrackingItemState} from './tracking-item.state';
import {MicrocourseResponse} from 'src/app/models/microcourse.model';

const initialState: TrackingItemState = {
  trackingHotItems: <MicrocourseResponse>{},
  trackingRecentItems: <MicrocourseResponse>{},
  isLoadingRecentItems: false,
  isLoadingHotItems: false,
  loadingHotItemsErrorMessage: '',
  loadingRecentItemsErrorMessage: ''
};

export const trackingItemReducer = createReducer(
  initialState,
  on(TrackingItemActions.resetStates, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
      isLoadingRecentItems: false,
      isLoadingHotItems: false,
      loadingHotItemsErrorMessage: '',
      loadingRecentItemsErrorMessage: '',
      trackingHotItems: <MicrocourseResponse>{},
      trackingRecentItems: <MicrocourseResponse>{},
    };
  }),
  on(TrackingItemActions.listTrackingItemsByView, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
      trackingHotItems: <MicrocourseResponse>{},
      isLoadingHotItems: true,
      loadingHotItemsErrorMessage: '',
    };
  }),
  on(TrackingItemActions.listTrackingItemsByViewSuccess, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
      trackingHotItems: action.trackingItems,
      isLoadingHotItems: false,
    };
  }),
  on(TrackingItemActions.listTrackingItemsByViewFailure, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
      isLoadingHotItems: false,
      loadingHotItemsErrorMessage: action.errorMessage,
    };
  }),
  on(TrackingItemActions.listTrackingItemsByEnrolled, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
      isLoadingHotItems: true,
      trackingHotItems: <MicrocourseResponse>{},
      loadingHotItemsErrorMessage: '',
    };
  }),
  on(
    TrackingItemActions.listTrackingItemsByEnrolledSuccess,
    (state, action) => {
      console.log(action.type);
      return <TrackingItemState>{
        ...state,
        isLoadingHotItems: false,
        trackingHotItems: action.trackingItems,
      };
    }
  ),
  on(
    TrackingItemActions.listTrackingItemsByEnrolledFailure,
    (state, action) => {
      console.log(action.type);
      return <TrackingItemState>{
        ...state,
        isLoadingHotItems: false,
        loadingHotItemsErrorMessage: action.errorMessage,
      };
    }
  ),
  on(TrackingItemActions.listTrackingItemsByAddTime, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
      trackingRecentItems: <MicrocourseResponse>{},
      isLoadingRecentItems: true,
      loadingRecentItemsErrorMessage: '',
    };
  }),
  on(TrackingItemActions.listTrackingItemsByAddTimeSuccess, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
      trackingRecentItems: action.trackingItems,
      isLoadingRecentItems: false,
    };
  }),
  on(TrackingItemActions.listTrackingItemsByAddTimeFailure, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
      isLoadingRecentItems: false,
      loadingRecentItemsErrorMessage: action.errorMessage,
    };
  }),
  on(TrackingItemActions.reportView, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
    };
  }),
  on(TrackingItemActions.reportViewSuccess, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
    };
  }),
  on(TrackingItemActions.reportViewFailure, (state, action) => {
    console.log(action.type);
    return <TrackingItemState>{
      ...state,
    };
  })
);
