import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, of, repeat, switchMap} from 'rxjs';
import * as TrackingItemActions from './tracking-item.actions';
import {objectToCamel} from 'src/app/utils/objectTransform';
import {TrackingItemService} from 'src/app/services/tracking-item/tracking-item.service';
import {
  MicrocourseResponse,
} from 'src/app/models/microcourse.model';

@Injectable()
export class TrackingItemEffects {
  listTrackingItemsByView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TrackingItemActions.listTrackingItemsByView),
      switchMap((action) => {
        return this.trackingItemService
          .listTrackingItemsByView(action.page, action.size)
          .pipe(
            map((data) => {
              return TrackingItemActions.listTrackingItemsByViewSuccess({
                trackingItems: <MicrocourseResponse>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                TrackingItemActions.listTrackingItemsByViewFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });
  listTrackingItemsByEnrolled$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TrackingItemActions.listTrackingItemsByEnrolled),
      switchMap((action) => {
        return this.trackingItemService
          .listTrackingItemsByEnrolled(action.page, action.size)
          .pipe(
            map((data) => {
              return TrackingItemActions.listTrackingItemsByEnrolledSuccess({
                trackingItems: <MicrocourseResponse>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                TrackingItemActions.listTrackingItemsByEnrolledFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });
  listTrackingItemsByAddTime$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TrackingItemActions.listTrackingItemsByAddTime),
      switchMap((action) => {
        return this.trackingItemService
          .listTrackingItemsByAddTime(action.page, action.size)
          .pipe(
            map((data) => {
              return TrackingItemActions.listTrackingItemsByAddTimeSuccess({
                trackingItems: <MicrocourseResponse>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                TrackingItemActions.listTrackingItemsByAddTimeFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });
  reportView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TrackingItemActions.reportView),
      switchMap((action) => {
        return this.trackingItemService.reportView(action.trackingItemId).pipe(
          map(() => {
            return TrackingItemActions.reportViewSuccess();
          }),
          catchError((error) => {
            return of(
              TrackingItemActions.reportViewFailure({
                errorMessage: error,
              })
            );
          }),
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private trackingItemService: TrackingItemService
  ) {
  }
}
