import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { StorageService } from 'src/app/services/storage/storage.service';
import * as StorageActions from './storage.actions';
import { UploadedFile } from 'src/app/models/storage.model';
import { camelToSnake } from 'src/app/utils/objectTransform';

@Injectable()
export class StorageEffects {
  constructor(
    private actions$: Actions,
    private storageService: StorageService
  ) {}

  uploadUserAvatar$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorageActions.uploadUserAvatar),
      switchMap((action) => {
        return this.storageService
          .uploadFile(action.file, camelToSnake(action.fileName))
          .pipe(
            map((file) => {
              return StorageActions.uploadUserAvatarSuccess({
                uploadedUserAvatar: <UploadedFile>file,
              });
            }),
            catchError((error) => {
              return of(
                StorageActions.uploadUserAvatarFailure({ errorMessage: error })
              );
            })
          );
      })
    );
  });

  uploadMicrocoursePhotoCover$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorageActions.uploadMicrocoursePhotoCover),
      switchMap((action) => {
        return this.storageService
          .uploadFile(action.file, camelToSnake(action.fileName))
          .pipe(
            map((file) => {
              return StorageActions.uploadMicrocoursePhotoCoverSuccess({
                uploadedMicrocoursePhotoCover: <UploadedFile>file,
              });
            }),
            catchError((error) => {
              return of(
                StorageActions.uploadMicrocoursePhotoCoverFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });

  uploadMicrocourseBadgeImageFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorageActions.uploadMicrocourseBadgeImageFile),
      switchMap((action) => {
        return this.storageService
          .uploadFile(action.file, camelToSnake(action.fileName))
          .pipe(
            map((file) => {
              return StorageActions.uploadMicrocourseBadgeImageFileSuccess({
                uploadedMicrocourseBadgeImageFile: <UploadedFile>file,
              });
            }),
            catchError((error) => {
              return of(
                StorageActions.uploadMicrocourseBadgeImageFileFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });

  uploadSkillsetPhotoCover$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorageActions.uploadSkillsetPhotoCover),
      switchMap((action) => {
        return this.storageService
          .uploadFile(action.file, camelToSnake(action.fileName))
          .pipe(
            map((file) => {
              return StorageActions.uploadSkillsetPhotoCoverSuccess({
                uploadedSkillsetPhotoCover: <UploadedFile>file,
              });
            }),
            catchError((error) => {
              return of(
                StorageActions.uploadSkillsetPhotoCoverFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });

  uploadSkillsetCertificateImageFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorageActions.uploadSkillsetCertificateImageFile),
      switchMap((action) => {
        return this.storageService
          .uploadFile(action.file, camelToSnake(action.fileName))
          .pipe(
            map((file) => {
              return StorageActions.uploadSkillsetCertificateImageFileSuccess({
                uploadedSkillsetCertImageFile: <UploadedFile>file,
              });
            }),
            catchError((error) => {
              return of(
                StorageActions.uploadSkillsetCertificateImageFileFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });

  uploadSkillsetIconImageFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorageActions.uploadSkillsetIconImageFile),
      switchMap((action) => {
        return this.storageService
          .uploadFile(action.file, camelToSnake(action.fileName))
          .pipe(
            map((file) => {
              return StorageActions.uploadSkillsetIconImageFileSuccess({
                uploadedSkillsetIconImageFile: <UploadedFile>file,
              });
            }),
            catchError((error) => {
              return of(
                StorageActions.uploadSkillsetIconImageFileFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });
}
