import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as LearningProgressActions from './learning-progress.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { objectToCamel } from 'src/app/utils/objectTransform';
import { LearningProgressService } from 'src/app/services/learning-progress/learning-progress.service';
import { LearningProgress } from 'src/app/models/learning-progress.model';

@Injectable()
export class LearningProgressEffects {
  constructor(
    private actions$: Actions,
    private learningProgressService: LearningProgressService
  ) {}

  getByUserIdAndMicrocourseId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LearningProgressActions.getByUserIdAndMicrocourseId),
      switchMap((action) => {
        return this.learningProgressService
          .getByUserIdAndMicrocourseId(action.userId, action.microcourseId)
          .pipe(
            map((data) => {
              let learningProgresses: LearningProgress[] = [];
              if ((data as Array<LearningProgress>).length == 0)
                return LearningProgressActions.getByUserIdAndMicrocourseIdSuccess(
                  {
                    learningProgresses,
                  }
                );
              (data as Array<LearningProgress>).forEach((element: any) => {
                learningProgresses.push(
                  <LearningProgress>objectToCamel(element)
                );
              });
              return LearningProgressActions.getByUserIdAndMicrocourseIdSuccess(
                {
                  learningProgresses,
                }
              );
            }),
            catchError((error) => {
              return of(
                LearningProgressActions.getByUserIdAndMicrocourseIdFail({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });

  count$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LearningProgressActions.count),
      switchMap((action) => {
        return this.learningProgressService.getByUserId(action.userId).pipe(
          map((data) => {
            let count = 0;
            if ((data as Array<LearningProgress>).length == 0) {
              return LearningProgressActions.countSuccess({ count: count });
            }
            (data as Array<LearningProgress>).forEach(() => {
              count++;
            });
            return LearningProgressActions.countSuccess({ count: count });
          }),
          catchError((error) => {
            return of(
              LearningProgressActions.countFail({ errorMessage: error })
            );
          })
        );
      })
    );
  });

  getById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LearningProgressActions.getByUserId),
      switchMap((action) => {
        return this.learningProgressService.getByUserId(action.userId).pipe(
          map((data) => {
            let learningProgresses: LearningProgress[] = [];
            if ((data as Array<LearningProgress>).length == 0)
              return LearningProgressActions.getByUserIdSuccess({
                learningProgresses,
              });
            (data as Array<LearningProgress>).forEach((element: any) => {
              learningProgresses.push(<LearningProgress>objectToCamel(element));
            });
            return LearningProgressActions.getByUserIdSuccess({
              learningProgresses,
            });
          }),
          catchError((error) => {
            return of(
              LearningProgressActions.getByUserIdFail({ errorMessage: error })
            );
          })
        );
      })
    );
  });
}
