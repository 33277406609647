import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, of, switchMap } from 'rxjs';
import { ACIService } from 'src/app/services/aci/aci.service';
import { ACI } from 'src/app/models/aci.model';
import * as ACIActions from './aci.actions';

@Injectable()
export class ACIEffects {
  constructor(
    private actions$: Actions,
    private ACIService: ACIService,
  ) {}

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ACIActions.create),
      mergeMap((action) => {
        return this.ACIService.createNewACI(action.newACI).pipe(
          map(() => {
            return ACIActions.createSuccess();
          }),
          catchError((error) => {
            return of(ACIActions.createFailure({ createErrorMessage: error }));
          }),
        );
      }),
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ACIActions.update),
      mergeMap((action) => {
        return this.ACIService.updateACI(action.ACI).pipe(
          map(() => {
            return ACIActions.updateSuccess();
          }),
          catchError((error) => {
            return of(ACIActions.updateFailure({ updateErrorMessage: error }));
          }),
        );
      }),
    );
  });

  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ACIActions.deleteACI),
      mergeMap((action) => {
        return this.ACIService.deleteACI(action.id).pipe(
          map(() => {
            return ACIActions.deleteSuccess();
          }),
          catchError((error) => {
            return of(ACIActions.deleteFailure({ deleteErrorMessage: error }));
          }),
        );
      }),
    );
  });

  getACIByRoleId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ACIActions.getByRoleId),
      switchMap((action) => {
        return this.ACIService.getACIByRoleId(action.roleId).pipe(
          map((data) => {
            return ACIActions.getByRoleIdSuccess({
              acl: data as ACI[],
            });
          }),
          catchError((error) => {
            return of(
              ACIActions.getByRoleIdFailure({ getByRoleIdErrorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getACIByUserId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ACIActions.getByUserId),
      switchMap((action) => {
        return this.ACIService.getACIByUserId(action.userId).pipe(
          map((data) => {
            return ACIActions.getByUserIdSuccess({
              personalAcl: data as ACI[],
            });
          }),
          catchError((error) => {
            return of(
              ACIActions.getByUserIdFailure({ getByUserIdErrorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getPayloadByResource$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ACIActions.getPayloadByResource),
      switchMap((action) => {
        return this.ACIService.getPayloadByResource(action.resource).pipe(
          map((data) => {
            return ACIActions.getPayloadByResourceSuccess({
              payloads: data as string[],
            });
          }),
          catchError((error) => {
            return of(
              ACIActions.getPayloadByResourceFailure({
                getPayloadsErrorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });
}
