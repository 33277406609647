import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpClientWithAuth} from "../../utils/HttpClientWithAuth";

@Injectable({
  providedIn: 'root',
})
export class TrackingItemService {
  constructor(private http: HttpClientWithAuth) {
  }

  listTrackingItemsByView(page: number, size: number) {
    return this.http.get('/store/view?page=' + page + '&size=' + size);
  }

  listTrackingItemsByEnrolled(page: number, size: number) {
    return this.http.get('/store/enrolled?page=' + page + '&size=' + size);
  }

  listTrackingItemsByAddTime(page: number, size: number) {
    return this.http.get('/store/time?page=' + page + '&size=' + size);
  }

  reportView(trackingItemId: string) {
    return this.http.put('/store/report_view?id=' + trackingItemId, null);
  }
}
