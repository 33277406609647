import { MentorProfileState } from './mentor-profile.state';
import { createReducer, on } from '@ngrx/store';
import * as MentorProfileActions from './mentor-profile.actions';
import {
  MentorProfile,
  MentorProfileData,
  MentorProfileResponse,
} from 'src/app/models/profile.model';

const initialState: MentorProfileState = {
  mentorProfileResponse: <MentorProfileResponse>{},
  isListingMentorProfileByStatus: false,
  listMentorProfileByStatusErrorMessages: '',

  mentorProfile: <MentorProfile>{},
  isGettingMentorProfileById: false,
  getMentorProfileByIdErrorMessages: '',

  mineMentorProfile: <MentorProfile>{},
  isGettingMineMentorProfile: false,
  getMineMentorProfileErrorMessages: '',

  isUpdatingMineMentorProfile: false,
  isUpdateMineMentorProfileSuccess: false,
  updateMineMentorProfileErrorMessages: '',

  isCreatingMineMentorProfile: false,
  isCreateMineMentorProfileSuccess: false,
  createMineMentorProfileErrorMessages: '',

  isChangingApproveStatus: false,
  isChangeApproveStatusSuccess: false,
  changeApproveStatusErrorMessages: '',

  isApprovingProfile: false,
  isApproveProfileSuccess: false,
  approveProfileErrorMessages: '',

  isChangingCommission: false,
  isChangeCommissionSuccess: false,
  changeCommissionErrorMessages: '',
};

export const mentorProfileReducer = createReducer(
  initialState,

  on(MentorProfileActions.getMineProfile, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      mineMentorProfile: <MentorProfile>{},
      isGettingMentorProfileById: true,
      getMineMentorProfileErrorMessages: '',
    };
  }),
  on(MentorProfileActions.getMineProfileSuccess, (state, { type, mine }) => {
    console.log(type);
    return <MentorProfileState>{
      ...state,
      mineMentorProfile: mine,
      isGettingMentorProfileById: false,
      getMineMentorProfileErrorMessages: '',
    };
  }),
  on(MentorProfileActions.getMineProfileFailure, (state, action) => {
    console.log(action.errorMessage);
    return <MentorProfileState>{
      ...state,
      isGettingMentorProfileById: false,
      getMineMentorProfileErrorMessages: action.errorMessage,
    };
  }),

  on(MentorProfileActions.createMineProfile, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isCreatingMineMentorProfile: true,
      isCreateMineMentorProfileSuccess: false,
      createMineMentorProfileErrorMessages: '',
    };
  }),
  on(MentorProfileActions.createMineProfileSuccess, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isCreatingMineMentorProfile: false,
      isCreateMineMentorProfileSuccess: true,
    };
  }),
  on(MentorProfileActions.createMineProfileFailure, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isCreatingMineMentorProfile: false,
      createMineMentorProfileErrorMessages: action.errorMessage,
    };
  }),

  on(MentorProfileActions.updateMineProfile, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isUpdatingMineMentorProfile: true,
      isUpdateMineMentorProfileSuccess: false,
      updateMineMentorProfileErrorMessages: '',
    };
  }),
  on(MentorProfileActions.updateMineProfileSuccess, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isUpdatingMineMentorProfile: false,
      isUpdateMineMentorProfileSuccess: true,
    };
  }),
  on(MentorProfileActions.updateMineProfileFailure, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isUpdatingMineMentorProfile: false,
      updateMineMentorProfileErrorMessages: action.errorMessage,
    };
  }),

  on(MentorProfileActions.listByStatus, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      mentorProfileResponse: <MentorProfileResponse>{},
      isListingMentorProfileByStatus: true,
      listMentorProfileByStatusErrorMessages: '',
    };
  }),
  on(
    MentorProfileActions.listByStatusSuccess,
    (state, { type, mentorResponse }) => {
      console.log(type);
      return <MentorProfileState>{
        ...state,
        mentorProfileResponse: mentorResponse,
        isListingMentorProfileByStatus: false,
      };
    },
  ),
  on(MentorProfileActions.listByStatusFailure, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isListingMentorProfileByStatus: false,
      listMentorProfileByStatusErrorMessages: action.errorMessage,
    };
  }),

  on(MentorProfileActions.getMentorProfileById, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      mentorProfile: <MentorProfile>{},
      isGettingMentorProfileById: true,
      getMentorProfileByIdErrorMessages: '',
    };
  }),
  on(
    MentorProfileActions.getMentorProfileByIdSuccess,
    (state, { type, mentor }) => {
      console.log(type);
      return <MentorProfileState>{
        ...state,
        mentorProfile: mentor,
        isGettingMentorProfileById: false,
      };
    },
  ),
  on(MentorProfileActions.getMentorProfileByIdFailure, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isGettingMentorProfileById: false,
      getMentorProfileByIdErrorMessages: action.errorMessage,
    };
  }),

  on(MentorProfileActions.changeApproveStatus, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isChangingApproveStatus: true,
      isChangeApproveStatusSuccess: false,
      changeApproveStatusErrorMessages: '',
    };
  }),
  on(MentorProfileActions.changeApproveStatusSuccess, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isChangingApproveStatus: false,
      isChangeApproveStatusSuccess: true,
    };
  }),
  on(MentorProfileActions.changeApproveStatusFailure, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isChangingApproveStatus: false,
      changeApproveStatusErrorMessages: action.errorMessage,
    };
  }),

  on(MentorProfileActions.approveProfile, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isApprovingProfile: true,
      isApproveProfileSuccess: false,
      approveProfileErrorMessages: '',
    };
  }),
  on(MentorProfileActions.approveProfileSuccess, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isApprovingProfile: false,
      isApproveProfileSuccess: true,
    };
  }),
  on(MentorProfileActions.approveProfileFailure, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isApprovingProfile: false,
      approveProfileErrorMessages: action.errorMessage,
    };
  }),

  on(MentorProfileActions.changeCommissionRate, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isChangingCommission: true,
      isChangeCommissionSuccess: false,
      changeCommissionErrorMessages: '',
    };
  }),
  on(MentorProfileActions.changeCommissionRateSuccess, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isChangingCommission: false,
      isChangeCommissionSuccess: true,
    };
  }),
  on(MentorProfileActions.changeCommissionRateFailure, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isChangingCommission: false,
      changeCommissionErrorMessages: action.errorMessage,
    };
  }),
  on(MentorProfileActions.clearCreateMentorProfileStates, (state, action) => {
    // console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isCreatingMineMentorProfile: false,
      isCreateMineMentorProfileSuccess: false,
      createMineMentorProfileErrorMessages: '',
    };
  }),
  on(MentorProfileActions.clearUpdateMentorProfileStates, (state, action) => {
    // console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isUpdatingMineMentorProfile: false,
      isUpdateMineMentorProfileSuccess: false,
      updateMineMentorProfileErrorMessages: '',
    };
  }),
  on(
    MentorProfileActions.clearGetListMentorProfileByStatusStates,
    (state, action) => {
      console.log(action.type);
      return <MentorProfileState>{
        ...state,
        isListingMentorProfileByStatus: false,
        mentorProfileResponse: <MentorProfileResponse>{},
        listMentorProfileByStatusErrorMessages: '',
      };
    },
  ),
  on(MentorProfileActions.clearChangeApproveStatusStates, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isChangingApproveStatus: false,
      isChangeApproveStatusSuccess: false,
      changeApproveStatusErrorMessages: '',
    };
  }),
  on(MentorProfileActions.clearApproveMentorProfileStates, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isApprovingProfile: false,
      isApproveProfileSuccess: false,
      approveProfileErrorMessages: '',
    };
  }),
  on(MentorProfileActions.clearChangeCommissionStates, (state, action) => {
    console.log(action.type);
    return <MentorProfileState>{
      ...state,
      isChangingCommission: false,
      isChangeCommissionSuccess: false,
      changeCommissionErrorMessages: '',
    };
  }),
);
