import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpClientWithAuth} from "../../utils/HttpClientWithAuth";

@Injectable({
  providedIn: 'root',
})
export class LearningProgressService {
  constructor(private http: HttpClientWithAuth) {
  }

  getByUserIdAndMicrocourseId(userId: string, microcourseId: string) {
    return this.http.get(
      `/learning_progress/usr_course?user_id=${userId}&microcourse_id=${microcourseId}`
    );
  }

  getByUserId(userId: string) {
    return this.http.get(`/learning_progress/user?user_id=${userId}`);
  }
}
