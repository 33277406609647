import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TaigaModule } from './taiga.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighlightModule } from 'ngx-highlightjs';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StorageService } from '../services/storage/storage.service';

import { SideBarComponent } from './common-components/side-bar/side-bar.component';
import { BasicEditorComponent } from './common-components/basic-editor/basic-editor.component';
import { CourseCardComponent } from '../pages/main/microcourse/components/course-card/course-card.component';
import { CourseDetailDrawerComponent } from '../pages/main/microcourse/components/course-detail-drawer/course-detail-drawer.component';
import { AciFormComponent } from '../pages/main/admin/components/aci-form/aci-form.component';
import { PreviewEditorComponent } from './common-components/preview-editor/preview-editor.component';
import { MobileDeviceWarningComponent } from './common-components/mobile-device-warning/mobile-device-warning.component';
import { NumberGroupingPipe } from './pipes/number-grouping.pipe';
import { OnlineTimeGroupingPipe } from './pipes/online-time-grouping.pipe';
import { IdToEmailPipe } from './pipes/id-to-email.pipe';
import { SkillsetCardComponent } from '../pages/main/microcourse/components/skillset-card/skillset-card.component';
import { SourceToBlobPipe } from './pipes/source-to-blob.pipe';
import { SchedulePipe } from './pipes/schedule.pipe';
import { IdToNamePipe } from './pipes/id-to-name.pipe';

@NgModule({
  declarations: [
    SideBarComponent,
    BasicEditorComponent,
    CourseDetailDrawerComponent,
    CourseCardComponent,
    SkillsetCardComponent,
    AciFormComponent,
    PreviewEditorComponent,
    MobileDeviceWarningComponent,
    NumberGroupingPipe,
    OnlineTimeGroupingPipe,
    IdToEmailPipe,
    SourceToBlobPipe,
    SchedulePipe,
    IdToNamePipe,
  ],
  imports: [
    CommonModule,
    TaigaModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HighlightModule,
    ScrollingModule,
    NgOptimizedImage,
  ],
  exports: [
    TaigaModule,
    AciFormComponent,
    FormsModule,
    ReactiveFormsModule,
    SideBarComponent,
    BasicEditorComponent,
    CourseDetailDrawerComponent,
    CourseCardComponent,
    PreviewEditorComponent,
    HighlightModule,
    ScrollingModule,
    MobileDeviceWarningComponent,
    NumberGroupingPipe,
    OnlineTimeGroupingPipe,
    IdToEmailPipe,
    SkillsetCardComponent,
    SourceToBlobPipe,
    SchedulePipe,
    IdToNamePipe,
  ],
  providers: [StorageService],
})
export class SharedModule {}
