import {createAction, props} from '@ngrx/store';
import {AnswerSheet, QuizAttempt, QuizManifest} from 'src/app/models/quiz.model';
import {QuizQuestion} from 'src/app/models/question.model';

export const create = createAction(
  '[Quiz] Create Quiz Manifest',
  props<{ manifest: QuizManifest }>()
);

export const createSuccess = createAction(
  '[Quiz] Create Quiz Manifest Success'
);

export const createFailure = createAction(
  '[Quiz] Create Quiz Manifest Failure',
  props<{ errorMessage: string }>()
);

export const getByCourseId = createAction(
  '[Quiz] Get Quiz Manifest By Course Id',
  props<{ courseId: string }>()
);

export const getByCourseIdSuccess = createAction(
  '[Quiz] Get Quiz Manifest By Course Id Success',
  props<{ manifest: QuizManifest }>()
);

export const getByCourseIdFailure = createAction(
  '[Quiz] Get Quiz Manifest By Course Id Failure',
  props<{ errorMessage: string }>()
);

export const update = createAction(
  '[Quiz] Update Quiz Manifest',
  props<{ manifest: QuizManifest }>()
);

export const updateSuccess = createAction(
  '[Quiz] Update Quiz Manifest Success'
);

export const updateFailure = createAction(
  '[Quiz] Update Quiz Manifest Failure',
  props<{ errorMessage: string }>()
);

export const deleteByCourseId = createAction(
  '[Quiz] Delete Quiz Manifest By Course Id',
  props<{ courseId: string }>()
);

export const deleteByCourseIdSuccess = createAction(
  '[Quiz] Delete Quiz Manifest By Course Id Success'
);

export const deleteByCourseIdFailure = createAction(
  '[Quiz] Delete Quiz Manifest By Course Id Failure',
  props<{ errorMessage: string }>()
);

export const createQuestion = createAction(
  '[Quiz] Create Quiz Question',
  props<{ question: QuizQuestion }>()
);

export const createQuestionSuccess = createAction(
  '[Quiz] Create Quiz Question Success'
);

export const createQuestionFailure = createAction(
  '[Quiz] Create Quiz Question Failure',
  props<{ errorMessage: string }>()
);

export const getQuestionById = createAction(
  '[Quiz] Get Quiz Question By Id',
  props<{ id: string }>()
);

export const getQuestionByIdSuccess = createAction(
  '[Quiz] Get Quiz Question By Id Success',
  props<{ question: QuizQuestion }>()
);

export const getQuestionByIdFailure = createAction(
  '[Quiz] Get Quiz Question By Id Failure',
  props<{ errorMessage: string }>()
);

export const getQuestionsByCourseId = createAction(
  '[Quiz] Get Quiz Question By Course Id',
  props<{ courseId: string }>()
);

export const getQuestionsByCourseIdSuccess = createAction(
  '[Quiz] Get Quiz Question By Course Id Success',
  props<{ questionList: QuizQuestion[] }>()
);

export const getQuestionsByCourseIdFailure = createAction(
  '[Quiz] Get Quiz Question By Course Id Failure',
  props<{ errorMessage: string }>()
);

export const updateQuestion = createAction(
  '[Quiz] Update Quiz Question',
  props<{ question: QuizQuestion }>()
);

export const updateQuestionSuccess = createAction(
  '[Quiz] Update Quiz Question Success'
);

export const updateQuestionFailure = createAction(
  '[Quiz] Update Quiz Question Failure',
  props<{ errorMessage: string }>()
);

export const deleteQuestion = createAction(
  '[Quiz] Delete Quiz Question',
  props<{ id: string }>()
);

export const deleteQuestionSuccess = createAction(
  '[Quiz] Delete Quiz Question Success'
);

export const deleteQuestionFailure = createAction(
  '[Quiz] Delete Quiz Question Failure',
  props<{ errorMessage: string }>()
);

export const createAttempt = createAction(
  '[Quiz] Create Quiz Attempt',
  props<{ attempt: string; quizId: string }>()
);

export const createAttemptSuccess = createAction(
  '[Quiz] Create Quiz Attempt Success',
  props<{ attempt: QuizAttempt }>()
);

export const createAttemptFailure = createAction(
  '[Quiz] Create Quiz Attempt Failure',
  props<{ errorMessage: string }>()
);

export const submitAttempt = createAction(
  '[Quiz] Submit Quiz Attempt',
  props<{ answerSheet: AnswerSheet; attemptId: string }>()
);

export const submitAttemptSuccess = createAction(
  '[Quiz] Submit Quiz Attempt Success'
);

export const submitAttemptFailure = createAction(
  '[Quiz] Submit Quiz Attempt Failure',
  props<{ errorMessage: string }>()
);

export const getMineAttempts = createAction(
  '[Quiz] Get Mine Quiz Attempts',
  props<{ quizId: string }>()
);

export const getMineAttemptsSuccess = createAction(
  '[Quiz] Get Mine Quiz Attempts Success',
  props<{ attemptList: QuizAttempt[] }>()
);

export const getMineAttemptsFailure = createAction(
  '[Quiz] Get Mine Quiz Attempts Failure',
  props<{ errorMessage: string }>()
);

export const getAttemptById = createAction(
  '[Quiz] Get Quiz Attempt By Id',
  props<{ id: string }>()
);

export const getAttemptByIdSuccess = createAction(
  '[Quiz] Get Quiz Attempt By Id Success',
  props<{ attempt: QuizAttempt }>()
);

export const getAttemptByIdFailure = createAction(
  '[Quiz] Get Quiz Attempt By Id Failure',
  props<{ errorMessage: string }>()
);

export const resetStates = createAction('[Quiz] Reset States');

export const clearQuestionMessage = createAction('[Quiz] Clear Question Message');
export const clearQuizMessage = createAction('[Quiz] Clear Quiz Message');
export const clearQuizAttemptMessage = createAction('[Quiz] Clear Quiz Attempt Message');
export const confirmSubmit = createAction(
  '[Quiz] Confirm Submit Attempt',
  props<{ confirm: number }>()
);

export const getQuestionInAttemptById = createAction(
  '[Quiz] Get Quiz Question In Attempt By Id',
  props<{ id: string }>()
);

export const getQuestionInAttemptByIdSuccess = createAction(
  '[Quiz] Get Quiz Question In Attempt By Id Success',
  props<{ question: QuizQuestion }>()
);

export const getQuestionInAttemptByIdFailure = createAction(
  '[Quiz] Get Quiz Question In Attempt By Id Failure',
  props<{ errorMessage: string }>()
);

export const getAttemptBestResult = createAction(
  '[Quiz] Get Quiz Attempt Best Result',
  props<{ quizId: string }>()
);

export const getAttemptBestResultSuccess = createAction(
  '[Quiz] Get Quiz Attempt Best Result Success',
  props<{ attempt: QuizAttempt }>()
);

export const getAttemptBestResultFailure = createAction(
  '[Quiz] Get Quiz Attempt Best Result Failure',
  props<{ errorMessage: string }>()
);

export const countAttemptsByUserId = createAction(
  '[Quiz] Count Quiz Attempts By User Id'
);
export const countAttemptsByUserIdSuccess = createAction(
  '[Quiz] Count Quiz Attempts By User Id Success',
  props<{ count: number }>()
);
export const countAttemptsByUserIdFailure = createAction(
  '[Quiz] Count Quiz Attempts By User Id Failure',
  props<{ errorMessage: string }>()
);
