import { createAction, props } from '@ngrx/store';
import { MicrocourseResponse } from 'src/app/models/microcourse.model';

export const listTrackingItemsByView = createAction(
  '[Tracking Item] List Tracking Items By View',
  props<{ page: number; size: number }>()
);
export const listTrackingItemsByViewSuccess = createAction(
  '[Tracking Item] List Tracking Items By View Success',
  props<{ trackingItems: MicrocourseResponse }>()
);
export const listTrackingItemsByViewFailure = createAction(
  '[Tracking Item] List Tracking Items By View Failure',
  props<{ errorMessage: string }>()
);

export const listTrackingItemsByEnrolled = createAction(
  '[Tracking Item] List Tracking Items By Enrolled',
  props<{ page: number; size: number }>()
);
export const listTrackingItemsByEnrolledSuccess = createAction(
  '[Tracking Item] List Tracking Items By Enrolled Success',
  props<{ trackingItems: MicrocourseResponse }>()
);
export const listTrackingItemsByEnrolledFailure = createAction(
  '[Tracking Item] List Tracking Items By Enrolled Failure',
  props<{ errorMessage: string }>()
);

export const listTrackingItemsByAddTime = createAction(
  '[Tracking Item] List Tracking Items By Add Time',
  props<{ page: number; size: number }>()
);
export const listTrackingItemsByAddTimeSuccess = createAction(
  '[Tracking Item] List Tracking Items By Add Time Success',
  props<{ trackingItems: MicrocourseResponse }>()
);
export const listTrackingItemsByAddTimeFailure = createAction(
  '[Tracking Item] List Tracking Items By Add Time Failure',
  props<{ errorMessage: string }>()
);

export const reportView = createAction(
  '[Tracking Item] Report View',
  props<{ trackingItemId: string }>()
);
export const reportViewSuccess = createAction(
  '[Tracking Item] Report View Success'
);
export const reportViewFailure = createAction(
  '[Tracking Item] Report View Failure',
  props<{ errorMessage: string }>()
);

export const resetStates = createAction('[Tracking Item] Reset States');
