import { createAction, props } from '@ngrx/store';
import {
  Microcourse,
  MicrocourseResponse,
} from 'src/app/models/microcourse.model';

export const clearStates = createAction('[Microcourse] Clear States');

export const clearMessages = createAction('[Microcourse] Clear Messages');

export const create = createAction(
  '[Microcourse] Create Microcourse',
  props<{ microcourse: Microcourse }>()
);

export const createSuccess = createAction(
  '[Microcourse] Create Microcourse Success'
);

export const createFailure = createAction(
  '[Microcourse] Create Microcourse Failure',
  props<{ errorMessage: string }>()
);

export const getResponse = createAction(
  '[Microcourse] Get Response',
  props<{ page: number; size: number }>()
);

export const getResponseSuccess = createAction(
  '[Microcourse] Get Response Success',
  props<{ microcourses: MicrocourseResponse }>()
);

export const getResponseFailure = createAction(
  '[Microcourse] Get Response Failure',
  props<{ errorMessage: string }>()
);

export const getById = createAction(
  '[Microcourse] Get By Id',
  props<{ id: string }>()
);

export const getByIdSuccess = createAction(
  '[Microcourse] Get By Id Success',
  props<{ microcourse: Microcourse }>()
);

export const getByIdFailure = createAction(
  '[Microcourse] Get By Id Failure',
  props<{ errorMessage: string }>()
);

export const update = createAction(
  '[Microcourse] Update',
  props<{ microcourse: Microcourse }>()
);

export const updateSuccess = createAction('[Microcourse] Update Success');

export const updateFailure = createAction(
  '[Microcourse] Update Failure',
  props<{ errorMessage: string }>()
);

export const deleteById = createAction(
  '[Microcourse] Delete By Id',
  props<{ id: string }>()
);

export const deleteByIdSuccess = createAction(
  '[Microcourse] Delete By Id Success'
);

export const deleteByIdFailure = createAction(
  '[Microcourse] Delete By Id Failure',
  props<{ errorMessage: string }>()
);

export const getEnrolledById = createAction(
  '[Microcourse] Get Enrolled By Id',
  props<{ id: string }>()
);

export const getEnrolledByIdSuccess = createAction(
  '[Microcourse] Get Enrolled By Id Success',
  props<{ microcourse: Microcourse }>()
);

export const getEnrolledByIdFailure = createAction(
  '[Microcourse] Get Enrolled By Id Failure',
  props<{ errorMessage: string }>()
);

export const clearEnrolledCourse = createAction(
  '[Microcourse] Clear Enrolled Course'
);

export const getByUserPayload = createAction(
  '[Microcourse] Get By User Payload ',
  props<{ id: string }>()
);

export const getByUserPayloadSuccess = createAction(
  '[Microcourse] Get By User Payload  Success',
  props<{ microcourse: Microcourse }>()
);

export const getByUserPayloadFailure = createAction(
  '[Microcourse] Get By User Payload  Failure',
  props<{ errorMessage: string }>()
);

export const GetPagination = createAction('[Microcourse] Get Pagination');

export const publish = createAction(
  '[Microcourse] Publish',
  props<{ id: string }>()
);

export const publishSuccess = createAction('[Microcourse] Publish Success');

export const publishFailure = createAction(
  '[Microcourse] Publish Failure',
  props<{ errorMessage: string }>()
);

export const search = createAction(
  '[Microcourse] Search',
  props<{ query: string }>()
);

export const searchSuccess = createAction(
  '[Microcourse] Search Success',
  props<{ microcourses: Microcourse[] }>()
);

export const searchFailure = createAction(
  '[Microcourse] Search Failure',
  props<{ errorMessage: string }>()
);

export const getInfo = createAction(
  '[Microcourse] Get Info',
  props<{ id: string }>()
);

export const getInfoSuccess = createAction(
  '[Microcourse] Get Info Success',
  props<{ microcourse: Microcourse }>()
);

export const getInfoFailure = createAction(
  '[Microcourse] Get Info Failure',
  props<{ errorMessage: string }>()
);

export const getCourseOfSkillsetInfo = createAction(
  '[Microcourse] Get Course Of Skillset Info',
  props<{ id: string }>()
);

export const getCourseOfSkillsetInfoSuccess = createAction(
  '[Microcourse] Get Course Of Skillset Info Success',
  props<{ microcourse: Microcourse }>()
);

export const getCourseOfSkillsetInfoFailure = createAction(
  '[Microcourse] Get Course Of Skillset Info Failure',
  props<{ errorMessage: string }>()
);

export const clearMicrocourseInfoStates = createAction(
  '[Microcourse] Clear Microcourse Info States'
);

export const clearMicrocourseSearchStates = createAction(
  '[Microcourse] Clear Microcourse Search States'
);

export const clearMicrocourseOfSkillsetStates = createAction(
  '[Microcourse] Clear Microcourse Of Skillset States'
);
