import {createAction, props} from '@ngrx/store';
import {AttemptData} from "../../models/indexed_db.model";


export const init = createAction(
  '[Indexed DB] Init'
);
export const resetInit = createAction(
  '[Indexed DB] Reset Init'
);

export const create = createAction(
  '[Indexed DB] Create',
  props<{ attemptData: AttemptData }>()
);
export const createSuccess = createAction(
  '[Indexed DB] Create Success'
);
export const createFailure = createAction(
  '[Indexed DB] Create Failure',
  props<{ errorMessage: string }>()
);

export const update = createAction(
  '[Indexed DB] Update',
  props<{ attemptData: AttemptData }>()
);
export const updateSuccess = createAction(
  '[Indexed DB] Update Success'
);
export const updateFailure = createAction(
  '[Indexed DB] Update Failure',
  props<{ errorMessage: string }>()
);

export const get = createAction(
  '[Indexed DB] Get',
  props<{ courseId: string, profileId: string }>()
);
export const getSuccess = createAction(
  '[Indexed DB] Get Success',
  props<{ attemptData: AttemptData }>()
);
export const getFailure = createAction(
  '[Indexed DB] Get Failure',
  props<{ errorMessage: string }>()
);


