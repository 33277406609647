import { AuthState } from './auth.state';
import { createReducer, on } from '@ngrx/store';
import {
  FirebaseUser,
  StaticUserResponse,
  User,
  UserResponse,
} from 'src/app/models/user.model';
import * as AuthActions from './auth.actions';

const initialState: AuthState = {
  isSignInWithGgSuccess: false,
  isSigningInWithGg: false,
  signInWithGgErrorMessage: '',

  userCredential: <User>{},
  isSigningInWithSystemGg: false,
  signInWithSystemGgErrorMessage: '',

  isSigningUpWithSystemGg: false,
  isSignUpWithSystemGgSuccess: false,
  signUpWithSystemGgErrorMessage: '',

  //store value
  idToken: '',
  firebaseUser: <FirebaseUser>{},
  jwt: '',

  userResponse: <UserResponse>{},
  isGettingList: false,
  getListErrorMessage: '',

  isDeleteSuccess: false,
  isDeleting: false,
  deleteErrorMessage: '',

  isAddNew: false,
  isAddNewSuccess: false,
  addNewErrorMessage: '',

  isChangingRole: false,
  isChangeRoleSuccess: false,
  changeRoleErrorMessage: '',

  //static user
  isSigningInWithStaticUser: false,
  staticUserCredential: <StaticUserResponse>{},
  signInWithStaticUserErrorMessage: '',

  isStaticUserLogout: false,
  isStaticUserLogoutSuccess: false,
  staticUserLogoutErrorMessage: '',

  //check sign in status
  isAlreadySignIn: true,
};

export const authReducer = createReducer(
  initialState,
  // signInWithGoogle
  on(AuthActions.signInWithGoogle, (state, action) => {
    console.log(action.type);
    return <AuthState>{
      ...state,
      isSigningInWithGg: true,
      isSignInWithGgSuccess: false,
      signInWithGgErrorMessage: '',
    };
  }),
  on(AuthActions.signInWithGoogleSuccess, (state, action) => {
    console.log(action.type);
    return <AuthState>{
      ...state,
      isSigningInWithGg: false,
      isSignInWithGgSuccess: true,
    };
  }),
  on(AuthActions.signInWithGoogleFailure, (state, action) => {
    console.log(action.type);
    return <AuthState>{
      ...state,
      isSigningInWithGg: false,
      signInWithGgErrorMessage: action.errorMessage,
    };
  }),

  // signInWithSystemGG
  on(AuthActions.signInWithSystemGG, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isSigningInWithSystemGg: true,
      signInWithSystemGgErrorMessage: '',
      userCredential: <User>{},
    };
  }),
  on(
    AuthActions.signInWithSystemGGSuccess,
    (state, { type, userCredential }) => {
      console.log(type);
      return <AuthState>{
        ...state,
        isSigningInWithSystemGg: false,
        userCredential: userCredential,
      };
    },
  ),
  on(AuthActions.signInWithSystemGGFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isSigningInWithSystemGg: false,
      signInWithSystemGgErrorMessage: errorMessage,
    };
  }),

  // signUpWithSystemGG
  on(AuthActions.signUpWithSystemGG, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isSigningUpWithSystemGg: true,
      isSignUpWithSystemGgSuccess: false,
      signUpWithSystemGgErrorMessage: '',
    };
  }),
  on(AuthActions.signUpWithSystemGGSuccess, (state, action) => {
    console.log(action.type);
    return <AuthState>{
      ...state,
      isSigningUpWithSystemGg: false,
      isSignUpWithSystemGgSuccess: true,
    };
  }),
  on(AuthActions.signUpWithSystemGGFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isSigningUpWithSystemGg: false,
      signUpWithSystemGgErrorMessage: errorMessage,
    };
  }),

  // storeIdToken
  on(AuthActions.storeIdToken, (state, { type, idToken }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      idToken: idToken,
    };
  }),

  // storeFirebaseUser
  on(AuthActions.storeFirebaseUser, (state, { type, firebaseUser }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      firebaseUser: firebaseUser,
    };
  }),

  // storeJWT
  on(AuthActions.storeJWT, (state, { type, jwt }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      jwt: jwt,
    };
  }),

  // getList
  on(AuthActions.getList, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      userResponse: <UserResponse>{},
      isGettingList: true,
      getListErrorMessage: '',
    };
  }),
  on(AuthActions.getListSuccess, (state, { type, userResponse }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      userResponse: userResponse,
      isGettingList: false,
    };
  }),
  on(AuthActions.getListFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isGettingList: false,
      getListErrorMessage: errorMessage,
    };
  }),

  // delete
  on(AuthActions.deleteUser, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isDeleteSuccess: false,
      isDeleting: true,
      deleteErrorMessage: '',
    };
  }),
  on(AuthActions.deleteUserSuccess, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isDeleteSuccess: true,
      isDeleting: false,
    };
  }),
  on(AuthActions.deleteUserFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isDeleting: false,
      deleteErrorMessage: errorMessage,
    };
  }),

  // changeRole
  on(AuthActions.changeRole, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isChangingRole: true,
      isChangeRoleSuccess: false,
      changeRoleErrorMessage: '',
    };
  }),
  on(AuthActions.changeRoleSuccess, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isChangingRole: false,
      isChangeRoleSuccess: true,
    };
  }),
  on(AuthActions.changeRoleFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isChangingRole: false,
      changeRoleErrorMessage: errorMessage,
    };
  }),

  // signInWithStaticUser
  on(AuthActions.signInWithStaticUser, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      staticUserCredential: <StaticUserResponse>{},
      isSigningInWithStaticUser: true,
      signInWithStaticUserErrorMessage: '',
    };
  }),
  on(
    AuthActions.signInWithStaticUserSuccess,
    (state, { type, staticUserResponse }) => {
      console.log(type);
      return <AuthState>{
        ...state,
        staticUserCredential: staticUserResponse,
        isSigningInWithStaticUser: false,
      };
    },
  ),
  on(
    AuthActions.signInWithStaticUserFailure,
    (state, { type, errorMessage }) => {
      console.log(type);
      return <AuthState>{
        ...state,
        isSigningInWithStaticUser: false,
        signInWithStaticUserErrorMessage: errorMessage,
      };
    },
  ),

  // signOutStaticUser
  on(AuthActions.signOutStaticUser, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isStaticUserLogout: true,
      isStaticUserLogoutSuccess: false,
      staticUserLogoutErrorMessage: '',
    };
  }),
  on(AuthActions.signOutStaticUserSuccess, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isStaticUserLogout: false,
      isStaticUserLogoutSuccess: true,
    };
  }),
  on(AuthActions.signOutStaticUserFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isStaticUserLogout: false,
      staticUserLogoutErrorMessage: errorMessage,
    };
  }),

  // clearAllState
  on(AuthActions.clearState, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isSignInWithGgSuccess: false,
      isSigningInWithGg: false,
      signInWithGgErrorMessage: '',

      userCredential: <User>{},
      isSigningInWithSystemGg: false,
      signInWithSystemGgErrorMessage: '',

      isSigningUpWithSystemGg: false,
      isSignUpWithSystemGgSuccess: false,
      signUpWithSystemGgErrorMessage: '',

      //store value
      idToken: '',
      firebaseUser: <FirebaseUser>{},
      jwt: '',

      userResponse: <UserResponse>{},
      isGettingList: false,
      getListErrorMessage: '',

      isDeleteSuccess: false,
      isDeleting: false,
      deleteErrorMessage: '',

      isAddNew: false,
      isAddNewSuccess: false,
      addNewErrorMessage: '',

      isChangingRole: false,
      isChangeRoleSuccess: false,
      changeRoleErrorMessage: '',

      //static user
      isSigningInWithStaticUser: false,
      staticUserCredential: <StaticUserResponse>{},
      signInWithStaticUserErrorMessage: '',

      isStaticUserLogout: false,
      isStaticUserLogoutSuccess: false,
      staticUserLogoutErrorMessage: '',

      //check sign in status
      isAlreadySignIn: false,
    };
  }),

  // clearMessages
  on(AuthActions.clearMessages, (state) => {
    return <AuthState>{
      ...state,
      isSignInWithGgSuccess: false,
      isSigningInWithGg: false,
      signInWithGgErrorMessage: '',

      isSigningInWithSystemGg: false,
      isSignInWithSystemGgSuccess: false,
      signInWithSystemGgErrorMessage: '',

      isSigningUpWithSystemGg: false,
      isSignUpWithSystemGgSuccess: false,
      signUpWithSystemGgErrorMessage: '',

      isGettingList: false,
      getListErrorMessage: '',

      isDeleteSuccess: false,
      isDeleting: false,
      deleteErrorMessage: '',

      isAddNew: false,
      isAddNewSuccess: false,
      addNewErrorMessage: '',

      isChangingRole: false,
      isChangeRoleSuccess: false,
      changeRoleErrorMessage: '',

      //static user
      isSigningInWithStaticUser: false,
      signInWithStaticUserErrorMessage: '',

      isStaticUserLogout: false,
      isStaticUserLogoutSuccess: false,
      staticUserLogoutErrorMessage: '',
    };
  }),

  on(AuthActions.verifyIsSignInYet, (state, action) => {
    // console.log(action.type);
    // console.log(action.isAlreadySignIn)
    return <AuthState>{
      ...state,
      isAlreadySignIn: action.isAlreadySignIn,
    };
  }),
);
