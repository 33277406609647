import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {RoleService} from 'src/app/services/role/role.service';
import {RoleActions} from './role.actions';
import {catchError, map, of, switchMap} from 'rxjs';
import {Role} from 'src/app/models/role.model';

@Injectable()
export class RoleEffects {
  constructor(private actions$: Actions, private roleService: RoleService) {
  }

  getAllRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoleActions.getAllRoles),
      switchMap(() => {
        return this.roleService.getAllRoles().pipe(
          map((data) => {
            let roles: Role[] = [];
            roles.push({
              id: 'default',
              name: 'default',
              description: 'default',
            });
            roles = [...roles, ...(<Role[]>data)];
            return RoleActions.getAllRolesSuccess({
              roles: roles,
            });
          }),
          catchError((error) => {
            return of(RoleActions.getAllRolesFailure({errorMessage: error}));
          })
        );
      })
    );
  });

  createRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoleActions.createRole),
      switchMap((action) => {
        return this.roleService.createNewRole(action.newRole).pipe(
          map(() => {
            return RoleActions.createRoleSuccess();
          }),
          catchError((error) => {
            return of(RoleActions.createRoleFailure({errorMessage: error}));
          })
        );
      })
    );
  });

  updateRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoleActions.updateRole),
      switchMap((action) => {
        return this.roleService.updateRole(action.role).pipe(
          map(() => {
            return RoleActions.updateRoleSuccess();
          }),
          catchError((error) => {
            return of(RoleActions.updateRoleFailure({errorMessage: error}));
          })
        );
      })
    );
  });

  deleteRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoleActions.deleteRole),
      switchMap((action) => {
        return this.roleService.deleteRole(action.role.id).pipe(
          map(() => {
            return RoleActions.deleteRoleSuccess();
          }),
          catchError((error) => {
            return of(RoleActions.deleteRoleFailure({errorMessage: error}));
          })
        );
      })
    );
  });
}
