// @ts-ignore
export function objectToCamel(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((v) => objectToCamel(v));
  }

  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = key.replace(/([-_][a-z])/gi, ($1) =>
      $1.toUpperCase().replace('-', '').replace('_', '')
    );

    // @ts-ignore
    acc[camelKey] = objectToCamel(obj[key]);
    return acc;
  }, {});
}

export function camelToSnake(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((v) => camelToSnake(v));
  }

  return Object.keys(obj).reduce((acc, key) => {
    const snakeKey = key.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);

    // @ts-ignore
    acc[snakeKey] = camelToSnake(obj[key]);
    return acc;
  }, {});
}
