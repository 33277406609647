import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MicrocourseService } from 'src/app/services/microcourse/microcourse.service';
import { catchError, delay, map, mergeMap, of, switchMap } from 'rxjs';
import { camelToSnake, objectToCamel } from 'src/app/utils/objectTransform';
import { PaymentOSResponse } from 'src/app/models/payment.model';

import * as PaymentActions from './payment.actions';
import { PaymentService } from '../../services/payment/payment.service';

@Injectable()
export class PaymentEffects {
  createWithPayOS$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentActions.createWithPayOS),
      switchMap((action) => {
        return this.paymentService.createWithPayOS(action.sku).pipe(
          map((paymentOSResponse: PaymentOSResponse) => {
            return PaymentActions.createWithPayOSSuccess({ paymentOSResponse });
          }),
          catchError((error) => {
            return of(
              PaymentActions.createWithPayOSFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private paymentService: PaymentService,
  ) {}
}
