import { createReducer, on } from '@ngrx/store';
import { StorageState } from './storage.state';
import * as StorageActions from './storage.actions';
import { UploadedFile } from 'src/app/models/storage.model';

const initialState: StorageState = {
  uploadedUserAvatar: <UploadedFile>{},
  isUploadingUserAvatar: false,
  uploadUserAvatarErrorMessage: '',

  uploadedMicrocoursePhotoCover: <UploadedFile>{},
  isUploadingMicrocoursePhotoCover: false,
  uploadMicrocoursePhotoCoverErrorMessage: '',

  uploadedMicrocourseBadgeImageFile: <UploadedFile>{},
  isUploadingMicrocourseBadgeImageFile: false,
  uploadMicrocourseBadgeImageFileErrorMessage: '',

  uploadedSkillsetPhotoCover: <UploadedFile>{},
  isUploadingSkillsetPhotoCover: false,
  uploadSkillsetPhotoCoverErrorMessage: '',

  uploadedCertificateImageFile: <UploadedFile>{},
  isUploadingCertificatemageFile: false,
  uploadCertificatemageFileErrorMessage: '',

  uploadedSkillsetIconImageFile: <UploadedFile>{},
  isUploadingSkillsetIconImageFile: false,
  uploadSkillsetIconImageFileErrorMessage: '',
};

export const storageReducer = createReducer(
  initialState,

  on(StorageActions.uploadUserAvatar, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingUserAvatar: true,
      uploadUserAvatarErrorMessage: '',
    };
  }),
  on(StorageActions.uploadUserAvatarSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedUserAvatar: action.uploadedUserAvatar,
      isUploadingUserAvatar: false,
    };
  }),
  on(StorageActions.uploadUserAvatarFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingUserAvatar: false,
      uploadUserAvatarErrorMessage: action.errorMessage,
    };
  }),

  on(StorageActions.uploadMicrocoursePhotoCover, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingMicrocoursePhotoCover: true,
      uploadMicrocoursePhotoCoverErrorMessage: '',
    };
  }),
  on(StorageActions.uploadMicrocoursePhotoCoverSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedMicrocoursePhotoCover: action.uploadedMicrocoursePhotoCover,
      isUploadingMicrocoursePhotoCover: false,
    };
  }),
  on(StorageActions.uploadMicrocoursePhotoCoverFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingMicrocoursePhotoCover: false,
      uploadMicrocoursePhotoCoverErrorMessage: action.errorMessage,
    };
  }),

  on(StorageActions.uploadMicrocourseBadgeImageFile, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingMicrocourseBadgeImageFile: true,
      uploadMicrocourseBadgeImageFileErrorMessage: '',
    };
  }),
  on(StorageActions.uploadMicrocourseBadgeImageFileSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedMicrocourseBadgeImageFile:
        action.uploadedMicrocourseBadgeImageFile,
      isUploadingMicrocourseBadgeImageFile: false,
    };
  }),
  on(StorageActions.uploadMicrocourseBadgeImageFileFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingMicrocourseBadgeImageFile: false,
      uploadMicrocourseBadgeImageFileErrorMessage: action.errorMessage,
    };
  }),

  on(StorageActions.uploadSkillsetPhotoCover, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingSkillsetPhotoCover: true,
      uploadSkillsetPhotoCoverErrorMessage: '',
    };
  }),
  on(StorageActions.uploadSkillsetPhotoCoverSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedSkillsetPhotoCover: action.uploadedSkillsetPhotoCover,
      isUploadingSkillsetPhotoCover: false,
    };
  }),
  on(StorageActions.uploadSkillsetPhotoCoverFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingSkillsetPhotoCover: false,
      uploadSkillsetPhotoCoverErrorMessage: action.errorMessage,
    };
  }),

  on(StorageActions.uploadSkillsetCertificateImageFile, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingCertificatemageFile: true,
      uploadCertificatemageFileErrorMessage: '',
    };
  }),
  on(
    StorageActions.uploadSkillsetCertificateImageFileSuccess,
    (state, action) => {
      console.log(action.type);
      return {
        ...state,
        uploadedCertificateImageFile: action.uploadedSkillsetCertImageFile,
        isUploadingCertificatemageFile: false,
      };
    }
  ),
  on(
    StorageActions.uploadSkillsetCertificateImageFileFailure,
    (state, action) => {
      console.log(action.type);
      return {
        ...state,
        isUploadingCertificatemageFile: false,
        uploadCertificatemageFileErrorMessage: action.errorMessage,
      };
    }
  ),

  on(StorageActions.uploadSkillsetIconImageFile, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingSkillsetIconImageFile: true,
      uploadSkillsetIconImageFileErrorMessage: '',
    };
  }),
  on(StorageActions.uploadSkillsetIconImageFileSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedSkillsetIconImageFile: action.uploadedSkillsetIconImageFile,
      isUploadingSkillsetIconImageFile: false,
    };
  }),
  on(StorageActions.uploadSkillsetIconImageFileFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingSkillsetIconImageFile: false,
      uploadSkillsetIconImageFileErrorMessage: action.errorMessage,
    };
  }),

  on(StorageActions.clearStates, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedUserAvatar: <UploadedFile>{},
      isUploadingUserAvatar: false,
      uploadUserAvatarErrorMessage: '',

      uploadedMicrocoursePhotoCover: <UploadedFile>{},
      isUploadingMicrocoursePhotoCover: false,
      uploadMicrocoursePhotoCoverErrorMessage: '',

      uploadedMicrocourseBadgeImageFile: <UploadedFile>{},
      isUploadingMicrocourseBadgeImageFile: false,
      uploadMicrocourseBadgeImageFileErrorMessage: '',

      uploadedSkillsetPhotoCover: <UploadedFile>{},
      isUploadingSkillsetPhotoCover: false,
      uploadSkillsetPhotoCoverErrorMessage: '',

      uploadedCertificateImageFile: <UploadedFile>{},
      isUploadingCertificatemageFile: false,
      uploadCertificatemageFileErrorMessage: '',

      uploadedSkillsetIconImageFile: <UploadedFile>{},
      isUploadingSkillsetIconImageFile: false,
      uploadSkillsetIconImageFileErrorMessage: '',
    };
  }),
  on(StorageActions.clearUserAvatarStates, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedUserAvatar: <UploadedFile>{},
      isUploadingUserAvatar: false,
      uploadUserAvatarErrorMessage: '',
    };
  }),
  on(StorageActions.clearMicrocourseBadgeImageFileStates, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedMicrocourseBadgeImageFile: <UploadedFile>{},
      isUploadingMicrocourseBadgeImageFile: false,
      uploadMicrocourseBadgeImageFileErrorMessage: '',
    };
  }),
  on(StorageActions.clearMicrocoursePhotoCoverStates, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedMicrocoursePhotoCover: <UploadedFile>{},
      isUploadingMicrocoursePhotoCover: false,
      uploadMicrocoursePhotoCoverErrorMessage: '',
    };
  }),
  on(StorageActions.clearSkillsetPhotoCoverStates, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedSkillsetPhotoCover: <UploadedFile>{},
      isUploadingSkillsetPhotoCover: false,
      uploadSkillsetPhotoCoverErrorMessage: '',
    };
  }),
  on(StorageActions.clearSkillsetIconImageFileStates, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedSkillsetIconImageFile: <UploadedFile>{},
      isUploadingSkillsetIconImageFile: false,
      uploadSkillsetIconImageFileErrorMessage: '',
    };
  }),
  on(
    StorageActions.clearSkillsetCertificateImageFileStates,
    (state, action) => {
      console.log(action.type);
      return {
        ...state,
        uploadedCertificateImageFile: <UploadedFile>{},
        isUploadingCertificatemageFile: false,
        uploadCertificatemageFileErrorMessage: '',
      };
    }
  )
);
