import { createAction, props } from '@ngrx/store';
import { Batch, Voucher, VoucherResponse } from 'src/app/models/voucher.model';

export const check = createAction(
  '[Voucher] Check',
  props<{ voucher: Voucher }>(),
);
export const checkSuccess = createAction(
  '[Voucher] Check Success',
  props<{ voucher: Voucher }>(),
);
export const checkFailure = createAction(
  '[Voucher] Check Failure',
  props<{ errorMessage: string }>(),
);

export const redeem = createAction(
  '[Voucher] Redeem',
  props<{ voucher: Voucher }>(),
);
export const redeemSuccess = createAction('[Voucher] Redeem Success');
export const redeemFailure = createAction(
  '[Voucher] Redeem Failure',
  props<{ errorMessage: string }>(),
);

export const getByBatchId = createAction(
  '[Voucher] Get By Batch Id',
  props<{ batchId: string; page: number; size: number }>(),
);
export const getByBatchIdSuccess = createAction(
  '[Voucher] Get By Batch Id Success',
  props<{ voucherResponse: VoucherResponse }>(),
);
export const getByBatchIdFailure = createAction(
  '[Voucher] Get By Batch Id Failure',
  props<{ errorMessage: string }>(),
);

export const generate = createAction(
  '[Voucher] Generate',
  props<{ batch: Batch }>(),
);
export const generateSuccess = createAction('[Voucher] Generate Success');
export const generateFailure = createAction(
  '[Voucher] Generate Failure',
  props<{ errorMessage: string }>(),
);

export const getBatchByTimeRange = createAction(
  '[Voucher] Get By Time Range',
  props<{ from: number; to: number }>(),
);
export const getBatchByTimeRangeSuccess = createAction(
  '[Voucher] Get By Time Range Success',
  props<{ batchList: Batch[] }>(),
);
export const getBatchByTimeRangeFailure = createAction(
  '[Voucher] Get By Time Range Failure',
  props<{ errorMessage: string }>(),
);

export const clearStates = createAction('[Voucher] Clear States');
