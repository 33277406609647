<div id="course-detail-cover">
  <tui-scrollbar style="width: 100%; height: 100%">
    <div class="course-detail-content">
      <div
        class="img-cover"
        *ngIf="
          course.photoCover | sourceToBlob | async as blobUrl;
          else defaultCover
        "
      >
        <img
          [src]="
            blobUrl == ''
              ? '../../../../assets/images/default-course.png'
              : blobUrl
          "
          alt="photoCover"
        />
      </div>
      <ng-template #defaultCover>
        <div class="img-cover">
          <img
            [src]="'../../../../assets/images/default-course.png'"
            alt="photoCover"
          />
        </div>
      </ng-template>
      <div class="course-meta-data">
        <div class="left-data">
          <tui-rating
            [(ngModel)]="ratingStar"
            [readOnly]="true"
            class="rating"
          ></tui-rating>
          <p class="num-rate tui-space_left-2">{{ this.ratingStar }}</p>
          <p class="font-f-detail tui-space_left-4">
            {{ count$ | async }} người đăng ký
          </p>
          <span class="material-symbols-rounded blue tui-space_left-4">
            person
          </span>
          <p class="font-f-detail tui-space_left-2">
            {{
              (otherProfile$ | async)?.lastName == undefined
                ? ""
                : (otherProfile$ | async)?.lastName
            }}
            {{
              (otherProfile$ | async)?.firstName == undefined
                ? ""
                : (otherProfile$ | async)?.firstName
            }}
          </p>
        </div>
        <div
          *ngIf="course.enrollPrice != 0; else freeCourse"
          class="font-f-detail right-data tui-space_left-2"
        >
          Giá: {{ course.enrollPrice | numberGrouping }} credits
        </div>
        <ng-template #freeCourse>
          <div class="font-f-detail right-data">Miễn phí</div>
        </ng-template>
      </div>
      <div class="title-row-cover tui-space_bottom-4">
        <div class="detail-course-title">
          {{ course.title }}
        </div>
        <button
          (click)="learnCourse()"
          *ngIf="isEnrolled; else enrollBtn"
          appearance="primary"
          size="m"
          tuiButton
        >
          Học
        </button>
        <ng-template #enrollBtn>
          <ng-template #haveSignedIn>
            <button
              (click)="showPaymentDialog()"
              *ngIf="
                this.profile?.id != undefined && this.profile?.id != '';
                else noProfile
              "
              appearance="primary"
              size="m"
              tuiButton
            >
              {{ course.enrollPrice == 0 ? "Lấy ngay" : "Đăng ký học" }}
            </button>
            <ng-template #noProfile>
              <button
                (click)="navigateToHome()"
                appearance="primary"
                size="m"
                tuiButton
              >
                Cập nhật thông tin cá nhân
              </button>
            </ng-template>
          </ng-template>
          <button
            (click)="navigateToHome()"
            *ngIf="(this.jwt$ | async) == ''; else haveSignedIn"
            appearance="primary"
            size="m"
            tuiButton
          >
            Đăng nhập
          </button>
        </ng-template>
      </div>

      <div class="font-f-detail">{{ course.description }}</div>
      <div class="head-title tui-space_top-4">Phần thưởng</div>
      <div class="badge-list">
        <div class="badge-cover shadow">
          <div class="badge-img">
            <tui-avatar
              [autoColor]="true"
              [avatarUrl]="course.badgesImgUrl"
              [size]="'m'"
            ></tui-avatar>
          </div>
          <div class="badge-content">
            <div class="badge-title">{{ course.title }}</div>
            <div class="badge-description">Hoàn thành Microcourse</div>
          </div>
        </div>
      </div>
      <div class="head-title">Phản hồi khóa học</div>
      <div class="feedback-list">
        <tui-scrollbar style="height: 100%; width: 100%">
          <tui-loader
            [inheritColor]="true"
            [overlay]="true"
            [showLoader]="isGettingFeedback"
            class="loader"
          >
            <div
              *ngIf="(feedbackResponse$ | async)?.data?.length == 0"
              class="tui-text_body-xl none-feedback"
            >
              Chưa có nhận xét
            </div>
            <div
              *ngFor="let comment of (feedbackResponse$ | async)?.data"
              class="feedback-cover shadow tui-space_bottom-4"
            >
              <div class="feedback-img tui-space_right-2">
                <tui-avatar
                  [autoColor]="true"
                  [avatarUrl]="comment.avatar"
                  [rounded]="true"
                  [size]="'l'"
                  [text]="comment.name"
                ></tui-avatar>
              </div>
              <div class="feedback-info tui-space_right-2">
                <div class="feedback-author">{{ comment.name }}</div>
                <tui-rating
                  [(ngModel)]="comment.score"
                  [readOnly]="true"
                  class="rating"
                ></tui-rating>
              </div>
              <div class="feedback-content">
                <span [innerText]="comment.content" class="comment"></span>
                <div class="date-time">
                  {{ comment.CreatedAt | date : "dd/MM/yyyy" }}
                </div>
              </div>
            </div>
          </tui-loader>
        </tui-scrollbar>
      </div>
    </div>
  </tui-scrollbar>
</div>

<ng-template
  [(tuiDialog)]="openPaymentDialog"
  [tuiDialogOptions]="{ label: 'Xác nhận thanh toán?', size: 's' }"
>
  <div>Microcourse: {{ course.title }}</div>
  <div>Giá: {{ course.enrollPrice }} credits</div>
  <div class="button-group-dialog">
    <button
      (click)="closePaymentDialog()"
      appearance="flat"
      class="tui-space_right-4"
      size="m"
      tuiButton
      type="submit"
    >
      Hủy
    </button>
    <button
      (click)="enrollCourse()"
      appearance="primary"
      size="m"
      tuiButton
      type="submit"
    >
      Mua
    </button>
  </div>
</ng-template>

<ng-template
  [(tuiDialog)]="openEnrollDialog"
  [tuiDialogOptions]="{ label: 'Không thể thanh toán', size: 's' }"
>
  <div>Vui lòng kiểm tra số dư tài khoản của bạn và thử lại sau</div>
  <div class="button-group-dialog">
    <button
      (click)="closeEnrollErrorDialog()"
      appearance="flat"
      class="tui-space_right-4"
      size="m"
      tuiButton
      type="submit"
    >
      Hủy
    </button>
    <button
      (click)="navigateToHome()"
      appearance="primary"
      size="m"
      tuiButton
      type="submit"
    >
      Nạp ngay
    </button>
  </div>
</ng-template>
