import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import { MentorProfileService } from 'src/app/services/mentor-profile/mentor-profile.service';
import * as mentorProfileActions from './mentor-profile.actions';
import {
  MentorProfileResponse,
  MentorProfileData,
  MentorProfile,
} from 'src/app/models/profile.model';
import { camelToSnake, objectToCamel } from 'src/app/utils/objectTransform';

@Injectable()
export class MentorProfileEffects {
  constructor(
    private actions$: Actions,
    private mentor: MentorProfileService
  ) {}

  createMineProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mentorProfileActions.createMineProfile),
      switchMap((action) => {
        let mentorProfile = action.profile;
        //camel to snake
        mentorProfile = camelToSnake(mentorProfile);
        // console.log(mentorProfile);
        return this.mentor.createMine(mentorProfile).pipe(
          map(() => {
            return mentorProfileActions.createMineProfileSuccess();
          }),
          catchError((error) => {
            return of(
              mentorProfileActions.createMineProfileFailure({
                errorMessage: error,
              })
            );
          })
        );
      })
    );
  });

  updateMineProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mentorProfileActions.updateMineProfile),
      switchMap((action) => {
        let mentorProfile = action.profile;
        //camel to snake
        mentorProfile = camelToSnake(mentorProfile);
        // console.log(mentorProfile);
        return this.mentor.updateMine(mentorProfile).pipe(
          map(() => {
            return mentorProfileActions.updateMineProfileSuccess();
          }),
          catchError((error) => {
            return of(
              mentorProfileActions.updateMineProfileFailure({
                errorMessage: error,
              })
            );
          })
        );
      })
    );
  });

  getMineProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mentorProfileActions.getMineProfile),
      switchMap(() => {
        return this.mentor.getMine().pipe(
          map((data) => {
            return mentorProfileActions.getMineProfileSuccess({
              mine: <MentorProfile>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              mentorProfileActions.getMineProfileFailure({
                errorMessage: error.message,
              })
            );
          })
        );
      })
    );
  });

  getAllByStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mentorProfileActions.listByStatus),
      switchMap((action) => {
        return this.mentor
          .listByStatus(action.status, action.size, action.page)
          .pipe(
            map((data) => {
              return mentorProfileActions.listByStatusSuccess({
                mentorResponse: <MentorProfileResponse>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                mentorProfileActions.listByStatusFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });

  getMentorProfileById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mentorProfileActions.getMentorProfileById),
      switchMap((action) => {
        return this.mentor.getMentorProfilebyId(action.id).pipe(
          map((data) => {
            return mentorProfileActions.getMentorProfileByIdSuccess({
              mentor: <MentorProfile>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              mentorProfileActions.getMentorProfileByIdFailure({
                errorMessage: error,
              })
            );
          })
        );
      })
    );
  });

  changeApproveStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mentorProfileActions.changeApproveStatus),
      switchMap((action) => {
        return this.mentor.changeApproveStatus(action.id, action.status).pipe(
          map(() => {
            return mentorProfileActions.changeApproveStatusSuccess();
          }),
          catchError((error) => {
            return of(
              mentorProfileActions.changeApproveStatusFailure({
                errorMessage: error,
              })
            );
          })
        );
      })
    );
  });

  approveProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mentorProfileActions.approveProfile),
      switchMap((action) => {
        return this.mentor.approveProfile(action.id).pipe(
          map(() => {
            return mentorProfileActions.approveProfileSuccess();
          }),
          catchError((error) => {
            return of(
              mentorProfileActions.approveProfileFailure({
                errorMessage: error,
              })
            );
          })
        );
      })
    );
  });

  changeCommissionRate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mentorProfileActions.changeCommissionRate),
      switchMap((action) => {
        return this.mentor
          .changeCommission(action.id, action.commissionRate)
          .pipe(
            map(() => {
              return mentorProfileActions.changeCommissionRateSuccess();
            }),
            catchError((error) => {
              return of(
                mentorProfileActions.changeCommissionRateFailure({
                  errorMessage: error,
                })
              );
            })
          );
      })
    );
  });
}
