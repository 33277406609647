import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuizAttempt, QuizManifest } from 'src/app/models/quiz.model';
import { QuizQuestion } from 'src/app/models/question.model';
import { HttpClientWithAuth } from '../../utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  constructor(private http: HttpClientWithAuth) {}

  create(manifest: QuizManifest) {
    return this.http.post('/quiz', manifest);
  }

  getByCourseId(courseId: string) {
    return this.http.get(`/quiz?course_id=${courseId}`);
  }

  update(manifest: QuizManifest) {
    return this.http.put('/quiz', manifest);
  }

  delete(courseId: string) {
    return this.http.delete(`/quiz?course_id=${courseId}`);
  }

  createQuestion(question: QuizQuestion) {
    return this.http.post('/quiz/question', question);
  }

  getQuestionById(id: string) {
    return this.http.get(`/quiz/question?id=${id}`);
  }

  getQuestionByCourseId(id: string) {
    return this.http.get(`/quiz/question/course?course_id=${id}`);
  }

  updateQuestion(question: QuizQuestion) {
    return this.http.put('/quiz/question', question);
  }

  deleteQuestion(id: string) {
    return this.http.delete(`/quiz/question?id=${id}`);
  }

  createAttempt(quizId: string, attempt: string) {
    // return this.http.post<QuizAttempt>(
    return this.http.post(
      `/quiz/attempt?quiz_id=${quizId}&attempt=${attempt}`,
      {}
    );
  }

  submitAttempt(attempt: QuizAttempt, attemptId: string) {
    return this.http.post(
      '/quiz/attempt/submit?attempt_id=' + attemptId,
      attempt
    );
  }

  getMineAttemptsByQuizId(quizId: string) {
    return this.http.get('/quiz/attempt/mine?quiz_id=' + quizId);
  }

  getAttemptByQuizId(quizId: string) {
    return this.http.get('/quiz/attempt?id=' + quizId);
  }

  getAttemptBestResult(quizId: string) {
    return this.http.get('/quiz/attempt/best_result?quiz_id=' + quizId);
  }

  countAttemptsByUserId() {
    return this.http.get('/quiz/attempt/count_by_user');
  }
}
