import { Renderer2, inject } from '@angular/core';

export type transforms = {
  [key: string]: any;
  delimiter(): HTMLElement;
  header(block: block): HTMLElement;
  paragraph(block: block): HTMLElement;
  list(block: block): HTMLElement;
  link(block: block): string;
  simpleImage(block: block): HTMLElement;
  image(block: block): HTMLElement;
  quote(block: block): HTMLElement;
  code(block: block): HTMLElement;
  embed(block: block): HTMLElement;
  warning(block: block): HTMLElement;
  table(block: block): HTMLElement;
  raw(block: block): HTMLElement;
  AnyButton(block: block): HTMLElement;
  checklist(block: block): HTMLElement;
};

type tags = {
  tagName: string;
  value: string;
};

type ListItem = {
  content: string;
  items: Array<ListItem>;
};

const alignType = ['left', 'right', 'center', 'justify'];

export type block = {
  type: string;
  data: {
    text?: string;
    level?: number;
    caption?: string;
    url?: string;
    file?: {
      url?: string;
    };
    stretched?: boolean;
    withBackground?: boolean;
    withBorder?: boolean;
    items?: Array<string> | Array<ListItem>;
    style?: string;
    code?: string;
    service?: 'vimeo' | 'youtube';
    source?: string;
    embed?: string;
    width?: number;
    height?: number;
    alignment?: 'left' | 'right' | 'center' | 'justify';
    align?: 'left' | 'right' | 'center' | 'justify';
  };
};

export class EditorTransforms {
  renderer = inject(Renderer2);
  hljs = require('highlight.js');

  constructor() {}

  renderCode = (code: any) => {
    if (code.nodeName == 'SPAN') {
      let tempCode = code as HTMLElement;
      let codeContent = this.renderer.createElement('span');
      if (tempCode.childNodes.length > 0) {
        console.log(tempCode.childNodes);
        for (let i = 0; i < tempCode.childNodes.length; i++) {
          codeContent.appendChild(this.renderCode(tempCode.childNodes[i]));
        }
      }
      for (let j = 0; j < tempCode.classList.length; j++) {
        codeContent.classList.add(tempCode.classList[j]);
      }
      // codeContent.innerHTML = tempCode.innerHTML;
      return codeContent;
    }
    let codeContent = this.renderer.createText(code.nodeValue || '');
    return codeContent;
  };

  transforms: transforms = {
    delimiter: () => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let blockDelimiter = this.renderer.createElement('div');
      blockDelimiter.classList.add('block-delimiter');

      contentBlock.appendChild(blockDelimiter);
      return contentBlock;
    },

    header: ({ data }) => {
      const headerAlign = data.alignment || data.align;

      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let element = this.renderer.createElement(`h${data.level}`);
      element.classList.add(`block_header--${data.level}`);
      element.innerHTML = data.text || '';
      if (
        typeof headerAlign !== 'undefined' &&
        alignType.includes(headerAlign)
      ) {
        element.style.textAlign = headerAlign;
      }
      contentBlock.appendChild(element);

      return contentBlock;
      // return `<h${data.level}>${data.text}</h${data.level}>`;
    },

    paragraph: ({ data }) => {
      const paragraphAlign = data.alignment || data.align;

      // let tempArrayTags = this.detectTagHTML(data.text!);

      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let element = this.renderer.createElement('p');
      element.classList.add('block-paragraph');
      element.innerHTML = data.text || '';

      if (
        typeof paragraphAlign !== 'undefined' &&
        alignType.includes(paragraphAlign)
      ) {
        element.style.textAlign = paragraphAlign;
      }

      contentBlock.appendChild(element);

      return contentBlock;
    },

    list: ({ data }) => {
      const listStyle = data.style === 'unordered' ? 'ul' : 'ol';
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');
      let element = this.renderer.createElement(listStyle);
      element.classList.add('block-list');
      element.classList.add(`block-list--${data.style}`);
      // element.classList.add(`block-list_${data.style}_${data.alignment}`);

      data.items!.forEach((item: any) => {
        let listItem = this.renderer.createElement('li');
        listItem.classList.add('list__item');
        listItem.innerHTML = item || '';
        element.appendChild(listItem);
      });

      contentBlock.appendChild(element);

      return contentBlock;
    },

    simpleImage: ({ data }) => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let caption = data.caption ? data.caption : '';
      let figure = this.renderer.createElement('figure');
      figure.classList.add('block-image');

      let div = this.renderer.createElement('div');
      div.classList.add('block-image__content');

      let element = this.renderer.createElement('img');
      element.src = data.url ? data.url : '';
      element.alt = caption;

      div.appendChild(element);
      figure.appendChild(div);

      contentBlock.appendChild(figure);

      console.log(data);

      return contentBlock;
    },

    image: ({ data }) => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let newCaption = data.file?.url?.split('/').pop();
      let caption = data.caption ? data.caption : newCaption;
      let figure = this.renderer.createElement('figure');
      figure.classList.add('block-image');

      let div = this.renderer.createElement('div');
      div.classList.add('block-image__content');

      let element = this.renderer.createElement('img');
      element.src = data.file?.url ? data.file.url : '';
      element.alt = caption;

      div.appendChild(element);
      figure.appendChild(div);

      contentBlock.appendChild(figure);

      // console.log(data)
      // console.log(contentBlock)

      return contentBlock;
    },

    quote: ({ data }) => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let element = this.renderer.createElement('blockquote');
      element.innerHTML = `${data.text} - ${data.caption}` || '';
      element.classList.add('blockquote');

      contentBlock.appendChild(element);

      return contentBlock;
    },

    code: ({ data }) => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let contentBlockCode = this.renderer.createElement('div');
      contentBlockCode.classList.add('block-code');

      let contentBlockCode2 = this.renderer.createElement('div');
      contentBlockCode2.classList.add('block-code__wrapper');

      let element = this.renderer.createElement('pre');

      let code = this.renderer.createElement('code');
      code.classList.add('hljs');

      let highlightedCode = this.hljs.highlightAuto(data.code);
      code.classList.add('language-' + highlightedCode.language);

      //convert string to html and get child nodes array
      let codeContentArray = new DOMParser().parseFromString(
        highlightedCode.value,
        'text/html',
      ).body.childNodes;

      console.log(codeContentArray);

      // create code content with the renderer2
      for (let i = 0; i < codeContentArray.length; i++) {
        // if (codeContentArray[i].nodeName == 'SPAN') {
        //   let tempCode = codeContentArray[i] as HTMLElement;
        //   console.log(tempCode);
        //   let codeContent = this.renderer.createElement('span');
        //   for (let j = 0; j < tempCode.classList.length; j++) {
        //     codeContent.classList.add(tempCode.classList[j]);
        //   }
        //   codeContent.innerHTML = tempCode.innerHTML;
        //   code.appendChild(codeContent);
        //   continue;
        // }
        let codeContent = this.renderCode(codeContentArray[i]);
        // let codeContent = this.renderer.createText(
        //   codeContentArray[i].nodeValue || ''
        // );
        code.appendChild(codeContent);
      }
      element.appendChild(code);
      contentBlockCode2.appendChild(element);
      contentBlockCode.appendChild(contentBlockCode2);
      contentBlock.appendChild(contentBlockCode);

      return contentBlock;
    },

    embed: ({ data }) => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let element = this.renderer.createElement('iframe');
      element.src = data.embed!;
      element.height = data.height!.toString();
      element.width = data.width!.toString();
      element.allowFullscreen = true;
      element.frameBorder = '0';

      switch (data.service) {
        case 'vimeo': {
          element.allow = 'autoplay; fullscreen; picture-in-picture';
          element.title = 'Vimeo video player';

          contentBlock.appendChild(element);

          return contentBlock;
        }
        // return `<iframe src="${data.embed}" height="${data.height}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
        case 'youtube': {
          element.allow =
            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
          element.title = 'YouTube video player';

          contentBlock.appendChild(element);

          return contentBlock;
        }
        default:
          throw new Error(
            'Only Youtube and Vime Embeds are supported right now.',
          );
      }
    },

    warning: ({ data }: any) => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let element = this.renderer.createElement('div');
      element.classList.add('block-warning');

      let icon = this.renderer.createElement('div');
      icon.classList.add('block-warning__icon');
      icon.innerHTML = '☝️';

      let content = this.renderer.createElement('div');
      content.classList.add('block-warning__content');

      let title = this.renderer.createElement('div');
      title.classList.add('block-warning__title');
      title.innerHTML = data.title || '';

      let message = this.renderer.createElement('div');
      message.classList.add('block-warning__message');
      message.innerHTML = data.message || '';

      content.appendChild(title);
      content.appendChild(message);
      element.appendChild(icon);
      element.appendChild(content);

      contentBlock.appendChild(element);

      return contentBlock;
    },

    table: ({ data }: any) => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let element = this.renderer.createElement('table');
      element.classList.add('block-table');

      let tableBody = this.renderer.createElement('tbody');

      data.content[0].forEach((cell: any) => {
        let tableHeadCell = this.renderer.createElement('td');

        tableHeadCell.innerHTML = cell;
        tableBody.appendChild(tableHeadCell);
      });
      // tableHead.appendChild(tableHeadRow);
      // element.appendChild(tableHead);

      data.content.slice(1).forEach((row: any) => {
        let tableRow = this.renderer.createElement('tr');
        tableRow.classList.add('table__row');
        row.forEach((cell: any) => {
          let tableCell = this.renderer.createElement('td');
          tableCell.classList.add('table__cell');
          tableCell.innerHTML = cell;
          tableRow.appendChild(tableCell);
        });
        tableBody.appendChild(tableRow);
      });
      element.appendChild(tableBody);

      contentBlock.appendChild(element);

      return contentBlock;
    },

    raw: ({ data }: any) => {
      return data.html;
    },

    AnyButton: ({ data }: any) => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let element = this.renderer.createElement('a');
      element.href = data.link!;
      element.classList.add('btn');
      element.classList.add('btn--gray');
      element.innerHTML = data.text || '';

      contentBlock.appendChild(element);

      return contentBlock;
    },

    link: ({ data }: any) => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let element = this.renderer.createElement('a');
      element.href = data.link!;
      element.innerHTML = data.meta!.title || '';

      contentBlock.appendChild(element);

      return contentBlock;
    },

    checklist: ({ data }: any) => {
      let contentBlock = this.renderer.createElement('div');
      contentBlock.classList.add('page__content-block');

      let element = this.renderer.createElement('ul');
      element.classList.add('block-checklist');
      element.classList.add('block-list--unordered');

      data.items.forEach((item: any) => {
        let listItem = this.renderer.createElement('li');
        listItem.classList.add('block-checklist__item');

        let checkbox = this.renderer.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.classList.add('block-checklist__item-checkbox');

        let checkboxInput = this.renderer.createElement('span');
        checkboxInput.classList.add('block-checklist__item-text');
        checkboxInput.innerHTML = item.text || '';

        listItem.appendChild(checkbox);
        listItem.appendChild(checkboxInput);

        element.appendChild(listItem);
      });

      contentBlock.appendChild(element);

      return contentBlock;
    },
  };
}
