import {Injectable} from '@angular/core';
import {HttpClientWithAuth} from "../../utils/HttpClientWithAuth";

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService {
  constructor(private http: HttpClientWithAuth) {
  }

  enroll(microcourseId: string) {
    return this.http.post(
      '/enrollment/enroll?microcourse_id=' + microcourseId,
      null
    );
  }

  deleteById(id: string) {
    return this.http.delete(`/microcourse?id=${id}`);
  }

  listByProfileId(profileId: string, page: number, size: number) {
    return this.http.get(
      `/enrollment?profile_id=${profileId}&page=${page}&size=${size}`
    );
  }

  listByMicrocourseId(microcourseId: string, page: number, size: number) {
    return this.http.get(
      `/enrollment/microcourse?microcourse_id=${microcourseId}&page=${page}&size=${size}`
    );
  }

  countByMicrocourseId(microcourseId: string) {
    return this.http.get(
      `/enrollment/microcourse/count?microcourse_id=${microcourseId}`
    );
  }

  countByProfileId(profileId: string) {
    return this.http.get(`/enrollment/count?profile_id=${profileId}`);
  }
}
