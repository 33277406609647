import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Assignment} from 'src/app/models/assignment.model';
import {HttpClientWithAuth} from "../../utils/HttpClientWithAuth";

@Injectable({
  providedIn: 'root',
})
export class AssignmentService {
  constructor(private http: HttpClientWithAuth) {
  }

  getById(id: string) {
    return this.http.get(`/assignment?id=${id}`);
  }

  create(assignment: Assignment) {
    return this.http.post('/assignment', assignment);
  }

  update(assignment: Assignment) {
    return this.http.put('/assignment', assignment);
  }
}
