import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, map, switchMap, mergeMap } from 'rxjs';

import { ProfileService } from 'src/app/services/profile/profile.service';
import * as ProfileActions from './profile.actions';
import { Profile } from 'src/app/models/profile.model';
import { camelToSnake, objectToCamel } from 'src/app/utils/objectTransform';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
  ) {}

  getMine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.getMine),
      switchMap(() => {
        return this.profileService.getMine().pipe(
          map((data) => {
            return ProfileActions.getMineSuccess({
              mineProfile: <Profile>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              ProfileActions.getMineFailure({ getMineErrorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  createMine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.createMine),
      switchMap((action) => {
        return this.profileService
          .createMine(camelToSnake(action.mineProfile))
          .pipe(
            map(() => {
              return ProfileActions.createMineSuccess();
            }),
            catchError((error) => {
              return of(
                ProfileActions.createMineFailure({
                  createMineErrorMessage: error,
                }),
              );
            }),
          );
      }),
    );
  });

  updateMine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.updateMine),
      switchMap((action) => {
        return this.profileService
          .updateMine(camelToSnake(action.mineProfile))
          .pipe(
            map(() => {
              return ProfileActions.updateMineSuccess();
            }),
            catchError((error) => {
              return of(
                ProfileActions.updateMineFailure({
                  updateMineErrorMessage: error,
                }),
              );
            }),
          );
      }),
    );
  });

  getById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.getById),
      switchMap((action) => {
        return this.profileService.getById(action.id).pipe(
          map((data) => {
            return ProfileActions.getByIdSuccess({
              userProfile: <Profile>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              ProfileActions.getByIdFailure({ getErrorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getByPublic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.getByPublic),
      switchMap((action) => {
        return this.profileService.getByPublic(action.id).pipe(
          map((data) => {
            return ProfileActions.getByPublicSuccess({
              userProfile: <Profile>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              ProfileActions.getByPublicFailure({
                getPublicErrorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });

  updateUserProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.updateUser),
      switchMap((action) => {
        return this.profileService
          .updateUserProfile(camelToSnake(action.userProfile))
          .pipe(
            map(() => {
              return ProfileActions.updateUserSuccess();
            }),
            catchError((error) => {
              return of(
                ProfileActions.updateUserFailure({ updateErrorMessage: error }),
              );
            }),
          );
      }),
    );
  });

  getListById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.getListById),
      mergeMap((action) => {
        return this.profileService.getById(action.id).pipe(
          map((data) => {
            console.log(data);
            return ProfileActions.getListByIdSuccess({
              userProfile: <Profile>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              ProfileActions.getListByIdFailure({ getListErrorMessage: error }),
            );
          }),
        );
      }),
    );
  });
}
