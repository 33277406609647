import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as AuthActions from '../../../ngrx/auth/auth.actions';
import * as ProfileActions from '../../../ngrx/profile/profile.actions';
import * as LearningProgressActions from '../../../ngrx/learning-progress/learning-progress.actions';
import * as EnrollmentActions from '../../../ngrx/enrollment/enrollment.actions';
import * as UsageTimeActions from '../../../ngrx/usage-time/usage-time.actions';
import * as QuizActions from '../../../ngrx/quiz/quiz.actions';
import { Store } from '@ngrx/store';
import { AuthState } from 'src/app/ngrx/auth/auth.state';
import { Subscription, switchMap } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { ACIState } from 'src/app/ngrx/aci/aci.state';
import { ProfileState } from 'src/app/ngrx/profile/profile.state';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output('open') expandEventEmitter = new EventEmitter<boolean>();
  @ViewChild('sidebar', { static: true }) sidebar!: ElementRef;

  userPages = [
    {
      id: 0,
      icon: 'account_circle',
      title: 'Hồ sơ',
      path: 'home',
    },
    {
      id: 1,
      icon: 'explore',
      title: 'Microcourse',
      path: 'explore/microcourses/list',
    },
  ];

  studioPages = [
    {
      id: 0,
      icon: 'grid_view',
      title: 'Courseware Studio',
      path: 'studio/microcourse/list',
    },
  ];

  mentorPages = [
    {
      id: 0,
      icon: 'assignment',
      title: 'Mentor',
      path: 'mentor/profile',
    },
  ];

  adminPages = [
    {
      id: 0,
      icon: 'storefront',
      title: 'Marketing',
      path: 'marketing',
    },
    {
      id: 1,
      icon: 'group',
      title: 'User management',
      path: 'admin/user-management/',
    },
    {
      id: 2,
      icon: 'key',
      title: 'Role management',
      path: 'admin/role-management',
    },
    {
      id: 3,
      icon: 'partner_exchange',
      title: 'Mentor management',
      path: 'admin/mentor-management',
    },
  ];

  itemSelected: number = 1;
  sectionSelected: number = 0;

  subscriptions: Subscription[] = [];

  user: User | null = null;
  isCreator: boolean = false;
  isAdmin: boolean = false;
  isMentor: boolean = false;

  acl$ = this.store.select('aci', 'acl');
  isGetSuccess$ = this.store.select('aci', 'isGetByRoleIdSuccess');

  jwt$ = this.store.select('auth', 'jwt');
  hidden: boolean = true;

  profile$ = this.store.select('profile', 'mineProfile');
  staticUser: any = null;
  isStaticUser: boolean = false;

  isExpanded = true;

  private resizeObserver!: ResizeObserver;

  constructor(
    private router: Router,
    private store: Store<{
      auth: AuthState;
      aci: ACIState;
      profile: ProfileState;
    }>,
    private authService: AuthService,
  ) {}

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver(() => {
      this.getSidebarWidth();
    });
    this.resizeObserver.observe(this.sidebar.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.jwt$.subscribe((jwt) => {
        if (jwt) {
          console.log(jwt);
        }
        this.hidden = !jwt;
      }),
      this.profile$.subscribe((profile) => {
        if (profile.email) {
          this.staticUser =
            profile.email.split('@')[1] === 'staticuser.akademy.dev';
          this.isStaticUser = !!this.staticUser;
        } else {
          this.isStaticUser = false;
        }
      }),
      this.isGetSuccess$
        .pipe(
          switchMap((isGetSuccess) => {
            if (isGetSuccess) {
              return this.acl$;
            }
            return [];
          }),
        )
        .subscribe((acl) => {
          acl.find((item) => {
            if (item.resource === 'v1/profile.PUT') {
              this.isAdmin = true;
            } else if (item.resource === 'v1/microcourse.POST') {
              this.isCreator = true;
            } else if (item.resource === 'v1/mentor/profile.POST') {
              this.isMentor = true;
            }
          });
        }),

      this.router.events.subscribe(() => {
        if (this.router.url.includes('home')) {
          this.itemSelected = 0;
          this.sectionSelected = 0;
        } else if (this.router.url.includes('explore')) {
          this.itemSelected = 1;
          this.sectionSelected = 0;
        } else if (this.router.url.includes('studio')) {
          this.itemSelected = 0;
          this.sectionSelected = 1;
        } else if (this.router.url.includes('mentor/profile')) {
          this.itemSelected = 0;
          this.sectionSelected = 2;
        } else if (this.router.url.includes('marketing')) {
          this.itemSelected = 0;
          this.sectionSelected = 3;
        } else if (this.router.url.includes('admin')) {
          this.sectionSelected = 3;
          if (this.router.url.includes('user-management')) {
            this.itemSelected = 1;
          } else if (this.router.url.includes('role-management')) {
            this.itemSelected = 2;
          } else if (this.router.url.includes('mentor-management')) {
            this.itemSelected = 3;
          }
        } else {
          this.itemSelected = -1;
        }
      }),
    );
  }

  getSidebarWidth(): number {
    const width = this.sidebar.nativeElement.offsetWidth;
    this.isExpanded = width > 70;
    return width;
  }

  signOut() {
    this.authService.signOut().then(() => {
      this.router.navigate(['explore/microcourses/list']).then(() => {
        this.store.dispatch(AuthActions.clearState());
        this.clearState();
      });
    });
  }

  signOutStatic() {
    this.router.navigate(['explore/microcourses/list']).then(() => {
      sessionStorage.clear();
      this.store.dispatch(AuthActions.clearState());
      this.store.dispatch(
        AuthActions.verifyIsSignInYet({ isAlreadySignIn: false }),
      );
      this.clearState();
    });
  }

  clearState() {
    this.store.dispatch(ProfileActions.clearMineProfileStates());
    this.store.dispatch(LearningProgressActions.clearStates());
    this.store.dispatch(EnrollmentActions.clearStates());
    this.store.dispatch(UsageTimeActions.resetStates());
    this.store.dispatch(QuizActions.resetStates());

    this.isAdmin = false;
    this.isCreator = false;
    this.isMentor = false;
    this.sectionSelected = 0;
    this.itemSelected = 1;
  }

  selected(sectionIndex: number, index: number) {
    if (sectionIndex == 0) {
      this.router.navigate([this.userPages[index].path]).then();
    } else if (sectionIndex == 1) {
      this.router.navigate([this.studioPages[index].path]).then();
    } else if (sectionIndex == 2) {
      this.router.navigate([this.mentorPages[index].path]).then();
    } else if (sectionIndex == 3) {
      if (index < 1) {
        this.router.navigate([this.adminPages[index].path]).then();
      } else {
        if (index === 3) {
          this.router
            .navigate([this.adminPages[index].path], {
              queryParams: { page: 1, status: 0 },
            })
            .then();
        } else {
          this.router
            .navigate([this.adminPages[index].path], {
              queryParams: { page: 1 },
            })
            .then();
        }
      }
    }
  }

  feedback() {
    window.open(
      'https://management.akademy.dev/dashboard/#/nc/form/9e848c8a-16a2-4732-8033-9545d1bd4dea',
      '_blank',
    );
  }

  expand() {
    this.isExpanded = !this.isExpanded;

    this.expandEventEmitter.emit(this.isExpanded);
  }

  handleLogoOnClick() {
    this.router.navigate(['explore/microcourses/list']).then();
  }
}
