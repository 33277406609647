import { createAction, props } from '@ngrx/store';

export const getMine = createAction('[Credit] Get Mine');

export const getMineSuccess = createAction(
  '[Credit] Get Mine Success',
  props<{ credit: number }>()
);

export const getMineFailure = createAction(
  '[Credit] Get Mine Failure',
  props<{ errorMessage: string }>()
);
