import { Injectable } from '@angular/core';
import { HttpClientWithAuth } from '../../utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClientWithAuth) {}

  createWithPayOS(sku: string) {
    return this.http.post('/payment/payos/link', { sku });
  }
}
