import {Component, Inject} from '@angular/core';
import {POLYMORPHEUS_CONTEXT} from "@tinkoff/ng-polymorpheus";
import {TuiDialogContext} from "@taiga-ui/core";

@Component({
  selector: 'app-mobile-device-warning',
  templateUrl: './mobile-device-warning.component.html',
  styleUrls: ['./mobile-device-warning.component.scss']
})
export class MobileDeviceWarningComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<boolean>,
  ) {
  }

  close(): void {
    this.context.completeWith(false);
  }
}
