import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberGrouping',
})
export class NumberGroupingPipe implements PipeTransform {
  transform(value: number): string {
    if (value != undefined) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return '0';
    }
  }
}
