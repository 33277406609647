import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, of, map, switchMap, repeat } from 'rxjs';

import { AuthService } from 'src/app/services/auth/auth.service';
import * as AuthActions from './auth.actions';
import {
  StaticUserResponse,
  User,
  UserResponse,
} from 'src/app/models/user.model';
import { objectToCamel } from 'src/app/utils/objectTransform';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}

  signInWithGoogle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signInWithGoogle),
      switchMap(() => {
        return this.authService.signInWithGoogle();
      }),
      map(() => {
        return AuthActions.signInWithGoogleSuccess();
      }),
      catchError((error) => {
        return of(AuthActions.signInWithGoogleFailure({ errorMessage: error }));
      }),
      repeat()
    );
  });

  signInWithStaticUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signInWithStaticUser),
      switchMap((action) => {
        return this.authService
          .signInWithUsernamePassword(action.staticUser)
          .pipe(
            map((data) => {
              return AuthActions.signInWithStaticUserSuccess({
                staticUserResponse: <StaticUserResponse>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                AuthActions.signInWithStaticUserFailure({ errorMessage: error })
              );
            })
          );
      })
    );
  });

  signInWithSystemGG$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signInWithSystemGG),
      switchMap(() => {
        return this.authService.signInWithSystemGG().pipe(
          map((data) => {
            return AuthActions.signInWithSystemGGSuccess({
              userCredential: <User>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              AuthActions.signInWithSystemGGFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });

  signUpWithSystemGG$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signUpWithSystemGG),
      switchMap(() => {
        return this.authService.signUpWithSystemGG().pipe(
          map(() => {
            return AuthActions.signUpWithSystemGGSuccess();
          }),
          catchError((error) => {
            return of(
              AuthActions.signUpWithSystemGGFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });

  getList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getList),
      switchMap((action) => {
        return this.authService.getListUser(action.page, action.size).pipe(
          map((data) => {
            return AuthActions.getListSuccess({
              userResponse: <UserResponse>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(AuthActions.getListFailure({ errorMessage: error }));
          })
        );
      })
    );
  });

  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.deleteUser),
      switchMap((action) => {
        return this.authService.deleteUser(action.id).pipe(
          map(() => {
            return AuthActions.deleteUserSuccess();
          }),
          catchError((error) => {
            return of(AuthActions.deleteUserFailure({ errorMessage: error }));
          })
        );
      })
    );
  });

  changeRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.changeRole),
      switchMap((action) => {
        return this.authService.changeRole(action.id, action.roleId).pipe(
          map(() => {
            return AuthActions.changeRoleSuccess();
          }),
          catchError((error) => {
            return of(AuthActions.changeRoleFailure({ errorMessage: error }));
          })
        );
      })
    );
  });
}
