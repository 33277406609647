import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ACI } from 'src/app/models/aci.model';

@Component({
  selector: 'app-aci-form',
  templateUrl: './aci-form.component.html',
  styleUrls: ['./aci-form.component.scss'],
})
export class AciFormComponent {
  @Output('remove') removeEvent: EventEmitter<ACI> = new EventEmitter();
  @Output('undo') undoEvent: EventEmitter<string> = new EventEmitter();
  @Output('edit') editEvent: EventEmitter<ACI> = new EventEmitter();
  // @Output('resoureChange') resoureChangeEvent: EventEmitter<string> =
  //   new EventEmitter();
  // @Output('payloadChange') payloadChangeEvent: EventEmitter<string> =
  //   new EventEmitter();
  aciForm!: FormGroup;
  @Input('aci') aci!: ACI;
  aciInfo!: ACI;
  @Input('isEdit') isEdit!: boolean;
  @Input('isReadonly') isReadonly!: boolean;
  isRemove: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.aciInfo = { ...this.aci };
  }

  focusedChange(event: any, field: string) {
    if (!event) {
      if (field === 'resource') this.aciInfo[field] = this.aciInfo[field];
      if (field === 'payload') this.aciInfo[field] = this.aciInfo[field];
      this.editEvent.emit(this.aciInfo);
      // this.resoureChangeEvent.emit(this.aciInfo.resource);
      // this.payloadChangeEvent.emit(this.aciInfo.payload);
    }
  }

  ngOnDestroy(): void {}

  remove() {
    this.removeEvent.emit(this.aci);
  }

  undo() {
    this.isRemove = false;
    this.undoEvent.emit('undo');
  }

  getData() {
    return this.aci;
  }
}
