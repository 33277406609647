import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'onlineTimeGrouping'
})
export class OnlineTimeGroupingPipe implements PipeTransform {

  transform(value: number): string {
    if (value != undefined) {
      return Math.floor(value / 3600) + 'h ' + Math.floor((value % 3600) / 60) + 'm ';
    } else {
      return '0h 0m';
    }
  }

}
