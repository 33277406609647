import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MicrocourseService } from 'src/app/services/microcourse/microcourse.service';
import { catchError, delay, map, mergeMap, of, switchMap } from 'rxjs';
import { camelToSnake, objectToCamel } from 'src/app/utils/objectTransform';
import {
  Microcourse,
  MicrocourseResponse,
} from 'src/app/models/microcourse.model';

import * as MicrocourseActions from './microcourse.actions';

@Injectable()
export class MicrocourseEffects {
  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.create),
      switchMap((action) => {
        let Microcourse = <Microcourse>{
          ...action.microcourse,
          content: JSON.stringify(action.microcourse.contentJson),
        };
        return this.MicrocourseService.create(camelToSnake(Microcourse)).pipe(
          map(() => {
            return MicrocourseActions.createSuccess();
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.createFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });
  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.update),
      switchMap((action) => {
        let microcourse = <Microcourse>{
          ...action.microcourse,
          content: JSON.stringify(action.microcourse.contentJson),
        };
        return this.MicrocourseService.update(camelToSnake(microcourse)).pipe(
          map(() => {
            return MicrocourseActions.updateSuccess();
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.updateFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });
  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.deleteById),
      switchMap((action) => {
        return this.MicrocourseService.deleteById(action.id).pipe(
          map(() => {
            return MicrocourseActions.deleteByIdSuccess();
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.deleteByIdFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });
  getList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getResponse),
      switchMap((action) => {
        return this.MicrocourseService.getResponse(
          action.page,
          action.size
        ).pipe(
          map((data) => {
            return MicrocourseActions.getResponseSuccess({
              microcourses: <MicrocourseResponse>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getResponseFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });
  getById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getById),
      switchMap((action) => {
        return this.MicrocourseService.getById(action.id).pipe(
          map((data) => {
            if (data.content == '') {
              data.content = '{}';
            }
            let microcourse = <Microcourse>objectToCamel(data);
            microcourse.contentJson = JSON.parse(microcourse.content);
            return MicrocourseActions.getByIdSuccess({
              microcourse: microcourse,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getByIdFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });
  getEnrolledById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getEnrolledById),
      mergeMap((action) => {
        return this.MicrocourseService.getById(action.id).pipe(
          map((data) => {
            let microcourse = <Microcourse>objectToCamel(data);
            microcourse.contentJson = JSON.parse(microcourse.content);
            return MicrocourseActions.getEnrolledByIdSuccess({
              microcourse: microcourse,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getEnrolledByIdFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });
  getUserManagementById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getByUserPayload),
      mergeMap((action) => {
        return this.MicrocourseService.getById(action.id).pipe(
          map((data) => {
            if (data.content == '') {
              data.content = '{}';
            }
            let microcourse = <Microcourse>objectToCamel(data);
            microcourse.contentJson = JSON.parse(microcourse.content);

            return MicrocourseActions.getByUserPayloadSuccess({
              microcourse: microcourse,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getByUserPayloadFailure({
                errorMessage: error,
              })
            );
          })
        );
      })
    );
  });
  publish$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.publish),
      switchMap((action) => {
        return this.MicrocourseService.publish(action.id).pipe(
          map(() => {
            return MicrocourseActions.publishSuccess();
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.publishFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });
  search$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.search),
      switchMap((action) => {
        return this.MicrocourseService.search(action.query).pipe(
          // delay(1000),
          map((data) => {
            let result: Microcourse[] = [];
            if (data === null) {
              return MicrocourseActions.searchSuccess({
                microcourses: result,
              });
            } else {
              (data as Array<Microcourse>).forEach((element) => {
                let microcourse = <Microcourse>objectToCamel(element);
                result.push(microcourse);
              });
              return MicrocourseActions.searchSuccess({
                microcourses: result,
              });
            }
          }),
          catchError((error) => {
            // console.log(error);
            return of(
              MicrocourseActions.searchFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });

  getInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getInfo),
      switchMap((action) => {
        return this.MicrocourseService.getInfo(action.id).pipe(
          map((data) => {
            let microcourse = <Microcourse>objectToCamel(data);
            return MicrocourseActions.getInfoSuccess({
              microcourse: microcourse,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getInfoFailure({ errorMessage: error })
            );
          })
        );
      })
    );
  });

  getCourseOfSkillsetInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getCourseOfSkillsetInfo),
      mergeMap((action) => {
        return this.MicrocourseService.getInfo(action.id).pipe(
          map((data) => {
            let microcourse = <Microcourse>objectToCamel(data);
            return MicrocourseActions.getCourseOfSkillsetInfoSuccess({
              microcourse: microcourse,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getCourseOfSkillsetInfoFailure({
                errorMessage: error,
              })
            );
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private MicrocourseService: MicrocourseService
  ) {}
}
