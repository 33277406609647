import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Feedback } from '../../models/feedback.model';
import { HttpClientWithAuth } from '../../utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClientWithAuth) {}

  feedback(feedback: Feedback) {
    // console.log(feedback);
    return this.http.post('/course_review/feedback', feedback);
  }

  listByCourseId(courseId: string, page: number, size: number) {
    return this.http.get(
      `/course_review/feedback/course?id=${courseId}&page=${page}&size=${size}`
    );
  }

  getByUserIdAndCourseId(userId: string, courseId: string) {
    return this.http.get(
      `/course_review/feedback/user?user_id=${userId}&course_id=${courseId}`
    );
  }
}
