import {QuizAttempt, QuizManifest} from 'src/app/models/quiz.model';
import {QuizState} from './quiz.state';
import {createReducer, on} from '@ngrx/store';

import * as QuizActions from './quiz.actions';
import {QuizQuestion} from 'src/app/models/question.model';

const initialState: QuizState = {
  //Quiz
  manifest: <QuizManifest>{},
  loadByCourseIdErrorMessage: '',
  isLoadingByCourseId: false,

  isCreating: false,
  isCreateSuccess: false,
  createErrorMessage: '',

  isUpdating: false,
  isUpdateSuccess: false,
  updateErrorMessage: '',

  isDeleting: false,
  isDeleteSuccess: false,
  deleteErrorMessage: '',

  //Quiz Question
  question: <QuizQuestion>{},
  isLoadingQuestionById: false,
  loadQuestionByIdErrorMessage: '',

  isUpdatingQuestion: false,
  isUpdateQuestionSuccess: false,
  updateQuestionErrorMessage: '',

  isDeletingQuestion: false,
  isDeleteQuestionSuccess: false,
  deleteQuestionErrorMessage: '',

  questionList: [],
  isLoadingQuestionsByCourseId: false,
  loadQuestionsByCourseIdErrorMessage: '',

  isCreatingQuestion: false,
  isCreateQuestionSuccess: false,
  createQuestionErrorMessage: '',

  // Quiz Attempt
  createdAttempt: <QuizAttempt>{},
  isCreatingAttempt: false,
  createAttemptErrorMessage: '',

  attemptList: [],
  isLoadingMineAttempts: false,
  loadMineAttemptsErrorMessage: '',

  reviewAttempt: <QuizAttempt>{},
  isLoadingAttemptById: false,
  loadAttemptByIdErrorMessage: '',

  attemptQuestionList: [],
  isLoadingAttemptQuestionByAttemptId: false,
  loadAttemptQuestionByAttemptIdErrorMessage: '',

  attemptBestResult: <QuizAttempt>{},
  isLoadingAttemptBestResult: false,
  loadAttemptBestResultErrorMessage: '',

  confirm: 0,
  isAttemptSubmitting: false,
  isAttemptSubmitSuccess: false,
  attemptSubmitErrorMessage: '',

  attemptCount: 0,
  isCountingAttempt: false,
  countAttemptErrorMessage: '',
};

export const quizReducer = createReducer(
  initialState,
  on(QuizActions.create, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isCreating: true,
      isCreateSuccess: false,
      createErrorMessage: '',
    };
  }),
  on(QuizActions.createSuccess, (state, {type}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isCreating: false,
      isCreateSuccess: true,
    };
  }),
  on(QuizActions.createFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isCreating: false,
      createErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.getByCourseId, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isLoadingByCourseId: true,
      loadByCourseIdErrorMessage: '',
      manifest: <QuizManifest>{}
    };
  }),
  on(QuizActions.getByCourseIdSuccess, (state, {type, manifest}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingByCourseId: false,
      manifest: manifest,
    };
  }),
  on(QuizActions.getByCourseIdFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingByCourseId: false,
      loadByCourseIdErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.update, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isUpdating: true,
      isUpdateSuccess: false,
      updateErrorMessage: '',
    };
  }),
  on(QuizActions.updateSuccess, (state, {type}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isUpdating: false,
      isUpdateSuccess: true,
    };
  }),
  on(QuizActions.updateFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isUpdating: false,
      updateErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.deleteByCourseId, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isDeleting: true,
      isDeleteSuccess: false,
      deleteErrorMessage: '',
    };
  }),
  on(QuizActions.deleteByCourseIdSuccess, (state, {type}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isDeleting: false,
      isDeleteSuccess: true,
    };
  }),
  on(QuizActions.deleteByCourseIdFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isDeleting: false,
      deleteErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.getQuestionById, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isLoadingQuestionById: true,
      question: <QuizQuestion>{},
      loadQuestionByIdErrorMessage: '',
    };
  }),
  on(QuizActions.getQuestionByIdSuccess, (state, {type, question}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingQuestionById: false,
      question: question,
    };
  }),
  on(QuizActions.getQuestionByIdFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingQuestionById: false,
      loadQuestionByIdErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.updateQuestion, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isUpdatingQuestion: true,
      isUpdateQuestionSuccess: false,
      updateQuestionErrorMessage: '',
    };
  }),
  on(QuizActions.updateQuestionSuccess, (state, {type}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isUpdatingQuestion: false,
      isUpdateQuestionSuccess: true,
    };
  }),
  on(QuizActions.updateQuestionFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isUpdatingQuestion: false,
      updateQuestionErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.deleteQuestion, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isDeletingQuestion: true,
      isDeleteQuestionSuccess: false,
      deleteQuestionErrorMessage: '',
    };
  }),
  on(QuizActions.deleteQuestionSuccess, (state, {type}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isDeletingQuestion: false,
      isDeleteQuestionSuccess: true,
    };
  }),
  on(QuizActions.deleteQuestionFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isDeletingQuestion: false,
      deleteQuestionErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.getQuestionsByCourseId, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isLoadingQuestionsByCourseId: true,
      questionList: [],
      loadQuestionsByCourseIdErrorMessage: '',
    };
  }),
  on(QuizActions.getQuestionsByCourseIdSuccess, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isLoadingQuestionsByCourseId: false,
      questionList: action.questionList,
    };
  }),
  on(QuizActions.getQuestionsByCourseIdFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingQuestionsByCourseId: false,
      loadQuestionsByCourseIdErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.createQuestion, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isCreatingQuestion: true,
      isCreateQuestionSuccess: false,
      createQuestionErrorMessage: '',
    };
  }),
  on(QuizActions.createQuestionSuccess, (state, {type}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isCreatingQuestion: false,
      isCreateQuestionSuccess: true,
    };
  }),
  on(QuizActions.createQuestionFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isCreatingQuestion: false,
      createQuestionErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.resetStates, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      manifest: <QuizManifest>{},
      loadByCourseIdErrorMessage: '',
      isLoadingByCourseId: false,

      isCreating: false,
      isCreateSuccess: false,
      createErrorMessage: '',

      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: '',

      isDeleting: false,
      isDeleteSuccess: false,
      deleteErrorMessage: '',

      //Quiz Question
      question: <QuizQuestion>{},
      isLoadingQuestionById: false,
      loadQuestionByIdErrorMessage: '',

      isUpdatingQuestion: false,
      isUpdateQuestionSuccess: false,
      updateQuestionErrorMessage: '',

      isDeletingQuestion: false,
      isDeleteQuestionSuccess: false,
      deleteQuestionErrorMessage: '',

      questionList: [],
      isLoadingQuestionByCourseId: false,
      loadQuestionByCourseIdErrorMessage: '',

      isCreatingQuestion: false,
      isCreateQuestionSuccess: false,
      createQuestionErrorMessage: '',

      // Quiz Attempt
      createdAttempt: <QuizAttempt>{},
      isCreatingAttempt: false,
      createAttemptErrorMessage: '',

      attemptList: [],
      isLoadingMineAttempts: false,
      loadMineAttemptsErrorMessage: '',

      reviewAttempt: <QuizAttempt>{},
      isGettingAttemptById: false,
      getAttemptByIdErrorMessage: '',

      attemptQuestionList: [],
      isLoadingAttemptQuestionByAttemptId: false,
      loadAttemptQuestionByAttemptIdErrorMessage: '',

      attemptBestResult: <QuizAttempt>{},
      isLoadingAttemptBestResult: false,
      loadAttemptBestResultErrorMessage: '',

      confirm: 0,
      isAttemptSubmitting: false,
      isAttemptSubmitSuccess: false,
      attemptSubmitErrorMessage: '',

      attemptCount: 0,
      isCountingAttempt: false,
      countAttemptErrorMessage: '',
    };
  }),
  on(QuizActions.clearQuestionMessage, (state) => {
    // console.log(action.type);
    return <QuizState>{
      ...state,
      //Quiz Question
      isLoadingQuestionById: false,
      loadQuestionByIdErrorMessage: '',

      isUpdatingQuestion: false,
      isUpdateQuestionSuccess: false,
      updateQuestionErrorMessage: '',

      isDeletingQuestion: false,
      isDeleteQuestionSuccess: false,
      deleteQuestionErrorMessage: '',

      isLoadingQuestionByCourseId: false,
      loadQuestionByCourseIdErrorMessage: '',

      isCreatingQuestion: false,
      isCreateQuestionSuccess: false,
      createQuestionErrorMessage: '',
    };
  }),
  on(QuizActions.clearQuizMessage, (state) => {
    return <QuizState>{
      ...state,
      //Quiz
      loadByCourseIdErrorMessage: '',
      isLoadingByCourseId: false,

      isCreating: false,
      isCreateSuccess: false,
      createErrorMessage: '',

      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: '',

      isDeleting: false,
      isDeleteSuccess: false,
      deleteErrorMessage: '',
    }
  }),
  on(QuizActions.clearQuizAttemptMessage, (state) => {
    return <QuizState>{
      ...state,
      createdAttempt: <QuizAttempt>{},
      isCreatingAttempt: false,
      createAttemptErrorMessage: '',

      isLoadingMineAttempts: false,
      loadMineAttemptsErrorMessage: '',

      isLoadingAttemptById: false,
      loadAttemptByIdErrorMessage: '',

      attemptQuestionList: [],
      isLoadingAttemptQuestionByAttemptId: false,
      loadAttemptQuestionByAttemptIdErrorMessage: '',

      isLoadingAttemptBestResult: false,
      loadAttemptBestResultErrorMessage: '',

      isAttemptSubmitting: false,
      isAttemptSubmitSuccess: false,
      attemptSubmitErrorMessage: '',

      isCountingAttempt: false,
      countAttemptErrorMessage: '',
      confirm: 0,
    }
  }),

  on(QuizActions.createAttempt, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isCreatingAttempt: true,
      createAttemptErrorMessage: '',
      createdAttempt: <QuizAttempt>{},
    };
  }),
  on(QuizActions.createAttemptSuccess, (state, {type, attempt}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isCreatingAttempt: false,
      createdAttempt: attempt,
    };
  }),
  on(QuizActions.createAttemptFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isCreatingAttempt: false,
      createAttemptErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.submitAttempt, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isAttemptSubmitting: true,
      isAttemptSubmitSuccess: false,
      attemptSubmitErrorMessage: '',
    };
  }),
  on(QuizActions.submitAttemptSuccess, (state, {type}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isAttemptSubmitting: false,
      isAttemptSubmitSuccess: true,
    };
  }),
  on(QuizActions.submitAttemptFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isAttemptSubmitting: false,
      attemptSubmitErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.getMineAttempts, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isLoadingMineAttempts: true,
      attemptList: [],
      loadMineAttemptsErrorMessage: '',
    };
  }),
  on(QuizActions.getMineAttemptsSuccess, (state, {type, attemptList}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingMineAttempts: false,
      attemptList: attemptList,
    };
  }),
  on(QuizActions.getMineAttemptsFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingMineAttempts: false,
      loadMineAttemptsErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.getAttemptById, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isLoadingAttemptById: true,
      reviewAttempt: <QuizAttempt>{},
      loadAttemptByIdErrorMessage: '',
    };
  }),
  on(QuizActions.getAttemptByIdSuccess, (state, {type, attempt}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingAttemptById: false,
      reviewAttempt: attempt,
    };
  }),
  on(QuizActions.getAttemptByIdFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingAttemptById: false,
      loadAttemptByIdErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.confirmSubmit, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      confirm: action.confirm,
    };
  }),

  on(QuizActions.getQuestionInAttemptById, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isLoadingAttemptQuestionByAttemptId: true,
      loadAttemptQuestionByAttemptIdErrorMessage: '',
    };
  }),
  on(QuizActions.getQuestionInAttemptByIdSuccess, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      isLoadingAttemptQuestionByAttemptId: false,
      attemptQuestionList: [...state.attemptQuestionList, action.question],
    };
  }),
  on(QuizActions.getQuestionInAttemptByIdFailure, (state, {errorMessage}) => {
    return <QuizState>{
      ...state,
      isLoadingAttemptQuestionByAttemptId: false,
      loadAttemptQuestionByAttemptIdErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.getAttemptBestResult, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      attemptBestResult: <QuizAttempt>{},
      isLoadingAttemptBestResult: true,
      loadAttemptBestResultErrorMessage: '',
    };
  }),
  on(QuizActions.getAttemptBestResultSuccess, (state, {type, attempt}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingAttemptBestResult: false,
      attemptBestResult: attempt,
    };
  }),
  on(QuizActions.getAttemptBestResultFailure, (state, {type, errorMessage}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isLoadingAttemptBestResult: false,
      loadAttemptBestResultErrorMessage: errorMessage,
    };
  }),

  on(QuizActions.countAttemptsByUserId, (state, action) => {
    console.log(action.type);
    return <QuizState>{
      ...state,
      attemptCount: 0,
      isCountingAttempt: true,
      countAttemptErrorMessage: '',
    };
  }),
  on(QuizActions.countAttemptsByUserIdSuccess, (state, {type, count}) => {
    console.log(type);
    return <QuizState>{
      ...state,
      isCountingAttempt: false,
      attemptCount: count,
    };
  }),
  on(QuizActions.countAttemptsByUserIdFailure, (state, {errorMessage}) => {
    return <QuizState>{
      ...state,
      isCountingAttempt: false,
      countAttemptErrorMessage: errorMessage,
    };
  })
);
