import {UsageTimeState} from "./usage-time.state";
import {UsageTime} from "../../models/usage_time.model";
import {createReducer, on} from "@ngrx/store";
import * as UsageTimeActions from './usage-time.actions';

export const initialState: UsageTimeState = {
  usageTime: <UsageTime>{},
  isLoading: false,
  loadErrorMessage: ''
}

export const usageTimeReducer = createReducer(
  initialState,
  on(UsageTimeActions.create, (state, action) => {
    console.log(action.type);
    return <UsageTimeState>{
      ...state,
    };
  }),
  on(UsageTimeActions.createSuccess, (state, action) => {
    console.log(action.type);
    return <UsageTimeState>{
      ...state,
    };
  }),
  on(UsageTimeActions.createFailure, (state, action) => {
    console.log(action.type);
    return <UsageTimeState>{
      ...state,
    };
  }),

  on(UsageTimeActions.getByUserId, (state, action) => {
    console.log(action.type);
    return <UsageTimeState>{
      ...state,
      isLoading: true,
      loadErrorMessage: ''
    };
  }),
  on(UsageTimeActions.getByUserIdSuccess, (state, action) => {
    console.log(action.type);
    return <UsageTimeState>{
      ...state,
      usageTime: action.usageTime,
      isLoading: false,
      loadErrorMessage: ''
    };
  }),
  on(UsageTimeActions.getByUserIdFailure, (state, action) => {
    console.log(action.type);
    return <UsageTimeState>{
      ...state,
      isLoading: false,
      loadErrorMessage: action.errorMessage
    };
  }),

  on(UsageTimeActions.resetStates, (state, action) => {
      console.log(action.type);
      return <UsageTimeState>{
        ...initialState
      };
    }
  )
)
