<div (click)="openDrawer()" [ngClass]="cardStyle">
  <tui-avatar
    class="tui-space_right-2"
    [avatarUrl]="course.badgesImgUrl"
    [size]="'xxl'"
    [autoColor]="true"
  ></tui-avatar>
  <div class="course-content-cover">
    <div class="course-content-title-box">
      <div class="course-content-title">
        {{ course.title }}
      </div>
    </div>
    <div class="course-content-tags">
      <tui-scrollbar>
        <tui-tag
          *ngIf="course.tag1 !== ''"
          [hoverable]="true"
          [value]="course.tag1"
          class="tag tui-space_right-2 tui-space_bottom-2"
          status="primary"
        ></tui-tag>
        <tui-tag
          *ngIf="course.tag2 !== ''"
          [hoverable]="true"
          [value]="course.tag2"
          class="tag tui-space_right-2 tui-space_bottom-2"
          status="primary"
        ></tui-tag>
      </tui-scrollbar>
    </div>
    <div class="course-content-description">{{ course.description }}</div>
  </div>
</div>
