import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Microcourse } from 'src/app/models/microcourse.model';
import { EnrollmentState } from 'src/app/ngrx/enrollment/enrollment.state';
import * as EnrollmentActions from 'src/app/ngrx/enrollment/enrollment.actions';
import * as ProfileActions from 'src/app/ngrx/profile/profile.actions';
import { TuiAlertService } from '@taiga-ui/core';
import { ProfileState } from 'src/app/ngrx/profile/profile.state';
import * as TrackingItemActions from 'src/app/ngrx/tracking-item/tracking-item.actions';
import { Router } from '@angular/router';
import { AuthState } from 'src/app/ngrx/auth/auth.state';
import { FeedbackState } from '../../../../../ngrx/feedback/feedback.state';
import * as FeedbackActions from '../../../../../ngrx/feedback/feedback.actions';
import { Profile } from 'src/app/models/profile.model';
import { Enrollment } from 'src/app/models/enrollment.model';
import * as DrawerActions from '../../../../../ngrx/drawer/drawer.actions';
import { AlertV2Service } from 'src/app/services/alert_v2/alert-v2.service';

@Component({
  selector: 'app-course-detail-drawer',
  templateUrl: './course-detail-drawer.component.html',
  styleUrls: ['./course-detail-drawer.component.scss'],
})
export class CourseDetailDrawerComponent implements OnInit, OnDestroy {
  course!: Microcourse;
  @Input('course')
  set _course(course: Microcourse) {
    this.course = course;
    if (this.course.id != undefined) {
      this.isEnrolled = this.enrollmentData.some(
        (enrollment) => enrollment.microcourseId == this.course.id
      );
      // console.log(this.isEnrolled);
      this.store.dispatch(
        FeedbackActions.listByCourseId({
          courseId: this.course.id,
          page: 1,
          size: 100,
        })
      );
      this.store.dispatch(
        ProfileActions.getByPublic({
          id: this.course.authorId,
        })
      );
      this.store.dispatch(
        EnrollmentActions.countByMicrocourseId({
          id: this.course.id,
        })
      );
      this.store.dispatch(
        TrackingItemActions.reportView({
          trackingItemId: this.course.id,
        })
      );
    }
  }

  openPaymentDialog = false;
  ratingStar = 0;
  subscription: Subscription[] = [];
  isEnrolled = false;
  openEnrollDialog = false;

  //enrollment
  count$ = this.store.select('enrollment', 'countByMicrocourseId');
  enrollmentData: Enrollment[] = [];

  //feedback
  feedbackResponse$ = this.store.select('feedback', 'feedbackResponse');
  isGettingFeedback = false;

  //auth x profile
  jwt$ = this.store.select('auth', 'jwt');
  profile: Profile = <Profile>{};
  otherProfile$ = this.store.select('profile', 'userProfile');

  constructor(
    private router: Router,
    private alertServiceV2: AlertV2Service,
    private store: Store<{
      enrollment: EnrollmentState;
      profile: ProfileState;
      auth: AuthState;
      feedback: FeedbackState;
    }>
  ) {}

  ngOnInit(): void {
    this.subscription.push(
      this.store
        .select('enrollment', 'enrollmentResponse')
        .subscribe((enrollment) => {
          if (enrollment.data != undefined) {
            this.enrollmentData = enrollment.data;
          } else {
            this.enrollmentData = [];
          }
        }),
      this.store
        .select('enrollment', 'isEnrollSuccess')
        .subscribe((success) => {
          if (success) {
            this.router.navigate(['explore/microcourses/enrolled']).then(() => {
              this.alertServiceV2.successNotification(
                'Đăng ký thành công',
                3000
              );
              this.closePaymentDialog();
              this.closeDrawer();
            });
          }
        }),
      this.store.select('enrollment', 'isEnrolling').subscribe((loading) => {
        if (loading) {
          this.alertServiceV2.infoNotification(
            'Đã gửi yêu cầu đăng ký...',
            3000
          );
        }
      }),
      this.store
        .select('enrollment', 'enrollErrorMessage')
        .subscribe((error) => {
          if (error) {
            this.closePaymentDialog();
            this.showEnrollErrorDialog();
          }
        }),
      this.feedbackResponse$.subscribe((feedbackResponse) => {
        if (feedbackResponse.data != null) {
          if (feedbackResponse.data.length > 0) {
            let score = 0;
            feedbackResponse.data.forEach((feedback) => {
              score += feedback.score;
            });
            this.ratingStar = Math.round(score / feedbackResponse.data.length);
            // console.log(feedbackResponse);
          }
        }
      }),
      this.store.select('feedback', 'isGetting').subscribe((isLoading) => {
        this.isGettingFeedback = isLoading;
      }),
      this.store.select('profile', 'mineProfile').subscribe((profile) => {
        if (profile.id != undefined && profile.id != '') {
          this.profile = profile;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
    this.store.dispatch(EnrollmentActions.clearMessages());
  }

  closeDrawer() {
    this.store.dispatch(DrawerActions.close());
  }

  showPaymentDialog(): void {
    this.openPaymentDialog = true;
  }

  closePaymentDialog(): void {
    this.openPaymentDialog = false;
  }

  showEnrollErrorDialog(): void {
    this.openEnrollDialog = true;
  }

  closeEnrollErrorDialog(): void {
    this.openEnrollDialog = false;
    this.closeDrawer();
  }

  enrollCourse(): void {
    this.store.dispatch(
      EnrollmentActions.enroll({
        microcourseId: this.course == null ? '' : this.course.id,
      })
    );
  }

  learnCourse(): void {
    this.router
      .navigate([
        'explore/learning/' + (this.course == null ? '' : this.course.id),
      ])
      .then(() => this.store.dispatch(DrawerActions.close()));
  }

  replaceCoverImg($event: ErrorEvent) {
    if ($event.target) {
      ($event.target as HTMLImageElement).src =
        '../../../../assets/images/default-course.png';
    }
  }

  navigateToHome() {
    this.router.navigate(['home']).then(() => {
      this.closeEnrollErrorDialog();
    });
  }
}
