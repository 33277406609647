import { createAction, props } from '@ngrx/store';
import {
  FirebaseUser,
  StaticUser,
  StaticUserResponse,
  User,
  UserResponse,
} from 'src/app/models/user.model';

export const clearState = createAction('[Auth] Clear State');

export const clearMessages = createAction('[Auth] Clear Message');

export const storeIdToken = createAction(
  '[Auth] Store Id Token',
  (idToken: string) => ({ idToken })
);

export const storeFirebaseUser = createAction(
  '[Auth] Store Firebase User',
  props<{ firebaseUser: FirebaseUser }>()
);

export const storeJWT = createAction(
  '[Auth] Store JWT',
  props<{ jwt: string }>()
);

export const signInWithGoogle = createAction('[Auth] Sign in with Google');

export const signInWithGoogleSuccess = createAction(
  '[Auth] Sign in with Google Success'
);

export const signInWithGoogleFailure = createAction(
  '[Auth] Sign in with Google Failure',
  props<{ errorMessage: string }>()
);

export const signInWithSystemGG = createAction(
  '[Auth] Sign in with System GG',
  props<{ idToken: string }>()
);

export const signInWithSystemGGSuccess = createAction(
  '[Auth] Sign in with System GG Success',
  props<{ userCredential: User }>()
);

export const signInWithSystemGGFailure = createAction(
  '[Auth] Sign in with System GG Failure',
  props<{ errorMessage: string }>()
);

export const signUpWithSystemGG = createAction(
  '[Auth] Sign up with System GG',
  props<{ idToken: string }>()
);

export const signUpWithSystemGGSuccess = createAction(
  '[Auth] Sign up with System GG Success'
);

export const signUpWithSystemGGFailure = createAction(
  '[Auth] Sign up with System GG Failure',
  props<{ errorMessage: string }>()
);

//list
export const getList = createAction(
  '[Auth] Get List',
  props<{ page: number; size: number }>()
);

export const getListSuccess = createAction(
  '[Auth] Get List Success',
  props<{ userResponse: UserResponse }>()
);

export const getListFailure = createAction(
  '[Auth] Get List Failure',
  props<{ errorMessage: string }>()
);

// delete
export const deleteUser = createAction(
  '[Auth] Delete User',
  props<{ id: string }>()
);

export const deleteUserSuccess = createAction('[Auth] Delete User Success');

export const deleteUserFailure = createAction(
  '[Auth] Delete User Failure',
  props<{ errorMessage: string }>()
);

// change role
export const changeRole = createAction(
  '[Auth] Change Role',
  props<{ id: string; roleId: string }>()
);

export const changeRoleSuccess = createAction('[Auth] Change Role Success');

export const changeRoleFailure = createAction(
  '[Auth] Change Role Failure',
  props<{ errorMessage: string }>()
);

export const signInWithStaticUser = createAction(
  '[Auth] Sign In With Static User',
  props<{ staticUser: StaticUser }>()
);

export const signInWithStaticUserSuccess = createAction(
  '[Auth] Sign In With Static User Success',
  props<{ staticUserResponse: StaticUserResponse }>()
);

export const signInWithStaticUserFailure = createAction(
  '[Auth] Sign In With Static User Failure',
  props<{ errorMessage: string }>()
);

export const signOutStaticUser = createAction('[Auth] Sign Out Static User');

export const signOutStaticUserSuccess = createAction(
  '[Auth] Sign Out Static User Success'
);

export const signOutStaticUserFailure = createAction(
  '[Auth] Sign Out Static User Failure',
  props<{ errorMessage: string }>()
);

export const verifyIsSignInYet = createAction(
  '[Auth] Verify Is Sign In Yet',
  props<{ isAlreadySignIn: boolean }>()
);
