import {Profile} from 'src/app/models/profile.model';
import {ProfileState} from './profile.state';
import {createReducer, on} from '@ngrx/store';
import * as ProfileActions from './profile.actions';

const initialState: ProfileState = {
  // mine
  mineProfile: <Profile>{},

  isGettingMine: false,
  getMineErrorMessage: '',

  isCreateMine: false,
  isCreateMineSuccess: false,
  createMineErrorMessage: '',

  isUpdateMine: false,
  isUpdateMineSuccess: false,
  updateMineErrorMessage: '',

  // user profile
  userProfile: <Profile>{},

  isGetting: false,
  isGetSuccess: false,
  getErrorMessage: '',

  isGettingPublic: false,
  isGetPublicSuccess: false,
  getPublicErrorMessage: '',

  isUpdating: false,
  isUpdateSuccess: false,
  updateErrorMessage: '',

  // profile list
  profileList: [],

  isGettingList: false,
  isGetListSuccess: false,
  getListErrorMessage: '',
};

export const profileReducer = createReducer(
  initialState,
  on(ProfileActions.getMine, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      mineProfile: <Profile>{},
      isGettingMine: true,
      getMineErrorMessage: '',
    };
  }),
  on(ProfileActions.getMineSuccess, (state, {type, mineProfile}) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      mineProfile: mineProfile,
      isGettingMine: false,
    };
  }),
  on(ProfileActions.getMineFailure, (state, {type, getMineErrorMessage}) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      isGettingMine: false,
      getMineErrorMessage: getMineErrorMessage,
    };
  }),

  on(ProfileActions.createMine, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isCreateMine: true,
      isCreateMineSuccess: false,
    };
  }),
  on(ProfileActions.createMineSuccess, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isCreateMine: false,
      isCreateMineSuccess: true,
    };
  }),
  on(ProfileActions.createMineFailure, (state, {type, createMineErrorMessage}) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      isCreateMine: false,
      isCreateMineSuccess: false,
      createMineErrorMessage: createMineErrorMessage,
    };
  },),

  on(ProfileActions.updateMine, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isUpdateMine: true,
      isUpdateMineSuccess: false,
    };
  }),
  on(ProfileActions.updateMineSuccess, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isUpdateMine: false,
      isUpdateMineSuccess: true,
    };
  }),
  on(ProfileActions.updateMineFailure, (state, {type, updateMineErrorMessage}) => {
      console.log(type);
      return <ProfileState>{
        ...state,
        isUpdateMine: false,
        isUpdateMineSuccess: false,
        updateMineErrorMessage: updateMineErrorMessage,
      };
    },
  ),

  on(ProfileActions.getByPublic, (state, {type}) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      isGettingPublic: true,
      isGetPublicSuccess: false,
    };
  }),
  on(ProfileActions.getByPublicSuccess, (state, {type, userProfile}) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      userProfile: userProfile,
      isGettingPublic: false,
      isGetPublicSuccess: true,
    };
  }),
  on(ProfileActions.getByPublicFailure, (state, {type, getPublicErrorMessage}) => {
      console.log(type);
      return <ProfileState>{
        ...state,
        isGettingPublic: false,
        isGetPublicSuccess: false,
        getPublicErrorMessage: getPublicErrorMessage,
      };
    },
  ),

  on(ProfileActions.getListById, (state, {type}) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      profileList: [],
      isGettingList: true,
      isGetListSuccess: false,
    };
  }),
  on(ProfileActions.getListByIdSuccess, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      profileList: [...state.profileList, action.userProfile],
      isGettingList: false,
      isGetListSuccess: true,
    };
  }),
  on(ProfileActions.getListByIdFailure, (state, {type, getListErrorMessage}) => {
      console.log(type);
      return <ProfileState>{
        ...state,
        isGettingList: false,
        isGetListSuccess: false,
        getListErrorMessage: getListErrorMessage,
      };
    },
  ),

  on(ProfileActions.getById, (state, {type}) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      isGetting: true,
      isGetSuccess: false,
    };
  }),
  on(ProfileActions.getByIdSuccess, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      userProfile: action.userProfile,
      isGetting: false,
      isGetSuccess: true,
    };
  }),
  on(ProfileActions.getByIdFailure, (state, {type, getErrorMessage}) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      isGetting: false,
      isGetSuccess: false,
      getErrorMessage: getErrorMessage,
    };
  }),

  on(ProfileActions.updateUser, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isUpdating: true,
      isUpdateSuccess: false,
    };
  }),
  on(ProfileActions.updateUserSuccess, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isUpdating: false,
      isUpdateSuccess: true,
    };
  }),
  on(ProfileActions.updateUserFailure, (state, {type, updateErrorMessage}) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: updateErrorMessage,
    };
  },),

  on(ProfileActions.clearMineProfileStates, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      mineProfile: <Profile>{},

      isGettingMine: false,
      getMineErrorMessage: '',

      isCreateMine: false,
      isCreateMineSuccess: false,
      createMineErrorMessage: '',

      isUpdateMine: false,
      isUpdateMineSuccess: false,
      updateMineErrorMessage: '',
    };
  }),
  on(ProfileActions.clearUserProfileStates, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      userProfile: <Profile>{},

      isGetting: false,
      isGetSuccess: false,
      getErrorMessage: '',

      isGettingPublic: false,
      isGetPublicSuccess: false,
      getPublicErrorMessage: '',

      isCreating: false,
      isCreateSuccess: false,
      createErrorMessage: '',

      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: '',

      isDeleting: false,
      isDeleteSuccess: false,
      deleteErrorMessage: '',
    };
  }),
  on(ProfileActions.clearProfileListStates, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      profileList: [],

      isGettingList: false,
      isGetListSuccess: false,
      getListErrorMessage: '',
    };
  }),

  on(ProfileActions.clearMineProfileMessage, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isGettingMine: false,
      getMineErrorMessage: '',

      isCreateMine: false,
      isCreateMineSuccess: false,
      createMineErrorMessage: '',

      isUpdateMine: false,
      isUpdateMineSuccess: false,
      updateMineErrorMessage: '',
    };
  }),
  on(ProfileActions.clearUserProfileMessage, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isGetting: false,
      isGetSuccess: false,
      getErrorMessage: '',

      isGettingPublic: false,
      isGetPublicSuccess: false,
      getPublicErrorMessage: '',

      isCreating: false,
      isCreateSuccess: false,
      createErrorMessage: '',

      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: '',

      isDeleting: false,
      isDeleteSuccess: false,
      deleteErrorMessage: '',
    };
  }),
  on(ProfileActions.clearProfileListMessage, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isGettingList: false,
      isGetListSuccess: false,
      getListErrorMessage: '',
    };
  }),
);
