import { createAction, props } from '@ngrx/store';
import {
  Certificate,
  MicrocourseGroup,
  MicrocourseGroupResponse,
} from 'src/app/models/skillset.model';

export const getSkillsetById = createAction(
  '[Skillset] Get By Id',
  props<{ id: string }>()
);
export const getSkillsetByIdSuccess = createAction(
  '[Skillset] Get By Id Success',
  props<{ skillsetData: MicrocourseGroup }>()
);
export const getSkillsetByIdFailure = createAction(
  '[Skillset] Get By Id Failure',
  props<{ errorMessage: string }>()
);

export const listInternalSkillset = createAction(
  '[Skillset] List Internal',
  props<{ size: number; page: number }>()
);
export const listInternalSkillsetSuccess = createAction(
  '[Skillset] List Internal Success',
  props<{ skillsetListResponse: MicrocourseGroupResponse }>()
);
export const listInternalSkillsetFailure = createAction(
  '[Skillset] List Internal Failure',
  props<{ errorMessage: string }>()
);

export const createSkillset = createAction(
  '[Skillset] Create',
  props<{ skillsetData: MicrocourseGroup }>()
);
export const createSkillsetSuccess = createAction('[Skillset] Create Success');
export const createSkillsetFailure = createAction(
  '[Skillset] Create Failure',
  props<{ errorMessage: string }>()
);

export const updateSkillset = createAction(
  '[Skillset] Update',
  props<{ skillsetData: MicrocourseGroup }>()
);
export const updateSkillsetSuccess = createAction('[Skillset] Update Success');
export const updateSkillsetFailure = createAction(
  '[Skillset] Update Failure',
  props<{ errorMessage: string }>()
);

export const deleteSkillset = createAction(
  '[Skillset] Delete',
  props<{ id: string }>()
);
export const deleteSkillsetSuccess = createAction('[Skillset] Delete Success');
export const deleteSkillsetFailure = createAction(
  '[Skillset] Delete Failure',
  props<{ errorMessage: string }>()
);

export const publishSkillset = createAction(
  '[Skillset] Publish',
  props<{ id: string }>()
);
export const publishSkillsetSuccess = createAction(
  '[Skillset] Publish Success'
);
export const publishSkillsetFailure = createAction(
  '[Skillset] Publish Failure',
  props<{ errorMessage: string }>()
);

export const unPublishSkillset = createAction(
  '[Skillset] Unpublish',
  props<{ id: string }>()
);
export const unPublishSkillsetSuccess = createAction(
  '[Skillset] Unpublish Success'
);
export const unPublishSkillsetFailure = createAction(
  '[Skillset] Unpublish Failure',
  props<{ errorMessage: string }>()
);

export const getSkillsetPublishStatus = createAction(
  '[Skillset] Get Publish Status',
  props<{ id: string }>()
);
export const getSkillsetPublishStatusSuccess = createAction(
  '[Skillset] Get Publish Status',
  props<{ publishStatus: boolean }>()
);
export const getSkillsetPublishStatusFailure = createAction(
  '[Skillset] Get Publish Status',
  props<{ errorMessage: string }>()
);

export const searchSkillset = createAction(
  '[Skillset] Search',
  props<{ query: string }>()
);
export const searchSkillsetSuccess = createAction(
  '[Skillset] Search Success',
  props<{ searchResult: MicrocourseGroup[] }>()
);
export const searchSkillsetFailure = createAction(
  '[Skillset] Search Failure',
  props<{ errorMessage: string }>()
);

export const listPublishedSkillset = createAction(
  '[Skillset] List Published',
  props<{ size: number; page: number }>()
);
export const listPublishedSkillsetSuccess = createAction(
  '[Skillset] List Published Success',
  props<{ skillsetListResponse: MicrocourseGroupResponse }>()
);
export const listPublishedSkillsetFailure = createAction(
  '[Skillset] List Published Failure',
  props<{ errorMessage: string }>()
);

//certificate
export const requestCertForIssuing = createAction(
  '[Certificate] Request For Issuing',
  props<{ skillsetId: string }>()
);
export const requestCertForIssuingSuccess = createAction(
  '[Certificate] Request For Issuing Success'
);
export const requestCertForIssuingFailure = createAction(
  '[Certificate] Request For Issuing Failure',
  props<{ errorMessage: string }>()
);

export const getCertsByUserId = createAction(
  '[Certificate] Get By User Id',
  props<{ id: string }>()
);
export const getCertsByUserIdSuccess = createAction(
  '[Certificate] Get By User Id Success',
  props<{ certificates: Certificate[] }>()
);
export const getCertsByUserIdFailure = createAction(
  '[Certificate]  Get By User Id Failure',
  props<{ errorMessage: string }>()
);

export const getCertByUserIdAndSkillsetId = createAction(
  '[Certificate] Get By User Id And Skillset Id',
  props<{ userId: string; skillsetId: string }>()
);
export const getCertByUserIdAndSkillsetIdSuccess = createAction(
  '[Certificate] Get By User Id And Skillset Id Success',
  props<{ certificate: Certificate }>()
);
export const getCertByUserIdAndSkillsetIdFailure = createAction(
  '[Certificate]  Get By User Id And Skillset Id Failure',
  props<{ errorMessage: string }>()
);

export const countCertBySkillsetId = createAction(
  '[Certificate] Count By Skillset Id',
  props<{ skillsetId: string }>()
);
export const countCertBySkillsetIdSuccess = createAction(
  '[Certificate] Count By Skillset Id Success',
  props<{ count: number }>()
);
export const countCertBySkillsetIdFailure = createAction(
  '[Certificate] Count By Skillset Id Failure',
  props<{ errorMessage: string }>()
);

export const clearStates = createAction('[Skillset] Clear States');

export const clearCreateSkillsetState = createAction(
  '[Skillset] Clear Create State'
);
export const clearUpdateSkillsetState = createAction(
  '[Skillset] Clear Update State'
);
export const clearDeleteSkillsetState = createAction(
  '[Skillset] Clear Delete State'
);
export const clearGetSkillsetByIdState = createAction(
  '[Skillset] Clear Get By Id State'
);
export const clearUnPublishSkillsetState = createAction(
  '[Skillset] Clear Unpublish State'
);
export const clearPublishSkillsetState = createAction(
  '[Skillset] Clear Publish State'
);
export const clearGetCertByUserIdAndSkillsetIdState = createAction(
  '[Certificate] Clear Get By User Id And Skillset Id State'
);
export const clearRequestCertForIssuingState = createAction(
  '[Certificate] Clear Request For Issuing State'
);

export const clearListPublishedSkillsetState = createAction(
  '[Skillset] Clear List Published State'
);
