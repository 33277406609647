import {Injectable} from '@angular/core';
import {Auth, GoogleAuthProvider, signInWithPopup} from '@angular/fire/auth';
import {StaticUser} from 'src/app/models/user.model';
import {HttpClientWithAuth} from "../../utils/HttpClientWithAuth";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private auth: Auth, private http: HttpClientWithAuth) {
  }

  async signInWithGoogle(): Promise<string> {
    const auth = this.auth;
    const provider = new GoogleAuthProvider();

    try {
      const credential = await signInWithPopup(auth, provider);
      if (credential.user) {
        return await credential.user.getIdToken();
      }
      throw new Error('User not found');
    } catch (error) {
      throw error;
    }
  }

  async signOut(): Promise<void> {
    try {
      await this.auth.signOut();
    } catch (error) {
      throw error;
    }
  }

  signInWithSystemGG() {
    return this.http.post('/auth/signin/google', '');
  }

  signUpWithSystemGG() {
    return this.http.post('/auth/signup/google', '');
  }

  getListUser(page: number, size: number) {
    return this.http.get(`/auth?page=${page}&size=${size}`);
  }

  deleteUser(id: string) {
    return this.http.delete(`/auth?id=${id}`);
  }

  changeRole(id: string, role: string) {
    return this.http.put(`/auth/role?id=${id}&role_id=${role}`, '');
  }

  signInWithUsernamePassword(user: StaticUser) {
    return this.http.post('/auth/signin', user);
  }
}
