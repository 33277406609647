import { Injectable } from '@angular/core';
import { MentorProfileData } from 'src/app/models/profile.model';
import { HttpClientWithAuth } from 'src/app/utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class MentorProfileService {
  constructor(private http: HttpClientWithAuth) {}

  listByStatus(status: number, size: number, page: number) {
    return this.http.get(
      `/mentor/profile/list?status=${status}&size=${size}&page=${page}`
    );
  }

  getMentorProfilebyId(id: string) {
    return this.http.get(`/mentor/profile?id=${id}`);
  }

  changeApproveStatus(id: string, status: number) {
    return this.http.put(
      `/mentor/profile/status?id=${id}&status=${status}`,
      {}
    );
  }

  approveProfile(id: string) {
    return this.http.put(`/mentor/profile/approve?id=${id}`, {});
  }

  getMine() {
    return this.http.get('/mentor/profile/mine');
  }

  createMine(mentorProfile: MentorProfileData) {
    return this.http.post('/mentor/profile/mine', mentorProfile);
  }

  updateMine(mentorProfile: MentorProfileData) {
    return this.http.put('/mentor/profile/mine', mentorProfile);
  }

  changeCommission(id: string, rate: number) {
    return this.http.put(
      `/mentor/profile/commission?id=${id}&rate=${rate}`,
      {}
    );
  }
}
