import { createAction, props } from '@ngrx/store';
import { PaymentOSResponse } from 'src/app/models/payment.model';

export const createWithPayOS = createAction(
  '[Payment] Create With PayOS',
  props<{ sku: string }>(),
);
export const createWithPayOSSuccess = createAction(
  '[Payment] Create With PayOS Success',
  props<{
    paymentOSResponse: PaymentOSResponse;
  }>(),
);
export const createWithPayOSFailure = createAction(
  '[Payment] Create With PayOS Failure',
  props<{
    errorMessage: string;
  }>(),
);

export const clearPaymentState = createAction('[Payment] Clear Payment State');
